import React from "react";
import * as ROLES from "../../constants/roles";

import { Breadcrumb } from "antd";
import { connect } from "react-redux";

function TableMenu(props) {
  const clickControls = (e) => {
    e.preventDefault();
    props.onSetTableMenu(e.target.innerHTML);
  };

  return (
    <Breadcrumb>
      {props.authUser.roles[ROLES.DATALEVEL1] ||
      props.authUser.roles[ROLES.DATALEVEL2] ? (
        <>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Offense</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Defense</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Body</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Ring</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Misc</a>
          </Breadcrumb.Item>
        </>
      ) : (
        <>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Moment</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Offense</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Defense</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Body</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Ring</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={clickControls}>
            <a href="">Misc</a>
          </Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
}
const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSetTableMenu: (data) => dispatch({ type: "TABLE_MENU_SET", data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(TableMenu);
