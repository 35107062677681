const INITIAL_STATE = {};

const applySetComboStudyMoment = (state, action) => ({
  ...state,
  comboStudyMoment: action.data,
});

function comboStudyMomentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "COMBO_STUDY_MOMENT_SET": {
      return applySetComboStudyMoment(state, action);
    }

    default:
      return state;
  }
}
export default comboStudyMomentReducer;
