import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { compose } from "recompose";
import { withFirebase } from "../Firebase";

import { Input, Button, message } from "antd";

class FightItem extends Component {
  constructor(props) {
    super(props);
  }

  onCollectFightData = () => {
    this.props.onCollectFightData(this.props.fight.uid);
  };
  onDeleteSomething = () => {
    const key = this.props.fight.uid;
    this.props.firebase
      .fight(key)
      .delete()
      .then(function () {
        message.success("Document successfully deleted!");
      })
      .catch(function (error) {
        message.error("Error removing document: ", error);
      });
  };
  render() {
    const { fight } = this.props;

    return (
      <li
        style={{
          marginBottom: 10,
          display: "flex",
          marginTop: 10,
          alignItems: "center",
        }}
      >
        <span>
          {fight.fighterA} <strong>vs </strong>
          {fight.fighterB} --- {fight.date}
        </span>
        <span style={{ marginLeft: 10 }}>
          <Button onClick={this.onCollectFightData}>
            <Link to={ROUTES.FIGHT_COLLECT}>Data</Link>
          </Button>

          {!!this.props.authUser.roles[ROLES.ADMIN] && (
            <>
              <Button onClick={this.onCollectFightData}>
                <Link to={ROUTES.EDIT_FIGHT}>Edit</Link>
              </Button>
              {/* <Button onClick={this.onDeleteSomething}>Delete</Button> */}
            </>
          )}
        </span>
      </li>
    );
  }
}

export default compose(withFirebase)(FightItem);
