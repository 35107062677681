const RoundEstimator = (currentTime, roundStartTime, styleOfFight) => {
  var roundTime;
  const boxingRoundEstimator = (currentTime, roundStartTime) => {
    let difference = currentTime - roundStartTime;
    if (difference <= 60) {
      let sec = 60 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 2 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 2 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 120) {
      let sec = 120 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 1 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 1 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 180) {
      let sec = 180 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 0 + `:` + sec + `:` + milliSec;
      }
    } else {
      let sec = 240 - difference;
      sec = 60 - sec;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = `-` + 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = `-` + 0 + `:` + sec + `:` + milliSec;
      }
    }
    return roundTime;
  };
  const mmaRoundEstimator = (currentTime, roundStartTime) => {
    let difference = currentTime - roundStartTime;
    if (difference <= 60) {
      let sec = 60 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 4 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 4 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 120) {
      let sec = 120 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 3 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 3 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 180) {
      let sec = 180 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 2 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 2 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 240) {
      let sec = 240 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 1 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 1 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 300) {
      let sec = 300 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 0 + `:` + sec + `:` + milliSec;
      }
    } else {
      let sec = 360 - difference;
      sec = 60 - sec;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = `-` + 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = `-` + 0 + `:` + sec + `:` + milliSec;
      }
    }
    return roundTime;
  };

  const womenBoxingRoundEstimator = (currentTime, roundStartTime) => {
    let difference = currentTime - roundStartTime;
    if (difference <= 60) {
      let sec = 60 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 1 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 1 + `:` + sec + `:` + milliSec;
      }
    } else if (difference <= 120) {
      let sec = 120 - difference;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = 0 + `:` + sec + `:` + milliSec;
      }
    } else {
      let sec = 180 - difference;
      sec = 60 - sec;
      sec = Math.round((sec + Number.EPSILON) * 100) / 100;
      let milliSec = (sec + "").split(".")[1];
      milliSec = Math.round((parseFloat(milliSec) / 100) * 60);
      milliSec = milliSec < 10 ? `0` + milliSec : milliSec;
      sec = Math.round(sec);
      if (sec < 10) {
        roundTime = `-` + 0 + `:0` + sec + `:` + milliSec;
      } else {
        roundTime = `-` + 0 + `:` + sec + `:` + milliSec;
      }
    }
    return roundTime;
  };
  switch (styleOfFight) {
    case "Boxing": // 3 mins
      return boxingRoundEstimator(currentTime, roundStartTime);
    case "MMA": // 5 mins round
      return mmaRoundEstimator(currentTime, roundStartTime);
    case "WomenBoxing": // 2mins round
      return womenBoxingRoundEstimator(currentTime, roundStartTime);

    default:
      break;
  }

  return roundTime;
};

export default RoundEstimator;
