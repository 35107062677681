import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import TallyScore from "./TallyScore";

function ScoreResult(props) {
  const [tallyResult, setTallyResult] = useState([]);
  const [missingScores, setMissingScores] = useState([]);
  const { Title } = Typography;
  useEffect(() => {
    var scoreTally = [];
    for (const [key, value] of Object.entries(props.scoreRound)) {
      let object = props.scoreRound[`${key}`];
      console.log(object, key);
      if (key === "Round 999") {
        scoreTally.push({
          [`Round ${props.start[0].length}`]: props.scoreRound[`${key}`],
        });
      } else {
        scoreTally.push({ [`${key}`]: props.scoreRound[`${key}`] });
      }
    }
    setTallyResult(scoreTally);
  }, [props.userScores]);
  useEffect(() => {
    console.log(props.start[0].length);

    var missingArray = [];
    for (let i = 0; i < props.start[0].length; i++) {
      if (!props.userScores[`Round ${i}`]) {
        if (i > 0) {
          missingArray.push(i);
        }
      }
    }
    setMissingScores(missingArray);
  }, [tallyResult]);

  useEffect(() => {
    console.log(missingScores);
  }, [missingScores]);

  return (
    <div>
      <Title level={2} style={{ textAlign: "center" }}>
        Final Score
      </Title>
      <ScoreResultCard
        currentRound={props.currentRound}
        endStatus={props.endStatus}
        start={props.start}
        fighterA={props.fighterA}
        fighterB={props.fighterB}
      />

      {tallyResult.map((roundResult) => (
        <div key={Object.keys(roundResult)[0]}>
          <Title level={4} style={{ textAlign: "center" }}>
            {Object.keys(roundResult)[0]}
          </Title>
          <ScoreResultCard
            currentRound={props.currentRound}
            endStatus={props.endStatus}
            start={props.start}
            fighterA={props.fighterA}
            fighterB={props.fighterB}
            roundScoreResult={true}
            F1={roundResult[Object.keys(roundResult)[0]].F1EA}
            F2={roundResult[Object.keys(roundResult)[0]].F2EA}
          />
        </div>
      ))}
      {missingScores.length > 1
        ? missingScores.map((missingScore) => (
            <div
              key={missingScore}
              onClick={() => {
                props.onSetScoreRound({ currentRound: missingScore });
              }}
            >
              {missingScore}
            </div>
          ))
        : null}
    </div>
  );
}
const ScoreResultCard = (props) => {
  return (
    <>
      {props.currentRound === 1000 && !props.endStatus ? null : (
        <Row
          justify="space-between"
          className={
            props.width > 768
              ? "scoreBoardScoreResult"
              : "scoreBoardScoreResultMobile"
          }
        >
          <Col span={20} className="fighterANameScoreResult">
            <Row>
              <Col span={24}> {props.fighterA} </Col>
            </Row>
          </Col>

          <Col span={4} className="fighterAScore">
            <Row justify="center">
              <Col span={20}>
                {" "}
                {props.roundScoreResult ? (
                  props.F1
                ) : (
                  <TallyScore
                    start={props.start}
                    scoreResultPage={true}
                    fighter={"A"}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={20} className="fighterBNameScoreResult">
            <Row>
              <Col span={24}> {props.fighterB}</Col>
            </Row>
          </Col>
          <Col span={4} className="fighterBScore">
            <Row justify="center">
              <Col span={20}>
                {" "}
                {props.roundScoreResult ? (
                  props.F2
                ) : (
                  <TallyScore
                    start={props.start}
                    fighter={"B"}
                    scoreResultPage={true}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
const mapStateToProps = (state, props) => ({
  currentMoment: state.currentUserPages.currentMomentPlay
    ? state.currentUserPages.currentMomentPlay
    : 0,
  userScores: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound
    : [],
  currentRound: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.currentRound
    : 0,

  startStatus: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.start
    : false,
  endStatus: state.roundScore ? state.roundScore.endStatus : false,
  width: state.viewPortReducer.viewPort,
});

const mapDispatchToProps = (dispatch) => ({
  onSetScoreRound: (data) =>
    dispatch({ type: "CURRENT_SCORE_ROUND_SET", data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ScoreResult);
