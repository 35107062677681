import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ReactPlayer from "react-player/youtube";
import { Row, Col, Typography } from "antd";
import captureVideoFrame from "capture-video-frame";

const handlePause = (e) => {
  console.log("This was paused");
};

function VideoApp(props) {
  const inputEl = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [url, setURL] = useState("");
  const [loading, setLoading] = useState(true);
  const { Title } = Typography;

  useEffect(() => {
    setTimeout(() => setLoading(false), 700);
  }, [loading]);

  useEffect(() => {
    // inputEl.current.seekTo(props.currentMoment, "seconds");
    if (inputEl.current !== null && props.currentMoment !== 0) {
      inputEl.current.seekTo(props.currentMoment, "seconds");
    }
  }, [props.currentMoment]);

  const playButton = () => {
    if (playing === true) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };

  const getTime = () => {
    console.log(inputEl.current.getCurrentTime());
  };
  const setTime = () => {
    inputEl.current.seekTo(
      inputEl.current.getCurrentTime() + 1 / 25,
      "seconds"
    );
  };
  const handlePause = () => {};

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 100 + `%`,
          height: 100 + `%`,
        }}
      >
        {loading ? (
          <Row justify="center">
            <Col>
              <Title level={4}>LOADING</Title>
            </Col>
          </Row>
        ) : (
          <ReactPlayer
            ref={inputEl}
            playing={playing}
            url={props.currentFullFightURL}
            controls={true}
            width="100%"
            height="100%"
            onPause={handlePause}
            onClick={() => {
              alert("screen has been disabled");
            }}
            progressInterval={1000 / 25}
            onProgress={(e) => {
              props.currentVideoTime(e.playedSeconds);
              // console.log(e);
            }}
          />
        )}
      </div>
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 100 + `%`,
          height: 100 + `%`,
          zIndex: 100,
          backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.1})`,
        }}
        onClick={playButton}
      > */}
      {/* <Button onClick={playButton}>toggle Play/Pause</Button>
          <Button onClick={setTime}>toggle Play/Pause</Button> */}
      <img id="my-screenshot" />
      <KeyboardEventHandler
        handleKeys={["a", "d", "space", "w", "r", "c", "p"]}
        onKeyEvent={(key, e) => {
          e.preventDefault();
          if (key === "a") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() - 1 / 30,
              "seconds"
            );
          } else if (key === "d") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() + 1 / 30,
              "seconds"
            );
          } else if (key === "space") {
            playButton();
          } else if (key === "w") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() - 1,
              "seconds"
            );
          } else if (key === "r") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() + 1,
              "seconds"
            );
          } else if (key === "c") {
            if (props.currentRowData.length > 0) {
              inputEl.current.seekTo(
                props.currentRowData[props.currentRowIndex]?.videoTime,
                "seconds"
              );
            }
          }
        }}
      />
      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentFightUID: state.currentFightReducer.currentFightID,
  currentFullFightURL: state.currentFightReducer.currentFullFight.videoURL,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => ({
  currentVideoTime: (data) => dispatch({ type: "CURRENT_TIME_SET", data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(VideoApp);
