import React, { Component } from "react";
import { withoutAuthorization, withEmailVerification } from "../Session";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import { Input, Button, Form, Row, Col, message } from "antd";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const SignUpPage = () => (
  <div>
    <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
      Sign up (Data Contractor)
    </h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  // isAdmin: true,
  // isAdmin: false,
  isData: true,
  messageCloseStatus: true,

  error: null,
  messageCanOpen: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    // const { username, email, passwordOne, isAdmin } = this.state;
    const { username, email, passwordOne } = this.state;

    const roles = {};
    if (this.state.isData) {
      roles[ROLES.DATALEVEL1] = ROLES.DATALEVEL1;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            roles,
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.FIGHT_LIST);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error }, () => {
          if (this.state.messageCloseStatus) {
            this.setState({ messageCloseStatus: false });
            message.error(error.message, 2, this.onMessageClose);
          }
        });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  onClose = () => {
    this.setState({ messageCanOpen: false });
  };
  afterClose = () => {
    this.setState({ messageCanOpen: true });
  };
  onMessageClose = () => {
    this.setState({ messageCloseStatus: true });
  };
  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      // isAdmin,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="User Name"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
          </Col>
        </Row>{" "}
        {/* <Row justify="center" gutter={[16, 16]}>
          <Col span={8}>
            <Row justify="center">
              <label>
                Admin:
                <Input
                  name="isAdmin"
                  type="checkbox"
                  checked={isAdmin}
                  onChange={this.onChangeCheckbox}
                />
              </label>{" "}
            </Row>
          </Col>
        </Row> */}{" "}
        {/* This is for signing up as admin. I need to create a new signup for admins? */}
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button disabled={isInvalid} htmlType="submit">
              Sign Up
            </Button>
          </Col>{" "}
        </Row>
      </Form>
    );
  }
}
const condition = (authUser) => !authUser;

const mapStateToProps = (state, props) => ({
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});

const SignUpLink = () => (
  <p style={{ textAlign: "center" }}>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
  withoutAuthorization(condition),
  connect(mapStateToProps, null)
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
