import React, { useState } from "react";
import { Layout, Row, Col, Button } from "antd";
import testImg from "../../assets/img/jonesGus.jpg";

function Playlist() {
  const { Content } = Layout;
  const [createPlaylistHover, setCreatePlaylistHover] = useState(false);
  return (
    <Content style={style.content}>
      <Row justify="center">
        <Col span="24">
          <h1 style={style.h1}>Playlists</h1>
        </Col>
        <Col span="20" style={{ marginBottom: "20px" }}>
          <Button>Create Playlist</Button>
        </Col>
        <Col span="20">
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{
              marginBottom: 30 + "px",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <img
                src={testImg}
                alt=""
                style={{
                  width: 100 + `%`,
                  borderRadius: 4 + `px`,
                }}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <h2>Jon Jones vs ALexander Gustufsson</h2>
              <p style={{ textTransform: "capitalize" }}>
                WeightClass : Light Heayweight
              </p>
              <p>Date : 2012-10-10</p>
              <Button>Watch Playlist</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
}
const style = {
  content: { paddingTop: "50px" },
  h1: { textAlign: "center" },
};

export default Playlist;
