import React, { useState } from "react";
import { Button } from "antd";

const ButtonClicked = (props) => {
  const [clicked, setClicked] = useState(false);
  return (
    <Button
      style={
        props.clickedState && props.value === props.selected
          ? Style.Clicked
          : Style.Reg
      }
      onClick={props.onClicked}
    >
      {props.value}
    </Button>
  );
};

const Style = {
  Clicked: {
    width: 100 + `%`,
    borderColor: "#a8071a",
    color: "#a8071a",
  },
  Reg: {
    width: 90 + `%`,
  },
};
export default ButtonClicked;
