import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ReactPlayer from "react-player/youtube";
import { Row, Col, Typography } from "antd";
import captureVideoFrame from "capture-video-frame";

const handlePause = (e) => {
  console.log("This was paused");
};

function VideoApp(props) {
  const inputEl = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [url, setURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [slowMo, setSlowMo] = useState(false);
  const [stopTime, setStopTime] = useState(0);

  const { Title } = Typography;

  useEffect(() => {
    setTimeout(() => setLoading(false), 700);
  }, [loading]);

  useEffect(() => {
    // inputEl.current.seekTo(props.currentMoment, "seconds");
    if (
      inputEl.current !== null &&
      props.currentMoment !== 0 &&
      props.filmEventCount > 1
    ) {
      inputEl.current.seekTo(props.currentMoment - 1, "seconds");
      setStopTime(props.currentMoment + 1);
      setSlowMo(true);
      setPlaying(true);
      props.onSetMomentPlay(0);
    }
  }, [props.currentMoment, props.filmEventCount]);

  useEffect(() => {
    if (
      inputEl.current !== null &&
      props.currentComboMoment?.length !== 0 &&
      props.comboEventCount > 1
    ) {
      inputEl.current.seekTo(props.currentComboMoment[0] - 1, "seconds");
      setStopTime(props.currentComboMoment[1] + 1);
      setSlowMo(true);
      setPlaying(true);
      props.onSetMomentPlay(0);
    }
  }, [props.currentComboMoment, props.comboEventCount]);
  useEffect(() => {
    if (slowMo && stopTime <= props.currentTime) {
      setPlaying(false);
      setSlowMo(false);
    }
  }, [props.currentTime]);
  useEffect(() => {
    if (
      props.frameForwardCount > 0 &&
      inputEl.current &&
      inputEl.current.getCurrentTime() > 0
    ) {
      inputEl.current.seekTo(
        inputEl.current?.getCurrentTime() + 1 / 30,
        "seconds"
      );
    }
  }, [props.frameForwardCount]);

  useEffect(() => {
    if (
      props.frameBackwardCount > 0 &&
      inputEl.current &&
      inputEl.current.getCurrentTime() > 0
    ) {
      inputEl.current.seekTo(
        inputEl.current?.getCurrentTime() - 1 / 30,
        "seconds"
      );
    }
  }, [props.frameBackwardCount]);

  const playButton = () => {
    if (playing === true) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };

  const getTime = () => {
    console.log(inputEl.current.getCurrentTime());
  };
  const setTime = () => {
    inputEl.current.seekTo(
      inputEl.current.getCurrentTime() + 1 / 25,
      "seconds"
    );
  };
  const handlePause = () => {};

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 100 + `%`,
          height: 100 + `%`,
        }}
      >
        {loading ? (
          <Row justify="center">
            <Col>
              <Title level={4}>LOADING</Title>
            </Col>
          </Row>
        ) : (
          <ReactPlayer
            ref={inputEl}
            playing={playing}
            url={props.currentURL}
            controls={true}
            width="100%"
            height="100%"
            onPause={handlePause}
            onClick={() => {
              alert("screen has been disabled");
            }}
            playbackRate={slowMo ? 0.5 : 1}
            volume={1}
            muted={true}
            progressInterval={1000 / 25}
            onProgress={(e) => {
              props.currentVideoTime(e.playedSeconds);
              // console.log(e);
            }}
          />
        )}
      </div>
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 100 + `%`,
          height: 100 + `%`,
          zIndex: 100,
          backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.1})`,
        }}
        onClick={playButton}
      > */}
      {/* <Button onClick={playButton}>toggle Play/Pause</Button>
          <Button onClick={setTime}>toggle Play/Pause</Button> */}
      <img id="my-screenshot" />
      <KeyboardEventHandler
        handleKeys={["left", "right", "space", "down", "up", "enter"]}
        onKeyEvent={(key, e) => {
          e.preventDefault();
          if (key === "left") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() - 1 / 30,
              "seconds"
            );
          } else if (key === "right") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() + 1 / 30,
              "seconds"
            );
          } else if (key === "space") {
            playButton();
          } else if (key === "down") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() - 1,
              "seconds"
            );
          } else if (key === "up") {
            inputEl.current.seekTo(
              inputEl.current.getCurrentTime() + 1,
              "seconds"
            );
          } else if (key === "enter") {
            if (props.currentRowData.length > 0) {
              if (
                props.searchQueryResult[1] === "Single Punch" ||
                props.searchQueryResult[1] === "Counter"
              ) {
                props.onSetFilmEventCount(props.filmEventCount + 1);
              } else {
                props.onSetComboEventCount(props.comboEventCount + 1);
              }
            }
          }
        }}
      />
      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentFightUID: state.currentFightReducer.currentFightID,
  // currentFullFightURL: state.currentFightReducer.currentFullFight.videoURL,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
  currentTime: state.currentTimeState.currentTime,
  filmEventCount: state.filmEventCount.filmEventCount,
  comboEventCount: state.comboEventCount.comboEventCount,
  searchQueryResult: state.searchQueryResult.searchQueryResult,
  frameForwardCount: state.frameForwardCount.frameForwardCount,
  frameBackwardCount: state.frameBackwardCount.frameBackwardCount,
});

const mapDispatchToProps = (dispatch) => ({
  currentVideoTime: (data) => dispatch({ type: "CURRENT_TIME_SET", data }),
  onSetMomentPlay: (data) =>
    dispatch({ type: "CURRENT_MOMENT_PLAY_SET", data }),
  onSetFilmEventCount: (data) =>
    dispatch({ type: "FILM_EVENT_COUNT_SET", data }),
  onSetComboEventCount: (data) =>
    dispatch({ type: "COMBO_EVENT_COUNT_SET", data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(VideoApp);
