const INITIAL_STATE = {};

const applySetCurrentScoreRound = (state, action) => ({
  ...state,
  currentScoreRound: action.data,
});

const applySetScoreCURRound = (state, action) => ({
  ...state,
  currentRound: action.data,
});

const applySetScoreCardEndStatus = (state, action) => ({
  ...state,
  endStatus: action.data,
});

const applySetCurrentScores = (state, action) => ({
  ...state,
  currentScores: action.data,
});

const applySetCurrentTally = (state, action) => ({
  ...state,
  currentTally: action.data,
});

const applySetStartStatus = (state, action) => ({
  ...state,
  start: action.data,
});

const applySetMissingScores = (state, action) => ({
  ...state,
  missingScores: action.data,
});
function roundScoreReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CURRENT_SCORE_ROUND_SET":
      return applySetCurrentScoreRound(state, action);
    case "CURRENT_SCORE_CURROUND_SET":
      return applySetScoreCURRound(state, action);
    case "END_STATUS_SET":
      return applySetScoreCardEndStatus(state, action);
    case "CURRENT_SCORE_SET":
      return applySetCurrentScores(state, action);
    case "CURRENT_TALLY_SET":
      return applySetCurrentTally(state, action);
    case "START_STATUS_SET":
      return applySetStartStatus(state, action);
    case "MISSING_SCORES_SET":
      return applySetMissingScores(state, action);
    default:
      return state;
  }
}
export default roundScoreReducers;
