import {
  FIGHTER,
  ROUND,
  HAND,
  PUNCH,
  STATUS,
  RANGE,
  STANCE,
  LEVEL,
  RING,
  DEFENSE,
  BODY,
  MOMENT,
  MISC,
} from "./actions";

export const fighter = (data) => {
  return {
    type: FIGHTER,
    payload: data,
  };
};
export const round = (data) => {
  return {
    type: ROUND,
    payload: data,
  };
};
export const hand = (data) => {
  return {
    type: HAND,
    payload: data,
  };
};
export const punch = (data) => {
  return {
    type: PUNCH,
    payload: data,
  };
};
export const status = (data) => {
  return {
    type: STATUS,
    payload: data,
  };
};
export const range = (data) => {
  return {
    type: RANGE,
    payload: data,
  };
};
export const stance = (data) => {
  return {
    type: STANCE,
    payload: data,
  };
};
export const level = (data) => {
  return {
    type: LEVEL,
    payload: data,
  };
};
export const ring = (data) => {
  return {
    type: RING,
    payload: data,
  };
};
export const defense = (data) => {
  return {
    type: DEFENSE,
    payload: data,
  };
};
export const body = (data) => {
  return {
    type: BODY,
    payload: data,
  };
};
export const moment = (data) => {
  return {
    type: MOMENT,
    payload: data,
  };
};
export const misc = (data) => {
  return {
    type: MISC,
    payload: data,
  };
};
