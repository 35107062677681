import React from "react";
import BodyFront from "../../../assets/img/body/Body_ref_front.jpg";
import BodyLeft from "../../../assets/img/body/body_ref_left.jpg";
import BodyRight from "../../../assets/img/body/body_ref_right.jpg";
function BodyManual() {
  return (
    <>
      <span style={Style.span}>Front</span>
      <img
        src={BodyFront}
        alt=""
        style={{
          maxWidth: "100%",
          textAlign: "center",
          display: "block",
          margin: "10px auto",
        }}
      />
      <span style={Style.span}>Left</span>
      <img
        src={BodyLeft}
        alt=""
        style={{
          maxWidth: "100%",
          textAlign: "center",
          display: "block",
          margin: "10px auto",
        }}
      />
      <span style={Style.span}>Right</span>
      <img
        src={BodyRight}
        alt=""
        style={{
          maxWidth: "100%",
          textAlign: "center",
          display: "block",
          margin: "0 auto",
        }}
      />
    </>
  );
}

const Style = {
  span: {
    fontWeight: "bold",
    color: "#E8B339",
  },
};

export default BodyManual;
