import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { fighter } from "../../actions";

const { Option } = Select;

const FighterList = (props) => {
  const [fighterName, setFighterName] = useState("");

  useEffect(() => {}, [fighterName]);

  const changeFighter = (e) => {
    console.log(e);
    setFighterName(e);
    props.fighter(e);
  };
  return (
    <Select
      defaultValue={props.defValue}
      onChange={changeFighter}
      style={{ width: 90 + `%` }}
    >
      <Option value="">Select a Fighter</Option>
      <Option value={props.fighterInfo.fighterAUID}>
        {props.fighterInfo.fighterAName}
      </Option>
      <Option value={props.fighterInfo.fighterBUID}>
        {props.fighterInfo.fighterBName}
      </Option>
    </Select>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fighter: (data) => dispatch(fighter(data)),
  };
};

export default connect(null, mapDispatchToProps)(FighterList);
