import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, AutoComplete } from "antd";
import ButtonClicked from "../ButtonClicked";
import { CloseCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { misc } from "../../actions";
const MiscList = (props) => {
  const [miscForm, setMiscForm] = useState(false);
  const [miscBtnList, setMiscBtnList] = useState([]);
  const [miscInput, setMiscInput] = useState("");
  const [miscInputIndex, setMiscInputIndex] = useState();
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  const options = [
    {
      value: "Lead Foot Dominance", // Event based - happens in an instance
    },
    {
      value: "Horns", // time based sort of like how long spent in clinch
    },
    {
      value: "Rhythm Step", // Can be time based i.e doing in for a few secs or event based, doing it before throwing a punch
    },
  ];
  useEffect(() => {
    props.misc(selectedButton);
    console.log(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.misc,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  const changeToInput = () => {
    console.log("here");
    setMiscForm(true);
  };
  const onInputChange = (e) => {
    setMiscInput(e);
  };
  const addToList = () => {
    console.log("here");
    setMiscBtnList([...miscBtnList, miscInput]);
    setMiscForm(false);
  };

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton([], setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  const removeButton = () => {
    setMiscBtnList(
      miscBtnList.filter((item, listIndex) => miscInputIndex !== listIndex)
    );
  };

  return (
    <Row justify="center" gutter={[0, 16]}>
      {miscForm ? (
        <Col span="20">
          <Row justify="space-between" gutter={[8, 8]}>
            <Col span="15">
              {/* <Input style={{ width: 90 + `%` }} onChange={onInputChange} /> */}
              <AutoComplete
                style={{
                  width: 90 + `%`,
                }}
                options={options}
                onChange={onInputChange}
                placeholder="Enter a Button type"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Col>
            <Col span="9">
              <Button onClick={addToList}>Create A Button</Button>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col span="20">
          <Row justify="end">
            <Col span="9">
              <Button onDoubleClick={changeToInput}>Create A Button</Button>
            </Col>
          </Row>
        </Col>
      )}{" "}
      {miscBtnList.map((buttonName, i) => (
        <Col span="20">
          <Row justify="space-between" align="middle" gutter={[16, 0]}>
            <Col span="22">
              <ButtonClicked
                value={buttonName}
                onClicked={onClicked}
                clickedState={clicked}
                selected={selectedButton}
              />
            </Col>
            <Col span="2">
              <CloseCircleOutlined
                style={{
                  cursor: "pointer",
                  color: miscInputIndex === i ? "#ad8b00" : null,
                }}
                onMouseEnter={() => {
                  setMiscInputIndex(i);
                }}
                onMouseLeave={() => {
                  setMiscInputIndex();
                }}
                onClick={removeButton}
              />
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});
const mapDispatchToProps = (dispatch) => {
  return {
    misc: (data) => dispatch(misc(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MiscList);
