const INITIAL_STATE = {};
const applySetFight = (state, action) => ({
  ...state,
  currentFightID: action.uid,
});
const applySetFullFightData = (state, action) => ({
  ...state,
  currentFullFight: action.data,
});

const applySetCurrentRoundStart = (state, action) => ({
  ...state,
  currentRoundStart: action.data,
});
const applySetMomentRoundStart = (state, action) => ({
  ...state,
  currentRoundMomentStart: action.data,
});

const applySetTableMenu = (state, action) => ({
  ...state,
  currentTableMenu: action.data,
});
const applySetCurrentRowData = (state, action) => ({
  ...state,
  currentRowData: action.data,
});
const applySetClearSome = (state, action) => ({
  ...state,
  currentClearSome: action.data,
});

const applySetCurrentRowIndex = (state, action) => ({
  ...state,
  currentRowIndex: action.data,
});

const applySetFightURL = (state, action) => ({
  ...state,
  currentFightURL: action.data,
});
function currentFightReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FIGHT_SET": {
      return applySetFight(state, action);
    }

    case "FULL_FIGHT_SET": {
      return applySetFullFightData(state, action);
    }

    case "ROUND_START_SET": {
      return applySetCurrentRoundStart(state, action);
    }
    case "MOMENTS_ ROUND_START_SET": {
      return applySetMomentRoundStart(state, action);
    }
    case "TABLE_MENU_SET": {
      return applySetTableMenu(state, action);
    }
    case "CURRENT_ROW_DATA_SET": {
      return applySetCurrentRowData(state, action);
    }
    case "CLEAR_SOME_SET": {
      return applySetClearSome(state, action);
    }
    case "CURRENT_ROW_INDEX_SET": {
      return applySetCurrentRowIndex(state, action);
    }
    case "CURRENT_FIGHT_URL": {
      return applySetFightURL(state, action);
    }

    default:
      return state;
  }
}
export default currentFightReducer;
