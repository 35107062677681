import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import ButtonClicked from "../ButtonClicked";
import { connect } from "react-redux";
import { status } from "../../actions";

const StatusList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    props.status(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentStatus) {
      setClicked(true);
      setSelectedButton(props.currentStatus);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.status,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col span={12}>
        <ButtonClicked
          value="Land"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={12}>
        <ButtonClicked
          value="Partial Land"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={12}>
        <ButtonClicked
          value="Miss"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={12}>
        <ButtonClicked
          value="Gloved Block"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={12}>
        <ButtonClicked
          value="PGB"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      {/* <Col span={8}>
        <ButtonClicked
          value="Knockdown"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "} */}
      <Col span={12}>
        <ButtonClicked
          value="Unsure"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
    </Row>
  );
};
const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    status: (data) => dispatch(status(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StatusList);
