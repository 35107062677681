import React from "react";
import { Row, Col, Divider, Tooltip } from "antd";
import { TinyArea } from "@ant-design/charts";
import { InfoCircleOutlined } from "@ant-design/icons";
function TotalPunchChart() {
  var data = [
    264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513,
    546, 983, 340, 539, 243, 226, 192,
  ];

  var config = {
    height: 60,
    autoFit: false,
    data: data,
    smooth: true,
    annotations: [
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        text: {
          content: "avg",
          offsetY: -2,
          style: {
            textAlign: "left",
            fontSize: 10,

            textBaseline: "bottom",
          },
        },
        style: { stroke: "rgba(255, 255, 255, 0.75)" },
      },
    ],
    areaStyle: {
      fill: "#531dab",
      fillOpacity: 1,
    },
    line: {
      color: "#531dab",
    },
  };
  return (
    <>
      <Row justify="space-between" align="center">
        <Col span="12">
          <p>Total Punches</p>
        </Col>
        <Col span="12">
          <Row justify="end">
            <Col>
              <Tooltip
                title="The total number of punches Saul Alvarez threw in the fight"
                trigger={["click"]}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        </Col>{" "}
      </Row>
      <Row>
        <Col>
          <p style={style.headerNumber}>879</p>
        </Col>
      </Row>
      <Row justify="center">
        <Col span="24">
          <Row justify="center">
            <Col span="24">
              <TinyArea {...config} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <p style={{ fontSize: "16px" }}>
            Avg. punch per round{" "}
            <span style={{ fontSize: "14px", color: "white" }}>
              {" "}
              {879 / 12}{" "}
            </span>
          </p>
        </Col>
      </Row>
    </>
  );
}
const style = {
  headerNumber: { color: "#fff", fontSize: "42px" },
};
export default TotalPunchChart;
