export const bodyPosPerFront = [
  {
    bodyPart: "Forehead",
    image: "Front",
    top: -125,
    left: 55,
    right: 78,
    bottom: -115,
  },
  {
    bodyPart: "Eye/CheekBone Right",
    image: "Front",
    top: -115,
    left: 55,
    right: 65,
    bottom: -110,
  },
  {
    bodyPart: "Eye/CheekBone Left",
    image: "Front",
    top: -115,
    left: 70,
    right: 78,
    bottom: -110,
  },
  {
    bodyPart: "Nose",
    image: "Front",
    top: -115,
    left: 65,
    right: 70,
    bottom: -106,
  },
  {
    bodyPart: "Mouth",
    image: "Front",
    top: -106,
    left: 55,
    right: 78,
    bottom: -97,
  },
  {
    bodyPart: "Neck",
    image: "Front",
    top: -97,
    left: 55,
    right: 78,
    bottom: -87,
  },
  {
    bodyPart: "Right Shoulder",
    image: "Front",
    top: -87,
    left: 28,
    right: 42,
    bottom: -60,
  },
  {
    bodyPart: "Left Shoulder",
    image: "Front",
    top: -87,
    left: 91,
    right: 104,
    bottom: -60,
  },
  {
    bodyPart: "Right Chest",
    image: "Front",
    top: -87,
    left: 42,
    right: 67,
    bottom: -55,
  },
  {
    bodyPart: "Left Chest",
    image: "Front",
    top: -87,
    left: 67,
    right: 91,
    bottom: -55,
  },
  {
    bodyPart: "Right Upper Ribs",
    image: "Front",
    top: -55,
    left: 42,
    right: 51,
    bottom: -37,
  },
  {
    bodyPart: "Right Upper Stomach",
    image: "Front",
    top: -55,
    left: 51,
    right: 67,
    bottom: -37,
  },
  {
    bodyPart: "Left Upper Ribs",
    image: "Front",
    top: -55,
    left: 85,
    right: 91,
    bottom: -37,
  },
  {
    bodyPart: "Left Upper Stomach",
    image: "Front",
    top: -55,
    left: 67,
    right: 85,
    bottom: -37,
  },
  {
    bodyPart: "Right Lower Ribs",
    image: "Front",
    top: 95,
    left: 42,
    right: 51,
    bottom: 114,
  },
  {
    bodyPart: "Right Lower Stomach",
    image: "Front",
    top: -37,
    left: 51,
    right: 67,
    bottom: -18,
  },

  {
    bodyPart: "Left Lower Ribs",
    image: "Front",
    top: -37,
    left: 85,
    right: 91,
    bottom: -18,
  },
  {
    bodyPart: "Left Lower Stomach",
    image: "Front",
    top: -37,
    left: 67,
    right: 85,
    bottom: -18,
  },
];
