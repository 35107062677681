import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Button } from "antd";
import FightList from "./FightList";

class Fights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.fights.length) {
      this.setState({ loading: true });
    }
    this.onListenForFights();
  }

  onListenForFights = () => {
    this.setState({ loading: true });
    let fights = [];
    this.unsubscribe = this.props.firebase
      .fights()
      .orderBy("createdAt", "desc")
      .limit(this.props.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach((doc) => {
            fights.push({ ...doc.data(), uid: doc.id });
            console.log(doc.id);
          });

          this.setState({ loading: false });
        } else {
          this.setState({ fights: null, loading: false });
        }
      });

    this.unsubscribeVirtualData = this.props.firebase
      .fight("RUsAMhuUfHV0LtPIlur2")
      .get()
      .then((doc) => {
        fights.push({ ...doc.data(), uid: doc.id });
        this.props.onSetFights(fights);
      });
  };
  onCollectFightData = (uid) => {
    this.props.onSetFight(uid);
  };

  componentWillUnmount() {
    this.unsubscribe();
  }
  onNextPage = () => {
    this.props.onSetFightsLimit(this.props.limit + 3);
  };
  onPrevPage = () => {
    this.props.onSetFightsLimit(this.props.limit - 3);
  };

  componentDidUpdate(props) {
    if (props.limit !== this.props.limit) {
      this.onListenForFights();
    }
  }

  render() {
    const { fights } = this.props;
    const { loading } = this.state;
    return (
      <div>
        {!loading && fights && (
          <>
            <Button type="Button" onClick={this.onNextPage}>
              More
            </Button>
            <Button
              disabled={this.props.limit < 6}
              type="Button"
              onClick={this.onPrevPage}
            >
              Less
            </Button>
          </>
        )}
        {loading && <div>Loading ...</div>}
        {fights && (
          <FightList
            fights={fights}
            key={fights.uid}
            onCollectFightData={this.onCollectFightData}
            authUser={this.props.authUser}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fights: Object.keys(state.fightState.fights || {}).map((key) => ({
    ...state.fightState.fights[key],
    uid: state.fightState.fights[key].uid,
  })),
  limit: state.fightState.limit,
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSetFights: (fights) => dispatch({ type: "FIGHTS_SET", fights }),
  onSetFightsLimit: (limit) => dispatch({ type: "FIGHTS_LIMIT_SET", limit }),
  onSetFight: (uid) => dispatch({ type: "FIGHT_SET", uid }),
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Fights);
