import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Select, Divider, message } from "antd";
import BodySideLeft from "../../../../assets/img/body/body-side-left.svg";
import BodySideRight from "../../../../assets/img/body/body-side-right.svg";
import BodyFront from "../../../../assets/img/body/body-front.svg";
import BodyBack from "../../../../assets/img/body/body-back.svg";
import BodyAngleLeft from "../../../../assets/img/body/body-angle-left.svg";
import BodyAngleRight from "../../../../assets/img/body/body-angle-right.svg";
import { bodyPosPerFront } from "../../../../constants/bodyPosPer";
import { CloseCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { body } from "../../../actions";
function Body(props) {
  const [currentView, setCurrentView] = useState("Front");
  const [bodyTags, setBodyTags] = useState([]);
  const [bodyTagsSaved, setBodyTagsSaved] = useState([]);

  const [hover, setHover] = useState(false);
  const [bodyIndex, setBodyIndex] = useState();

  const { Option } = Select;
  const bodyContainer = useRef(null);
  function handleChange(value) {
    setCurrentView(value.target.textContent);
  }

  const removeTag = (bodyPart) => {
    setBodyTags((bodyTags) =>
      bodyTags.filter((item, index) => index !== bodyPart.index)
    );
    setBodyTagsSaved((bodyTagsSaved) =>
      bodyTagsSaved.filter((item, index) => index !== bodyPart.index)
    );
  };

  useEffect(() => {
    console.log("triggered");
    if (props.currentRowData.length > 0) {
      // props.currentRowData[props.currentRowIndex]?.body.forEach((bodyDatum) => {
      //   console.log(bodyDatum);
      // });
      // setSelectedButton(
      //   props.currentRowData[props.currentRowIndex]?.body,
      //   setClicked(true)
      // );
      if (props.currentRowData[props.currentRowIndex].body) {
        const bodyDataSplit =
          props.currentRowData[props.currentRowIndex]?.body?.split(",");
        const currentBodyTag = [];
        bodyDataSplit.forEach((bodyDatum) => {
          var tempSingleTag = bodyDatum.split("-");
          currentBodyTag.push({
            bodyPart: tempSingleTag[0],
            image: tempSingleTag[1],
          });
        });
        setBodyTags(currentBodyTag);
        setBodyTagsSaved(bodyDataSplit);
      }
    } else {
      setBodyTags([]);
      setBodyTagsSaved([]);
    }
  }, [
    props?.currentRowData?.[props.currentRowIndex]?.body,
    props.currentRowIndex,
  ]);

  const bodyTagElement = bodyTags.map((bodyPart, i) => (
    <Col span="8">
      <div
        key={i}
        style={{
          fontSize: "12px",
          color: hover && bodyIndex === i ? "#ad8b00" : null,
        }}
        onMouseEnter={() => {
          setHover(true);
          setBodyIndex(i);
        }}
        onMouseLeave={() => {
          setHover(false);
          setBodyIndex();
        }}
      >
        {bodyPart.bodyPart} - {bodyPart.image}{" "}
        <span
          onClick={(e) => {
            console.log(e.key);
            removeTag({
              bodyPart: bodyPart.bodyPart,
              image: bodyPart.image,
              index: i,
            });
          }}
          style={{ cursor: "pointer" }}
        >
          <CloseCircleOutlined />
        </span>
      </div>
    </Col>
  ));

  useEffect(() => {
    console.log(bodyTagsSaved, bodyTags, "here is the result");
    props.body(bodyTagsSaved);
  }, [bodyTagsSaved]);

  const getPosition = (el) => {
    var xPosition = 0;
    var yPosition = 0;
    let n = 0;
    while (el) {
      n++;
      console.log(el, n);
      if (el.tagName == "BODY" || el.id == "ringScrollPos") {
        // deal with browser quirks with body/window/document and page scroll
        console.log("BODY");
        var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
        var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

        xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
        yPosition += el.offsetTop - yScrollPos + el.clientTop;
      } else {
        xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      }
      if (n === 3) {
        xPosition +=
          el.children[1].offsetLeft -
          el.children[1].scrollLeft +
          el.children[1].clientLeft;

        yPosition +=
          el.children[1].offsetTop -
          el.children[1].scrollTop +
          el.children[1].clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPosition,
      y: yPosition,
    };
  };

  const bodySelected = (e) => {
    var parentPosition = getPosition(bodyContainer.current);

    var clickX = ((e.clientX - parentPosition.x) / 150) * 100;
    var clickY = ((e.clientY - parentPosition.y) / 150) * 100;
    console.log(e.clientX, e.clientY, parentPosition, clickX, clickY);
    bodyPosPerFront.forEach((bodyPos) => {
      if (
        clickX >= bodyPos.left &&
        clickX < bodyPos.right &&
        clickY >= bodyPos.top &&
        clickY < bodyPos.bottom
      ) {
        setBodyTags((bodyTags) => [
          ...bodyTags,
          { bodyPart: bodyPos.bodyPart, image: currentView },
        ]);
        setBodyTagsSaved((bodyTags) => [
          ...bodyTagsSaved,
          `${bodyPos.bodyPart} - ${bodyPos.image}`,
        ]);
      }
    });
    console.log(bodyTags);
  };
  return (
    <>
      {" "}
      <Divider>Body Data</Divider>
      <Row style={{ marginTop: "10px" }} gutter={[16, 16]}>
        <Col span="24">
          <Row justify="space-between">
            <Col span="6">
              <Row justify="center">
                {" "}
                <span
                  onClick={handleChange}
                  style={{
                    cursor: "pointer",
                    color: currentView === "Front" ? "#ad8b00" : null,
                  }}
                >
                  Front
                </span>
              </Row>
            </Col>
            <Col span="6">
              {" "}
              <Row justify="center">
                {" "}
                <span
                  onClick={handleChange}
                  style={{
                    cursor: "pointer",
                    color: currentView === "Side(Left)" ? "#ad8b00" : null,
                  }}
                >
                  Side(Left)
                </span>
              </Row>
            </Col>
            <Col span="6">
              <Row justify="center">
                {" "}
                <span
                  onClick={handleChange}
                  style={{
                    cursor: "pointer",
                    color: currentView === "Side(Right)" ? "#ad8b00" : null,
                  }}
                >
                  Side(Right)
                </span>
              </Row>
            </Col>
            <Col span="6">
              <Row justify="center">
                {" "}
                <span
                  onClick={handleChange}
                  style={{
                    cursor: "pointer",
                    color: currentView === "Back" ? "#ad8b00" : null,
                  }}
                >
                  Back
                </span>
              </Row>
            </Col>
            {/* <Col span="4">
              <Row justify="center">
                {" "}
                <span onClick={handleChange}>Angle(Left)</span>
              </Row>
            </Col>{" "}
            <Col span="4">
              <Row justify="center">
                {" "}
                <span onClick={handleChange}>Angle(Right)</span>
              </Row>
            </Col> */}
            {/* <Select
              defaultValue="Front"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="Front">Front</Option>
              <Option value="Side(Left)">Side(Left)</Option>
              <Option value="Angle(left)">Angle(left)</Option>
              <Option value="Back">Back</Option>
              <Option value="Side(Right)">Side(Right)</Option>
              <Option value="Angle(Right)">Angle(Right)</Option>
            </Select> */}
          </Row>
        </Col>
        <Col span="24">
          <Row>{bodyTagElement}</Row>
        </Col>
        <Col span="24">
          <Row justify="center">
            {currentView === "Front" ? (
              <div
                style={{
                  width: "200px",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={bodySelected}
                ref={bodyContainer}
              >
                <img
                  src={BodyFront}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
              </div>
            ) : currentView === "Side(Left)" ? (
              <div style={{ width: "69.2px", position: "relative" }}>
                <img
                  src={BodySideLeft}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                {/* <div
                      class="red_body_blob eye"
                      style={blob(70, 400, "Forehead")}
                    ></div> */}
              </div>
            ) : currentView === "Angle(Left)" ? (
              <div style={{ width: "130px", position: "relative" }}>
                <img
                  src={BodyAngleLeft}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                {/* <div
                  class="red_body_blob eye"
                  style={blob(70, 400, "Forehead")}
                ></div> */}
              </div>
            ) : currentView === "Back" ? (
              <div style={{ width: "200px", position: "relative" }}>
                <img
                  src={BodyBack}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                {/* <div
                  class="red_body_blob eye"
                  style={blob(70, 400, "Forehead")}
                ></div> */}
              </div>
            ) : currentView === "Side(Right)" ? (
              <div style={{ width: "69.2px", position: "relative" }}>
                <img
                  src={BodySideRight}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                {/* <div
                      class="red_body_blob eye"
                      style={blob(70, 400, "Forehead")}
                    ></div> */}
              </div>
            ) : (
              <div style={{ width: "130px", position: "relative" }}>
                <img
                  src={BodyAngleRight}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                {/* <div
              class="red_body_blob eye"
              style={blob(70, 400, "Forehead")}
            ></div> */}
              </div>
            )}
          </Row>
        </Col>
        {/* <Col span="8">
          {" "}
          <img
            src={BodySideLeft}
            alt=""
            style={{
              maxWidth: "86.5px",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Col>
        <Col span="8">
          <img
            src={BodyAngleLeft}
            alt=""
            style={{
              maxWidth: "217px",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Col>

        <Col span="8">
          {" "}
          <div style={{ width: "250px", position: "relative" }}>
            <img
              src={BodyBack}
              alt=""
              style={{
                maxWidth: "250px",
                textAlign: "center",
                display: "block",
                margin: "0 auto",
              }}
            />{" "}
            <div
              class="red_body_blob eye"
              style={blob(70, 400, "Forehead")}
            ></div>
          </div>
        </Col>
        <Col span="8">
          {" "}
          <img
            src={BodySideRight}
            alt=""
            style={{
              maxWidth: "86.5px",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Col>
        <Col span="8">
          <img
            src={BodyAngleRight}
            alt=""
            style={{
              maxWidth: "217px",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Col> */}
      </Row>
    </>
  );
}
const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    body: (data) => dispatch(body(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Body);
