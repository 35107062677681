const INITIAL_STATE = {};
const applySetLandingPageFight = (state, action) => ({
  ...state,
  currentLandingPageFight: action.data,
});
const applySetCurrentMomentPlay = (state, action) => ({
  ...state,
  currentMomentPlay: action.data,
});
const applySetCurrentScoreRound = (state, action) => ({
  ...state,
  currentScoreRound: action.data,
});

function userPagesReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "LANDING_PAGE_FIGHT_SET":
      return applySetLandingPageFight(state, action);
    case "CURRENT_MOMENT_PLAY_SET":
      return applySetCurrentMomentPlay(state, action);
    case "CURRENT_SCORE_ROUND_SET":
      return applySetCurrentScoreRound(state, action);
    default:
      return state;
  }
}
export default userPagesReducers;
