import React from "react";

function DefenseManual() {
  return (
    <>
      <h4>Defensive Data</h4>
      <div>
        <p>
          <span style={Style.span}>Body Move</span> - Is when a fighter
          manuevers their torso in an attempt to evade a punch
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=WIEbtG5QrPc"
            target="_blank"
          >
            Body Move Example 1(Starting from 0:09)
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=6GRX9cDPrrE"
            target="_blank"
          >
            {" "}
            Body Move Example 2 (Starting from 41:35)
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Slip</span> - Is when a fighter manuevers
          their head in any direction in an attempt to evade a punch
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=7eWPwsCC3QY"
            target="_blank"
          >
            Slip Examples 1 (Starting at 0:13 )
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=rgHvy0Vei_U"
            target="_blank"
          >
            {" "}
            Slip Example 2
          </a>
          ,
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=eXJUD1nVgPg"
            target="_blank"
          >
            {" "}
            Slip Example 3 (Starting from 1:11)
          </a>
        </p>
      </div>
      <p>
        {" "}
        <span style={Style.span}>Push</span> - Is when a fighter pushes their
        opponent in an attempt to create distance between their body and the
        opponent's incoming punch
      </p>
      <div>
        <p>
          {" "}
          <span style={Style.span}>Shoulder Roll</span> - is a defensive boxing
          technique that occurs when a fighter uses their shoulder and the
          momentum of the opponent’s punch to offset the angle of a punch in an
          attempt to make the punch miss their head. Usually the fighter is
          standing in a way that uses their shoulder to shield their face from
          an opponent's incoming punches
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=RQ-C0c3NZ48"
            target="_blank"
          >
            Shoulder Roll Examples 1(Starting 0:20)
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=2AnWJAz_-9k&t=13s"
            target="_blank"
          >
            {" "}
            Shoulder Roll Example 2 (Starting 0:24)
          </a>
          ,
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=ZLO0pGzgAtc"
            target="_blank"
          >
            {" "}
            Shoulder Roll Example 3
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Parry</span> - Is a defensive boxing
          technique where a fighter using their hand to knock down or divert
          their opponent's incoming punch
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=kDAcTE0p4So"
            target="_blank"
          >
            Parry Examples (0:37, 3:14-3:52 )
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Blocking</span> - Is a defensive boxing
          technique that happens whereby a fighter uses one or both of their
          gloves to cover any part of their head in an attempt to minimize the
          damage taken from an opponent's incoming punch
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=YKeNuQJB8_k"
            target="_blank"
          >
            Blocking Examples (Starting at 0:23 )
          </a>
        </p>
      </div>
      <p>
        <span style={Style.span}>Step back</span> - Is when a fighter steps back
        in an direction in an attempt to evade an opponent's incoming punch
      </p>
      <div>
        <p>
          <span style={Style.span}>Clinching</span> - is a defensive tactic
          which basically sees a boxer tie his opponent's arms up so that they
          become entangled and any punch is not as effective.
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=9JRl3OkAJcs"
            target="_blank"
          >
            Clinching Examples
          </a>
        </p>
      </div>
      <p>
        <span style={Style.span}>No Defense</span> - is when an fighter makes no
        movement or tactic in order to evade an opponent's incoming punch. That
        is to say the fighter just takes the punch.
      </p>
      <p>
        <span style={Style.span}>Unsure</span> - is when a fighter makes an
        attempt of some sort to evade an opponent's incoming punch but it does
        not fall in line with any of the techniques described above.
      </p>
    </>
  );
}
const Style = {
  span: {
    fontWeight: "bold",
    color: "#E8B339",
  },
  videoLink: {
    fontStyle: "italic",
  },
};

export default DefenseManual;
