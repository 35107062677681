import React from "react";
import { Row, Col, Input, Checkbox, Menu, Dropdown } from "antd";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import BodySideLeft from "../../assets/img/body/body-side-left.svg";
import BodySideRight from "../../assets/img/body/body-side-right.png";
import BodyFront from "../../assets/img/body/body-front.svg";
import BodyBack from "../../assets/img/body/body-back.svg";
import BodyAngleLeft from "../../assets/img/body/body-angle-left.svg";
import BodyAngleRight from "../../assets/img/body/body-angle-right.svg";
function BodyViz() {
  const optionsWithDisabled = [
    { label: "Total", value: "total" },
    { label: "Land", value: "land" },
    { label: "Miss", value: "miss" },
  ];
  const menu = (
    <Menu>
      <Menu.Item>1st menu item</Menu.Item>
      <Menu.Item>2nd menu item</Menu.Item>
      <Menu.Item>3rd menu item </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Row>
        <Col span="24">
          {" "}
          <Row justify="space-between" gutter={[8, 32]}>
            <Col span="20">
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Col span="12">
                    {" "}
                    <Input
                      size="large"
                      placeholder="Jabs"
                      suffix={<DownOutlined />}
                    />
                  </Col>
                </Dropdown>
                <Col span="12">
                  <Checkbox.Group
                    options={optionsWithDisabled}
                    defaultValue={["Apple"]}
                    // onChange={onChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col span="4">
              <Row justify="end">
                <Col>
                  <InfoCircleOutlined />
                </Col>
              </Row>
            </Col>
            <Col span="24">
              <Row justify="center" gutter={[64, 0]}>
                <Col>Left</Col>
                <Col>Top</Col>
                <Col>Right</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[32, 32]}>
        <Col span="24">
          <Row justify="space-between">
            {/* <div
              style={{ width: "250px", position: "relative", display: "flex" }}
            >
              <img
                src={BodyFront}
                alt=""
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
              />

              <div
                class="red_body_blob eye"
                style={blob(70, 400, "Forehead")}
              ></div>

              <div
                class="red_body_blob chest"
                style={blob(30, "chest", 57, 400)}
              ></div>
              <div
                class="red_body_blob stomach"
                style={blob(10, "stomach", 31, 400)}
              ></div>
              <div
                style={{
                  width: "100px",
                  height: "5px",
                  position: "absolute",
                  top: "5%",
                  left: "0",
                  backgroundColor: "blue",
                }}
              ></div>
              <div
                style={{
                  height: "100px",
                  width: "5px",
                  position: "absolute",
                  top: "0",
                  left: "41.5%",
                  backgroundColor: "blue",
                }}
              ></div>
              <div
                style={{
                  height: "100px",
                  width: "5px",
                  position: "absolute",
                  top: "0",
                  left: "57%",
                  backgroundColor: "blue",
                }}
              ></div>
            </div> */}
            <Col span="8">
              {" "}
              <div style={{ width: "250px", position: "relative" }}>
                <img
                  src={BodyFront}
                  alt=""
                  style={{
                    maxWidth: "250px",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                <div
                  class="red_body_blob eye"
                  style={blob(70, 400, "Forehead")}
                ></div>
              </div>
            </Col>
            <Col span="8">
              {" "}
              <img
                src={BodySideLeft}
                alt=""
                style={{
                  maxWidth: "86.5px",
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Col>
            <Col span="8">
              <img
                src={BodyAngleLeft}
                alt=""
                style={{
                  maxWidth: "217px",
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Col>

            <Col span="8">
              {" "}
              <div style={{ width: "250px", position: "relative" }}>
                <img
                  src={BodyBack}
                  alt=""
                  style={{
                    maxWidth: "250px",
                    textAlign: "center",
                    display: "block",
                    margin: "0 auto",
                  }}
                />{" "}
                <div
                  class="red_body_blob eye"
                  style={blob(70, 400, "Forehead")}
                ></div>
              </div>
            </Col>
            <Col span="8">
              {" "}
              <img
                src={BodySideRight}
                alt=""
                style={{
                  maxWidth: "86.5px",
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Col>
            <Col span="8">
              <img
                src={BodyAngleRight}
                alt=""
                style={{
                  maxWidth: "217px",
                  textAlign: "center",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col span="8">
          {" "}
          <img
            src={BodySideLeft}
            alt=""
            style={{
              maxWidth: "44.85px",
              textAlign: "center",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Col> */}
      </Row>
    </>
  );
}
const blob = (land, totalLand, bodyPart) => {
  var size = (land / totalLand) * 40 + 10;
  const body = {
    Forehead: [0, 41.5, 58.5, 5],
    eye: "10px",
    chest: "50px",
    stomach: "160px",
  };
  const location = () => {
    var left = (body[bodyPart][2] - body[bodyPart][1]) / 2 + body[bodyPart][1];
    var top = (body[bodyPart][3] - body[bodyPart][0]) / 2 + body[bodyPart][0];

    return { left: left, top: top };
  };
  return {
    height: `${size}px`,
    width: `${size}px`,
    top: `calc(${location().top + "%"} - ${size / 2 + "px"})`,
    left: `calc(${location().left + "%"} - ${size / 2 + "px"})`,
  };
};

export default BodyViz;
