import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Row } from "antd";
import "./index.css";
import CenterRingLogo from "../../../../assets/img/ROUNDSCORE.png";
import { ring } from "../../../actions";
function Ring(props) {
  const [translateBlob, setTranslateBlob] = useState();
  const [clickedPos, setClickedPos] = useState();

  const greenBlob = useRef(null);

  const ringContainer = useRef(null);

  const getClickPosition = (e) => {
    var parentPosition = getPosition(ringContainer.current);
    console.log(
      greenBlob.current.offsetTop,
      e.clientY,
      parentPosition.y,
      e.clientY - parentPosition.y
    );
    var clickedPosX =
      e.clientX - parentPosition.x - greenBlob.current.offsetWidth / 2 - 25;
    var clickedPosY =
      e.clientY - parentPosition.y - greenBlob.current.offsetHeight / 2 - 25;
    var translate3dBlobTemp = `translate(${clickedPosX}px, ${clickedPosY}px)`;
    setClickedPos({ x: clickedPosX, y: clickedPosY });
    setTranslateBlob(translate3dBlobTemp);
  };

  useEffect(() => {
    props.ring(clickedPos);
  }, [clickedPos]);

  useEffect(() => {
    // if (props.currentLevel) {
    //   setClicked(true);
    //   setSelectedButton(props.currentLevel);
    // }
    props.ring("");
  }, []);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setTranslateBlob(
        `translate(${props.currentRowData[props.currentRowIndex]?.ringX}px, ${
          props.currentRowData[props.currentRowIndex]?.ringY
        }px)`
      );
    } else {
      setTranslateBlob(`translate(${25}px, ${25}px)`);
    }
  }, [props.currentRowData, props.currentRowIndex]);
  const getPosition = (el) => {
    var xPosition = 0;
    var yPosition = 0;

    while (el) {
      if (el.tagName == "BODY") {
        // deal with browser quirks with body/window/document and page scroll
        var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
        var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

        xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
        yPosition += el.offsetTop - yScrollPos + el.clientTop;
      } else {
        xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPosition,
      y: yPosition,
    };
  };

  return (
    <>
      <Row justify="center">
        <div
          className="content--container__Ring"
          ref={ringContainer}
          onClick={getClickPosition}
        >
          <div className="blue--corner__Ring"></div>
          <div className="red--corner__Ring"></div>
          <img src={CenterRingLogo} alt="" className="centerRingLogoRing" />
          <div class="innerRingRing"></div>
          <div
            ref={greenBlob}
            style={{
              position: "absolute",
              left: "25px",
              top: "25px",
              background: "rgba(51, 217, 178, 1)",
              borderRadius: "50%",
              boxShadow: "0 0 0 0 rgba(51, 217, 178, 1)",
              height: "50px",
              width: "50px",
              zIndex: 200,
              transform: !translateBlob ? null : translateBlob,
              transition: `transform ${0.3}s ease-in`,
            }}
          ></div>
        </div>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ring: (data) => dispatch(ring(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Ring);
