import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function TallyScore(props) {
  const [currentTally, setCurrentTally] = useState(0);
  useEffect(() => {
    if (props.currentTotal.start) {
      console.log(props.start);
      var totalTally = 0;
      var FighterATally = 0;
      var FighterBTally = 0;
      if (props.start[0]) {
        props.start[0].forEach((element, i) => {
          if (props.currentTotal[`Round ${i}`]) {
            FighterATally =
              FighterATally + props.currentTotal[`Round ${i}`].F1EA;
            FighterBTally =
              FighterBTally + props.currentTotal[`Round ${i}`].F2EA;
            if (props.fighter === "A") {
              totalTally = totalTally + props.currentTotal[`Round ${i}`].F1EA;
            } else if (props.fighter === "B") {
              totalTally = totalTally + props.currentTotal[`Round ${i}`].F2EA;
            }
          } else if (props.currentTotal[`Round 999`]) {
            FighterATally =
              FighterATally + props.currentTotal[`Round 999`].F1EA;
            FighterBTally =
              FighterBTally + props.currentTotal[`Round 999`].F2EA;
            if (props.fighter === "A") {
              totalTally = totalTally + props.currentTotal[`Round 999`].F1EA;
            } else if (props.fighter === "B") {
              totalTally = totalTally + props.currentTotal[`Round 999`].F2EA;
            }
          }
        });
      }
      props.onSetTally({ F1: FighterATally, F2: FighterBTally });
      setCurrentTally(totalTally);
    }
  }, [props.currentTotal]);

  useEffect(() => {
    console.log(currentTally);
  }, [currentTally]);
  return (
    <>
      {props.scoreResultPage ? (
        currentTally
      ) : props.mostRecentScore ? (
        <div
          className={
            props.fighter === "A"
              ? "tallyScoreBackgroundA"
              : "tallyScoreBackgroundB"
          }
        >
          <p className="tallyScore">{props.recentTally}</p>
        </div>
      ) : (
        <div
          className={
            props.fighter === "A"
              ? "tallyScoreBackgroundA"
              : "tallyScoreBackgroundB"
          }
        >
          <p className="tallyScore">{currentTally}</p>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSetTally: (data) => dispatch({ type: "CURRENT_TALLY_SET", data }),
});
const mapStateToProps = (state, props) => ({
  currentMoment: state.currentUserPages.currentMomentPlay
    ? state.currentUserPages.currentMomentPlay
    : 0,

  currentTotal: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound
    : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TallyScore);
