import React from "react";
import { Row, Col, Input, Checkbox, Menu, Dropdown } from "antd";
import { Column } from "@ant-design/charts";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
function ColumnViz() {
  var data = [
    {
      name: "London",
      月份: "Jan.",
      月均降雨量: 18.9,
    },
    {
      name: "London",
      月份: "Feb.",
      月均降雨量: 28.8,
    },
    {
      name: "London",
      月份: "Mar.",
      月均降雨量: 39.3,
    },
    {
      name: "London",
      月份: "Apr.",
      月均降雨量: 81.4,
    },
    {
      name: "London",
      月份: "May",
      月均降雨量: 47,
    },
    {
      name: "London",
      月份: "Jun.",
      月均降雨量: 20.3,
    },
    {
      name: "London",
      月份: "Jul.",
      月均降雨量: 24,
    },
    {
      name: "London",
      月份: "Aug.",
      月均降雨量: 35.6,
    },
    {
      name: "Berlin",
      月份: "Jan.",
      月均降雨量: 12.4,
    },
    {
      name: "Berlin",
      月份: "Feb.",
      月均降雨量: 23.2,
    },
    {
      name: "Berlin",
      月份: "Mar.",
      月均降雨量: 34.5,
    },
    {
      name: "Berlin",
      月份: "Apr.",
      月均降雨量: 99.7,
    },
    {
      name: "Berlin",
      月份: "May",
      月均降雨量: 52.6,
    },
    {
      name: "Berlin",
      月份: "Jun.",
      月均降雨量: 35.5,
    },
    {
      name: "Berlin",
      月份: "Jul.",
      月均降雨量: 37.4,
    },
    {
      name: "Berlin",
      月份: "Aug.",
      月均降雨量: 42.4,
    },
  ];
  var config = {
    data: data,
    isGroup: true,
    xField: "月份",
    yField: "月均降雨量",
    seriesField: "name",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  const optionsWithDisabled = [
    { label: "Total", value: "total" },
    { label: "Land", value: "land" },
    { label: "Miss", value: "miss" },
  ];
  const menu = (
    <Menu>
      <Menu.Item>1st menu item</Menu.Item>
      <Menu.Item>2nd menu item</Menu.Item>
      <Menu.Item>3rd menu item </Menu.Item>
    </Menu>
  );
  return (
    <>
      {/* <h4>
                  {" "}
                  Column and grouped Chart(Bar and grouped chart for smaller
                  screens)
                </h4>
                Total action types and their performance (Customizable: All
                types of punch, combo,defense - Status DropDown Checkbox [All,
                land, Miss ]) - Check combo stats percentage as a group I.e 3/4
                punches landed is 75% for that combo and then average out all
                the 4 punch combo percentage for that round */}
      <Row>
        <Col span="24">
          {" "}
          <Row justify="space-between" gutter={[8, 32]}>
            <Col span="12">
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Col span="12">
                    {" "}
                    <Input
                      size="large"
                      placeholder="Jabs"
                      suffix={<DownOutlined />}
                    />
                  </Col>
                </Dropdown>
                <Col span="12">
                  <Checkbox.Group
                    options={optionsWithDisabled}
                    defaultValue={["Apple"]}
                    // onChange={onChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col span="12">
              <Row justify="end">
                <Col>
                  <InfoCircleOutlined />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <Column {...config} />
        </Col>{" "}
      </Row>
    </>
  );
}

export default ColumnViz;
