import React, { useState, useEffect } from "react";
import ButtonClicked from "./ButtonClicked";
import { connect } from "react-redux";

const ClearSome = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    props.onSetClearSome(clicked);
  }, [clicked]);

  useEffect(() => {
    if (props.currentClear) {
      setClicked(props.currentClear);
      setSelectedButton("Clear");
    }
  }, []);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
        setClicked(false);
      }
    }
  };

  return (
    <ButtonClicked
      clearRow={true}
      value="Clear"
      onClicked={onClicked}
      clickedState={clicked}
      selected={selectedButton}
    />
  );
};
const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome,
});
const mapDispatchToProps = (dispatch) => {
  return {
    onSetClearSome: (data) => dispatch({ type: "CLEAR_SOME_SET", data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClearSome);
