import React, { useState } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withAuthorization, withEmailVerification } from "../Session";
import * as ROLES from "../../constants/roles";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { Input, Button, Form, Row, Col, message } from "antd";

const FighterAdd = (props) => {
  const INITIAL_VALUES = {
    fighterName: "",
    fighterNickName: "",
  };
  const [formValue, setFormValue] = useState(INITIAL_VALUES);

  const formSubmit = () => {
    props.firebase
      .fighters()
      .add({
        ...formValue,
        createdAt: props.firebase.fieldValue.serverTimestamp(),
      })
      .then(() => {
        message.success("Fighter was Added");
        setFormValue(INITIAL_VALUES);
      });
  };

  const { fighterName, fighterNickName } = formValue;

  const isInvalid = fighterName === "";

  return (
    <>
      <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
        Add a Fighter
      </h1>
      <Form onFinish={formSubmit} name="basic">
        <Row justify="center" gutter={[16, 16]}>
          <Col span={8} align="center">
            <Input
              name="fighterName"
              type="text"
              value={fighterName}
              placeholder="Name of Fighter"
              onChange={(e) =>
                setFormValue({ ...formValue, fighterName: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={8} align="center">
            <Input
              name="fighterNickName"
              value={fighterNickName}
              type="text"
              placeholder="NickName of Fighter"
              onChange={(e) =>
                setFormValue({ ...formValue, fighterNickName: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          {" "}
          <Col span={8} align="center">
            <Button disabled={isInvalid} htmlType="submit">
              Add Fighter
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(FighterAdd);
