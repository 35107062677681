import {
  FIGHTER,
  ROUND,
  HAND,
  PUNCH,
  STATUS,
  RANGE,
  STANCE,
  LEVEL,
  RING,
  DEFENSE,
  BODY,
  MOMENT,
  MISC,
} from "../components/actions/actions";

const INITIAL_STATE = {
  fighter: "",
  round: 0,
  hand: "",
  punch: "",
  status: "",
  range: "",
  stance: "",
  level: "",
  ring: {},
  defense: [],
  body: [],
  moment: "",
  misc: "",
};

const actionTable = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIGHTER:
      return { ...state, fighter: action.payload };
    case ROUND:
      return { ...state, round: action.payload };
    case HAND:
      return { ...state, hand: action.payload };
    case PUNCH:
      return { ...state, punch: action.payload };
    case STATUS:
      return { ...state, status: action.payload };
    case RANGE:
      return { ...state, range: action.payload };
    case STANCE:
      return { ...state, stance: action.payload };
    case LEVEL:
      return { ...state, level: action.payload };
    case RING:
      return { ...state, ring: action.payload };
    case DEFENSE:
      return { ...state, defense: action.payload };
    case BODY:
      return { ...state, body: action.payload };
    case MOMENT:
      return { ...state, moment: action.payload };
    case MISC:
      return { ...state, misc: action.payload };
    default:
      return state;
  }
};
export default actionTable;
