import React, { useState } from "react";
import { Button } from "antd";
import OffenseManual from "./OffenseManual";
import DefenseManual from "./DefenseManual";
import BodyManual from "./BodyManual";
import ShortCut from "./ShortCut";

function Manual({ currentTable }) {
  const [shortCutStatus, setShortCutStatus] = useState(false);
  const shortCutStatusSetter = () => {
    setShortCutStatus(!shortCutStatus);
  };
  return (
    <>
      <Button onClick={shortCutStatusSetter}>
        {shortCutStatus ? "Hide Shortcut" : "Show Shortcut"}
      </Button>

      {shortCutStatus && (
        <>
          <h3> Keyboard Shortcuts</h3>
          <ShortCut />
        </>
      )}

      {currentTable === "Offense" && (
        <>
          <h3>Offense Manual</h3>
          <OffenseManual />
        </>
      )}
      {currentTable === "Defense" && (
        <>
          <h3>Defense Manual</h3>
          <DefenseManual />
        </>
      )}
      {currentTable === "Body" && (
        <>
          <h3>Body Manual</h3>
          <BodyManual />
        </>
      )}
    </>
  );
}

export default Manual;
