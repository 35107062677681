import React from "react";
import { compose } from "recompose";
import { Switch, Route } from "react-router-dom";

import * as ROLES from "../../constants/roles";
import { withAuthorization, withEmailVerification } from "../Session";
import Fights from "../Fights/Fights";
import Collection from "../Collection";
import * as ROUTES from "../../constants/routes";

const FightList = () => (
  <div>
    <h1>List of Fights</h1>
    <p>The Home Page is accessible by Admin Users ONLY!</p>

    <Fights />
  </div>
);

const condition = (authUser) =>
  authUser &&
  (!!authUser.roles[ROLES.ADMIN] ||
    !!authUser.roles[ROLES.DATALEVEL1] ||
    !!authUser.roles[ROLES.DATALEVEL2]);

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FightList);
