import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { Table } from "antd";

const TableData = (props) => {
  const [Data, setData] = useState([]);
  const [column, setColumn] = useState("");
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (props.currentTableMenu === "Defense" && selectedRows[0]) {
        props.onSetCurrentRowData([
          { ...selectedRows[0], defense: selectedRows[0].defense.split(", ") },
        ]);
        props.onSetCurrentRowData(selectedRows);
      } else if (props.currentTableMenu === "Body" && selectedRows[0]) {
        props.onSetCurrentRowData([
          { ...selectedRows[0], body: selectedRows[0].body.split(", ") },
        ]);
      } else {
        props.onSetCurrentRowData(selectedRows);
        props.onSetCurrentRowIndex(0);
      }
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      // props.onSetCurrentRowIndex(0);
      // props.onSetCurrentRowData(selectedRows);
      // // props.onSetCurrentRowIndex(0);
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
  };

  useEffect(() => {
    console.log(props.currentFighterUID, props.currentColumn);
    console.log(props.currentColumn);

    if (props.currentColumn === "Moment") {
      props.firebase.moments(props.currentFightUID).onSnapshot((snapshot) => {
        if (snapshot.size) {
          let momentDatum = snapshot.docs.map((doc) => ({
            key: doc.id,
            moment: doc.data().action,
            round: doc.data().round,
            roundTime: doc.data().roundTime,
            videoTime: doc.data().videoTime,
          }));
          setData(momentDatum);
        }
      });
    } else if (props.currentFighterUID !== "") {
      if (props.currentColumn === "Offense") {
        props.firebase
          .fightData(props.currentFightUID, props.currentFighterUID)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let actionDatum = snapshot.docs.map((doc) => ({
                key: doc.id,
                punch: doc.data().punch,
                hand: doc.data().hand,
                status: doc.data().status,
                range: doc.data().range,
                level: doc.data().level,
                stance: doc.data().stance,
                round: doc.data().round,
                roundTime: doc.data().roundTime,
                videoTime: doc.data().videoTime,
              }));
              setData(actionDatum);
            }
          });
      } else if (props.currentColumn === "Defense") {
        props.firebase
          .fightData(props.currentFightUID, props.currentFighterUID)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let actionDatum = snapshot.docs.map((doc) => ({
                key: doc.id,
                defense:
                  doc.data().defense && doc.data().defense.length > 0
                    ? doc
                        .data()
                        .defense.filter(
                          (word) => word !== "" && word.length > 1
                        )
                        .join(", ")
                    : "",
                round: doc.data().round,
                roundTime: doc.data().roundTime,
                videoTime: doc.data().videoTime,
              }));
              setData(actionDatum);
            }
          });
      } else if (props.currentColumn === "Body") {
        props.firebase
          .fightData(props.currentFightUID, props.currentFighterUID)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let actionDatum = snapshot.docs.map((doc) => ({
                key: doc.id,
                body:
                  doc.data().body && doc.data().body.length > 0
                    ? doc
                        .data()
                        .body.filter((word) => word !== "")
                        .join(", ")
                    : "",
                // body: doc.data.body?.map((docu) => docu.bodypart),
                round: doc.data().round,
                roundTime: doc.data().roundTime,
                videoTime: doc.data().videoTime,
              }));
              setData(actionDatum);
            }
          });
      } else if (props.currentColumn === "Ring") {
        props.firebase
          .fightData(props.currentFightUID, props.currentFighterUID)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let actionDatum = snapshot.docs.map((doc) => ({
                key: doc.id,
                ring: doc.data().ring,
                ringX: doc.data().ring?.x,
                ringY: doc.data().ring?.y,
                round: doc.data().round,
                roundTime: doc.data().roundTime,
                videoTime: doc.data().videoTime,
              }));
              setData(actionDatum);
            }
          });
      } else if (props.currentColumn === "Misc") {
        props.firebase
          .fightData(props.currentFightUID, props.currentFighterUID)
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let actionDatum = snapshot.docs.map((doc) => ({
                key: doc.id,
                misc: doc.data().misc,
                round: doc.data().round,
                roundTime: doc.data().roundTime,
                videoTime: doc.data().videoTime,
              }));
              setData(actionDatum);
            }
          });
      }
    }
  }, [props.currentFighterUID, props.currentColumn]);
  return (
    <Table
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
      columns={props.columnData}
      dataSource={Data}
      style={{ width: 100 + `%`, height: 300 }}
      scroll={{ y: 240 }}
      pagination={true}
    />
  );
};
const mapDispatchToProps = (dispatch) => ({
  onSetCurrentRowData: (data) =>
    dispatch({ type: "CURRENT_ROW_DATA_SET", data }),
  onSetCurrentRowIndex: (data) =>
    dispatch({ type: "CURRENT_ROW_INDEX_SET", data }),
});
const mapStateToProps = (state) => ({
  currentFightUID: state.currentFightReducer.currentFightID,
  currentFighterUID: state.actionState.fighter,
  currentTableMenu: state.currentFightReducer.currentTableMenu
    ? state.currentFightReducer.currentTableMenu
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(TableData);
