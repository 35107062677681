import React, { useLayoutEffect, useState, useEffect } from "react";
import { Layout } from "antd";
import useViewport from "../../useViewPort";
import ContactUsForm from "./ContactUsForm";

import Footer from "./Footer";
function ContactUs() {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const { width } = useViewport();
  const { Content } = Layout;

  const mobileBreakpoint = 769;
  const tabletBreakpoint = 1025;

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [width]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Content style={mobileView ? style.contentMobile : style.content}>
      <ContactUsForm />
      <Footer />
    </Content>
  );
}

const style = {
  content: {
    paddingLeft: 10 + `%`,
    paddingRight: 10 + `%`,
    marginTop: 70 + `px`,
    width: 100 + "%",
  },
  contentMobile: {
    paddingLeft: 5 + `%`,
    paddingRight: 5 + `%`,
    marginTop: 70 + `px`,
    width: 100 + "%",
  },
};

export default ContactUs;
