export const Ropes = [
  "0 - 4",
  "1 - 4",
  "2 - 4",
  "3 - 4",
  "4 - 4",
  "0 - 3",
  "0 - 2",
  "0 - 1",
  "0 - 0",
  "1 - 0",
  "2 - 0",
  "3 - 0",
  "4 - 0",
  "4 - 1",
  "4 - 2",
  "4 - 3",
];
export const middleRing = [
  "1 - 3",
  "1 - 2",
  "1 - 1",
  "2 - 3",
  "3 - 3",
  "3 - 2",
  "3 - 1",
  "2 - 1",
  "2 - 2",
];
export const ringLocationCSS = {
  "2 - 2": { top: "-130px", left: "125px" },
  "0 - 1": { top: "-70px", left: "10px" },
  "0 - 0": { top: "-20px", left: "10px" },
  "0 - 2": { top: "-130px", left: "10px" },
  "0 - 3": { top: "-190px", left: "10px" },
  "0 - 4": { top: "-240px", left: "10px" },
  "1 - 0": { top: "-20px", left: "60px" },
  "2 - 0": { top: "-20px", left: "120px" },
  "3 - 0": { top: "-20px", left: "180px" },
  "4 - 0": { top: "-20px", left: "230px" },
  "4 - 1": { top: "-70px", left: "230px" },
  "4 - 2": { top: "-130px", left: "230px" },
  "4 - 3": { top: "-190px", left: "230px" },
  "4 - 4": { top: "-240px", left: "230px" },
  "1 - 4": { top: "-240px", left: "60px" },
  "2 - 4": { top: "-240px", left: "120px" },
  "3 - 4": { top: "-240px", left: "180px" },
  "1 - 3": { top: "-190px", left: "60px" },
  "1 - 2": { top: "-130px", left: "60px" },
  "1 - 1": { top: "-70px", left: "60px" },
  "2 - 3": { top: "-190px", left: "125px" },
  "2 - 1": { top: "-70px", left: "125px" },
  "3 - 1": { top: "-70px", left: "180px" },
  "3 - 2": { top: "-130px", left: "180px" },
  "3 - 3": { top: "-190px", left: "180px" },
};

export const bodyLocationCSS = {
  "Temple Right": { top: "25px", left: "140px", img: "right" },
  Forehead: { top: "25px", left: "135px", img: "front" },
  "Eye/CheekBone Left": { top: "27px", left: "140px", img: "left-angle" },
  "Temple Left": { top: "25px", left: "132px", img: "left" },
  "Eye/CheekBone Right": { top: "27px", left: "132px", img: "right-angle" },
  "Jaw Left": { top: "37px", left: "128px", img: "left" },
  "Jaw Right": { top: "37px", left: "143px", img: "right" },
  Mouth: { top: "37px", left: "135px", img: "front" },
  "Neck Left": { top: "45px", left: "135px", img: "left" },
  "Neck Right": { top: "45px", left: "135px", img: "right" },
  "Neck Front": { top: "48px", left: "135px", img: "front" },
  Back: { top: "45px", left: "135px", img: "back" },
  "Shoulder Left": { top: "65px", left: "160px", img: "front" },
  "Shoulder Right": { top: "65px", left: "110px", img: "front" },
  "Shoulder Left Side": { top: "63px", left: "143px", img: "left" },
  "Shoulder Right Side": { top: "63px", left: "127px", img: "right" },
  "Chest Left": { top: "70px", left: "123px", img: "front" },
  "Chest Right": { top: "70px", left: "147px", img: "front" },
  "Bicep Left": { top: "80px", left: "170px", img: "left-angle" },
  "Bicep Right": { top: "80px", left: "100px", img: "right-angle" },
  "Forearm Left": { top: "120px", left: "140px", img: "left" },
  "Forearm Right": { top: "120px", left: "136px", img: "right" },
  "Stomach Upper": { top: "95px", left: "135px", img: "front" },
  "Stomach Lower": { top: "115px", left: "135px", img: "front" },
  "Rib Left": { top: "95px", left: "150px", img: "left-angle" },
  "Rib Right": { top: "95px", left: "120px", img: "right-angle" },
  "Hip Left": { top: "115px", left: "150px", img: "left-angle" },
  "Hip Right": { top: "115px", left: "120px", img: "right-angle" },
  BTB: { top: "135px", left: "135px", img: "front" },
};
