import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "antd";

function ProgressBar(props) {
  const [currentTally, setCurrentTally] = useState(0);

  useEffect(() => {
    if (props.currentTotal.start) {
      console.log(props.start);
      var totalTally = 1;
      props.start[0].forEach((element, i) => {
        if (props.currentTotal[`Round ${i}`]) {
          totalTally++;
        }
      });
      if (totalTally < props.start[0].length) {
        props.onSetEndStatus(false);
      } else if (props.currentRound > props.start[0].length) {
        props.onSetEndStatus(true);
      }
      setCurrentTally(totalTally);
    }
  }, [props.currentTotal]);

  return (
    <Progress
      onClick={() => {
        console.log(props.start[0].length);
      }}
      onChange={props.TallyChange(currentTally)}
      strokeColor={{
        "0%": "#fff",
        "100%": "#fff",
      }}
      percent={
        props.currentTotal.start
          ? parseInt((currentTally / props.start[0].length) * 100)
          : 0
      }
      status="active"
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSetEndStatus: (data) => dispatch({ type: "END_STATUS_SET", data }),
});

const mapStateToProps = (state, props) => ({
  currentRound: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.currentRound
    : 0,
  currentMoment: state.currentUserPages.currentMomentPlay
    ? state.currentUserPages.currentMomentPlay
    : 0,

  currentTotal: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound
    : [],
  currentTally: props.roundScore ? props.roundScore.currentTally : {},
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
