import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { round } from "../../actions";

const { Option } = Select;

const RoundList = (props) => {
  const [round, setRound] = useState(0);

  // useEffect(() => {
  //   props.round(round);
  // }, [round]);
  useEffect(() => {
    setRound(props.currentRound);
  }, [props.currentRound]);

  useEffect(() => {
    if (props.currentClear === true) {
      props.round(0);
    } else {
    }
  }, [props.currentClear]);
  useEffect(() => {
    if (props.currentRowData.length > 0) {
      // setRound(props.currentRowData[props.currentRowIndex].round);
      props.round(props.currentRowData[props.currentRowIndex]?.round);
    } else {
      // props.round(0);
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const changeRound = (e) => {
    // setRound(e);
    props.round(e);
  };

  return (
    <Select
      defaultValue={props.currentRound === null ? 0 : props.currentRound}
      onChange={changeRound}
      style={{ width: 90 + `%` }}
      value={round}
    >
      <Option value={0}>Pick a Round</Option>
      <Option value={1}>1</Option>
      <Option value={2}>2</Option>
      <Option value={3}>3</Option>
      <Option value={4}>4</Option>
      <Option value={5}>5</Option>
      <Option value={6}>6</Option>
      <Option value={7}>7</Option>
      <Option value={8}>8</Option>
      <Option value={9}>9</Option>
      <Option value={10}>10</Option>
      <Option value={11}>11</Option>
      <Option value={12}>12</Option>
      <Option value={13}>13</Option>
      <Option value={14}>14</Option>
      <Option value={15}>15</Option>
    </Select>
  );
};

const mapStateToProps = (state) => ({
  currentRound: state.actionState.round,
  currentClear: state.currentFightReducer.currentClearSome,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => ({
  round: (data) => dispatch(round(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RoundList);
