const INITIAL_STATE = {};

const applySetSearchQueryResult = (state, action) => ({
  ...state,
  searchQueryResult: action.data,
});

function searchQueryResultReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SEARCH_QUERY_RESULT_SET": {
      return applySetSearchQueryResult(state, action);
    }

    default:
      return state;
  }
}
export default searchQueryResultReducer;
