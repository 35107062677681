import React, { useEffect, useState } from "react";
import {
  format,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInSeconds,
  differenceInMinutes,
} from "date-fns";

import { withFirebase } from "../Firebase";

import { Row, Col, Timeline } from "antd";

const RecentScore = (props) => {
  const [userName, setUserName] = useState("");
  const [diffTime, setDiffTime] = useState("");
  useEffect(() => {
    var diff = 0;
    props.firebase
      .user(props.userID)
      .get()
      .then((result) => {
        console.log(result.data().username);
        setUserName(result.data().username);
      });
    var diff = differenceInSeconds(new Date(), props.date);

    if (diff < 60) {
      var diff = diff + `sec`;
    } else if (diff < 3600) {
      var diff = differenceInMinutes(new Date(), props.date) + `m`;
    } else if (diff < 86400) {
      var diff = differenceInHours(new Date(), props.date) + `h`;
    } else if (diff < 604800) {
      var diff = differenceInDays(new Date(), props.date) + `d`;
    } else if (diff >= 604800) {
      var diff = differenceInWeeks(new Date(), props.date) + `w`;
    } else {
      diff = diff + `s`;
    }
    setDiffTime(diff);
  }, []);
  return (
    <Timeline.Item
      color={props.f1Score > props.f2Score ? "#e8b339" : "#177ede"}
    >
      {diffTime} -{" "}
      {props.currentFightInfo.fighterA
        ? props.currentFightInfo.fighterA.split(" ")[
            props.currentFightInfo.fighterA.split(" ").length - 1
          ]
        : ""}{" "}
      {props.f1Score} - {props.f2Score}{" "}
      {props.currentFightInfo.fighterB
        ? props.currentFightInfo.fighterB.split(" ")[
            props.currentFightInfo.fighterB.split(" ").length - 1
          ]
        : ""}
    </Timeline.Item>
    // <Row justify="space-between" key={props.key}>
    //   <Col span={12}>{diffTime}</Col>
    //   <Col span={12}></Col>
    //   <Col span={8}>
    //     {props.currentFightInfo.fighterA
    //       ? props.currentFightInfo.fighterA.split(" ")[
    //           props.currentFightInfo.fighterA.split(" ").length - 1
    //         ]
    //       : ""}{" "}
    //     {props.f1Score}
    //   </Col>
    //   <Col span={8}>
    //     {props.currentFightInfo.fighterB
    //       ? props.currentFightInfo.fighterB.split(" ")[
    //           props.currentFightInfo.fighterB.split(" ").length - 1
    //         ]
    //       : ""}{" "}
    //     {props.f2Score}{" "}
    //   </Col>
    // </Row>
  );
};

export default withFirebase(RecentScore);
