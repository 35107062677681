import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as ROLES from "../../constants/roles";
import { withAuthorization, withEmailVerification } from "../Session";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Col, Row, Button } from "antd";

function MyFights(props) {
  const [myFights, setMyFights] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState("");

  useEffect(() => {
    setCurrentUserEmail(props.authUser.email);
  }, []);

  useEffect(() => {
    console.log();
    if (props.authUser.roles?.ADMIN) {
      props.firebase
        .fights()
        .get()
        .then((querySnapshot) => {
          var tempMyFights = [];
          querySnapshot.forEach((doc) => {
            tempMyFights.push([doc.data(), doc.id]);
          });
          setMyFights(tempMyFights);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    } else {
      props.firebase
        .fights()
        .where("customerReady", "==", true)
        .get()
        .then((querySnapshot) => {
          var tempMyFights = [];
          querySnapshot.forEach((doc) => {
            tempMyFights.push([doc.data(), doc.id]);
          });
          setMyFights(tempMyFights);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }, [currentUserEmail]);

  useEffect(() => {
    console.log(myFights);
  }, [myFights]);
  return (
    <Row
      justify="center"
      style={{
        paddingLeft: 5 + `%`,
        paddingRight: 5 + `%`,
        marginTop: 20 + `px`,
      }}
    >
      <Col span="24">
        <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
          My Film Study
        </h1>
      </Col>{" "}
      <Col span="20">
        {myFights.length > 0 ? (
          myFights.map((fight) => (
            <Row
              justify="center"
              gutter={[16, 16]}
              key={fight[1]}
              style={{
                marginBottom: 30 + "px",
              }}
            >
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <img
                  src={fight[0].fightImageURL}
                  alt=""
                  style={{
                    width: 100 + `%`,
                    borderRadius: 4 + `px`,
                  }}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <h2>
                  {fight[0].fighterA} vs {fight[0].fighterB}
                </h2>
                <p style={{ textTransform: "capitalize" }}>
                  WeightClass : {fight[0].weightClass}
                </p>
                <p>Date : {fight[0].date}</p>
                <Button
                  onClick={() => {
                    props.onSetCurrentFilmStudy(fight[0]);
                  }}
                >
                  <Link to={`/film-study/${fight[1]}`}>Study Film</Link>
                </Button>
              </Col>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <Col span="12">
              <p style={{ textAlign: "center" }}>
                You don't have access to any fights at the moment.
              </p>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

const condition = (authUser) =>
  authUser &&
  (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.CUSTOMER]);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentFilmStudy: (data) =>
    dispatch({ type: "CURRENT_FILM_STUDY_SET", data }),
});
export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition)
)(MyFights);
