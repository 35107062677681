const INITIAL_STATE = {};

const applySetFilmStudyMoment = (state, action) => ({
  ...state,
  filmStudyMoment: action.data,
});

function filmStudyMomentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FILM_STUDY_MOMENT_SET": {
      return applySetFilmStudyMoment(state, action);
    }

    default:
      return state;
  }
}
export default filmStudyMomentReducer;
