import React, { useEffect, useState } from "react";
import { Row, Col, Timeline } from "antd";
import RecentScore from "./RecentScore";

function MostRecentScore(props) {
  const [allDaScores, setAllDASCORES] = useState([]);
  useEffect(() => {
    var allDaScoreTemp = [];
    if (props.allScores) {
      props.allScores.forEach((element, i) => {
        console.log(element, i);
        allDaScoreTemp.push(element);

        for (const [key, value] of Object.entries(element)) {
          console.log(value.data);
          // allDaScoreTemp.push(key);
        }
      });
      allDaScoreTemp.sort(function (a, b) {
        return (
          (!b.data
            ? null
            : b.data.editedAt
            ? b.data.editedAt.toDate()
            : b.data.createdAt
            ? b.data.createdAt.toDate()
            : b) -
          (!a.data
            ? null
            : a.data.editedAt
            ? a.data.editedAt.toDate()
            : a.data.createdAt
            ? a.data.createdAt.toDate()
            : a)
        );
      });
      // console.log(allDaScoreTemp);

      setAllDASCORES(allDaScoreTemp);
    }
  }, []);

  useEffect(() => {
    // allDaScores.forEach((element) => {
    //   console.log(element);
    // });
    console.log(allDaScores);
  }, [allDaScores]);
  return (
    <Row justify="space-between">
      {/* <Col span={24}> */}

      <Timeline mode="left">
        {allDaScores.map((el, i) => {
          return (
            <RecentScore
              userID={el.userID}
              date={
                el.data.editedAt
                  ? el.data.editedAt.toDate()
                  : el.data.createdAt
                  ? el.data.createdAt.toDate()
                  : null
              }
              currentFightInfo={props.currentFightInfo}
              key={i}
              f1Score={el.data.currentTally.F1}
              f2Score={el.data.currentTally.F2}
            />
          );
        })}
      </Timeline>
      {/* </Col> */}
    </Row>
  );
}

export default MostRecentScore;
