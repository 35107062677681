import React from "react";

function ShortCut() {
  return (
    <>
      <h4>Play shortcuts</h4>
      <p>
        <span style={Style.span}>c</span> - Play the current selected row
      </p>
      <p>
        <span style={Style.span}>Space bar</span> - Play or pauses video
      </p>
      <h4>Navigating the video (works best when video is paused)</h4>
      <p>
        {" "}
        <span style={Style.span}>a</span> - Step back 1 frame
      </p>
      <p>
        {" "}
        <span style={Style.span}>d</span> - Step forward 1 frame
      </p>
      <h4>Punch Data</h4>
      <p>
        <span style={Style.span}>w</span> - Step back 1 sec
      </p>
      <p>
        <span style={Style.span}>r</span> - Step forward 1 sec
      </p>
      <h4>Navigating the data table </h4>
      <p>
        <span style={Style.span}>s</span> - Move down the selected rows
      </p>
      <p>
        <span style={Style.span}>f</span> - Move up the selected rows
      </p>
      <p>
        <span style={Style.span}>c</span> - Play the current selected row
      </p>
      <p>
        <span style={Style.span}>g</span> - Edit the current selected row with
        the data currently selected
      </p>
    </>
  );
}
const Style = {
  span: {
    fontWeight: "bold",
    color: "#E8B339",
  },
};

export default ShortCut;
