import React, { useState, useEffect } from "react";
import { Card, Avatar, Button } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { withFirebase } from "../Firebase";
import { connect } from "react-redux";

import { compose } from "recompose";

function Moments(props) {
  const handlePlay = (e) => {
    props.onSetMomentPlay(props.moment.videoTime);
    console.log(props.moment);
  };
  const { Meta } = Card;
  return (
    <Card
      style={{ width: 300, marginBottom: 20 }}
      cover={<img alt="example" src={props.imgSRC} />}
      actions={[
        <Button onClick={handlePlay}>
          Jump to Moment
          <PlayCircleOutlined

          // key={`play${i}`}
          />
        </Button>,
      ]}
    >
      <Meta
        // avatar={
        //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        // }
        title={`${props.moment.action} - Round ${props.moment.round}`}
        description={`Starts at ${props.moment.roundTime}`}
      />
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onSetMomentPlay: (data) =>
    dispatch({ type: "CURRENT_MOMENT_PLAY_SET", data }),
});
export default compose(
  withFirebase,
  connect(null, mapDispatchToProps)
)(Moments);
