const INITIAL_STATE = {
  fights: null,
  limit: 6,
};

const applySetFights = (state, action) => ({
  ...state,
  fights: action.fights,
});

const applySetFightsLimit = (state, action) => ({
  ...state,
  limit: action.limit,
});
// const applySetFight = (state, action) => ({
//   ...state,
//   currentFightID: action.uid,
// });

function fightReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FIGHTS_SET": {
      return applySetFights(state, action);
    }
    case "FIGHTS_LIMIT_SET": {
      return applySetFightsLimit(state, action);
    }
    // case "FIGHT_SET": {
    //   return applySetFight(state, action);
    // }
    default:
      return state;
  }
}

export default fightReducer;
