const INITIAL_STATE = {};

const applySetCurrentFilmStudy = (state, action) => ({
  ...state,
  currentFilmStudy: action.data,
});

function currentFilmStudyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CURRENT_FILM_STUDY_SET": {
      return applySetCurrentFilmStudy(state, action);
    }

    default:
      return state;
  }
}
export default currentFilmStudyReducer;
