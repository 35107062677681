import React, { useState, useEffect } from "react";
import { Row, Col, Button, Empty } from "antd";
import {
  PlayCircleTwoTone,
  CaretLeftFilled,
  CaretRightFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { compose } from "recompose";
import BodyFront from "../../assets/img/body/body-front.png";
import BodySideLeft from "../../assets/img/body/body-side-left.png";
import BodySideRight from "../../assets/img/body/body-side-right.png";
import BodyAngleRight from "../../assets/img/body/body-angle-right.png";
import BodyAngleLeft from "../../assets/img/body/body-angle-left.png";
import BodyBack from "../../assets/img/body/body-back.png";

import CenterRingLogo from "../../assets/img/ROUNDSCORE.png";
import {
  Ropes,
  middleRing,
  ringLocationCSS,
  bodyLocationCSS,
} from "../../constants/lists";

function FilmSearchResult(props) {
  const [currentEventID, setCurrentEventID] = useState("");
  const [allFightEvents, setAllFightEvents] = useState([]);
  const [currentEventLimit, setCurrentEventLimit] = useState(10);
  const [unSortedFightEvents, setUnSortedFightEvents] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showRing, setShowRing] = useState(false);
  const [hoverBodyLink, setHoverBodyLink] = useState("");
  const [currentBodyChoice, setCurrentBodyChoice] = useState("");
  const [hoverRingLink, setHoverRingLink] = useState(false);
  const [comboEventIndex, setComboEventIndex] = useState(0);
  const [comboEventStartIndex, setComboEventStartIndex] = useState(0);
  const [buttonHover, setButtonHover] = useState(false);
  const [currentComboHover, setCurrentComboHover] = useState("");

  useEffect(() => {
    setUnSortedFightEvents(props.searchQueryResult);
    props.onSetFilmEventCount(1);
    props.onSetComboEventCount(1);
    props.onSetFilmStudyCurrentView();
  }, []);

  useEffect(() => {
    if (unSortedFightEvents[1] === "Single Punch") {
      setAllFightEvents(
        unSortedFightEvents[0].sort((a, b) =>
          a.videoTime > b.videoTime ? 1 : b.videoTime > a.videoTime ? -1 : 0
        )
      );
    }
    if (unSortedFightEvents[1] === "Counter") {
      setAllFightEvents(unSortedFightEvents[0]);
    }

    if (
      unSortedFightEvents[1] === "2 Punch Combo" ||
      unSortedFightEvents[1] === "3 Punch Combo" ||
      unSortedFightEvents[1] === "4 Punch Combo" ||
      unSortedFightEvents[1] === "5+ Punch Combo"
    ) {
      setAllFightEvents(unSortedFightEvents[0]);
    }
  }, [unSortedFightEvents]);

  useEffect(() => {
    setComboEventStartIndex(0);
  }, [currentEventID]);

  useEffect(() => {
    console.log(allFightEvents);
  }, [allFightEvents]);
  useEffect(() => {
    console.log(comboEventIndex, comboEventStartIndex);
  }, [comboEventIndex]);

  return (
    <Col span="24" style={{ width: "100%" }}>
      {allFightEvents.length > 0 && (
        <h2 style={{ textAlign: "center" }}>
          Fight Events ({allFightEvents.length})
        </h2>
      )}
      <Row>
        {allFightEvents === undefined ||
          (allFightEvents.length == 0 && (
            <Col span="24" style={{ marginTop: 100 + "px" }}>
              <Empty description={<span>No Fight Data Found</span>}>
                {" "}
                <Button
                  onClick={() => {
                    props.onSetFilmStudyCurrentView("search");
                  }}
                >
                  Go Back to Search
                </Button>
              </Empty>
            </Col>
          ))}
        {unSortedFightEvents[1] === "Single Punch" ||
        unSortedFightEvents[1] === "Counter"
          ? allFightEvents
              .filter((item, index) => index < currentEventLimit)
              .map((event) => (
                <Col span="24" key={event.key}>
                  <Row style={{ padding: "10px" }}>
                    <Col
                      span="24"
                      style={{
                        backgroundColor: "#262626",
                        marginBottom: "10px",
                        padding: "10px 0px 0px",
                      }}
                    >
                      {/* Header */}
                      <Row
                        justify="space-between"
                        align="center"
                        style={{ padding: "10px" }}
                      >
                        <Col
                          onClick={() => {
                            if (currentEventID === event.key) {
                              setCurrentEventID("");
                            } else {
                              setCurrentEventID(event.key);
                            }
                          }}
                          span="18"
                        >
                          {event.fighter ===
                          props.currentFilmStudy?.fightNameAUID
                            ? props.currentFilmStudy?.fighterA
                            : props.currentFilmStudy?.fighterB}
                          , Rd. {event.round} -{" "}
                          {unSortedFightEvents[1] === "Counter"
                            ? `${event.punch} (Counter)`
                            : `${event.punch}`}
                        </Col>
                        <Col span="4">
                          {" "}
                          {event.videoTime - 0.25 <= props.currentTime &&
                            event.videoTime + 0.3 >= props.currentTime && (
                              <span
                                style={{
                                  textAlign: "center",
                                  color: "#ad8b00",
                                }}
                              >
                                NOW
                              </span>
                            )}
                        </Col>
                        <Col span="2">
                          <PlayCircleTwoTone
                            twoToneColor={
                              currentEventID === event.key ||
                              event.key === buttonHover
                                ? "#ad8b00"
                                : "#1d39c4"
                            }
                            style={{ fontSize: "24px" }}
                            onMouseEnter={() => {
                              setButtonHover(event.key);
                            }}
                            onMouseLeave={() => {
                              setButtonHover("");
                            }}
                            onClick={() => {
                              setCurrentEventID(event.key);
                              props.onSetFilmStudyMoment(event.videoTime);
                              props.onSetFilmEventCount(
                                props.filmEventCount + 1
                              );
                            }}
                          />{" "}
                        </Col>
                      </Row>
                      {/* Body */}
                      <Row justify="space-between" align="center">
                        <Col
                          style={{
                            border: "1px solid #262626",
                            minHeight: "100%",
                            display:
                              currentEventID === event.key ? "block" : "none",

                            backgroundColor: "#141414",
                          }}
                        >
                          <Row
                            justify="space-between"
                            style={{ padding: "10px" }}
                            gutter={[16, 8]}
                          >
                            {/* <Col span="24">
                          {" "}
                          <h3>Information</h3>
                        </Col> */}

                            <Col span="12">
                              <h5>Round</h5>
                              {event.round}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Time</h5>
                              {event.roundTime}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Hand</h5> {event.hand}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Punch</h5> {event.punch}{" "}
                            </Col>
                            {
                              <Col
                                span="12"
                                style={{ opacity: props.Demo && 0.2 }}
                              >
                                <h5>Status</h5> {!props.Demo && event.status}{" "}
                              </Col>
                            }

                            <Col span="12">
                              {<h5>Stance</h5>} {event.stance}{" "}
                            </Col>

                            <Col
                              span="12"
                              style={{ opacity: props.Demo && 0.2 }}
                            >
                              <h5>Range</h5> {!props.Demo && event.range}{" "}
                            </Col>
                            <Col
                              span="12"
                              style={{ opacity: props.Demo && 0.2 }}
                            >
                              {/* <h5>Defense</h5> {event.defense && event.defense.length > 1 ? event.defense.join(", "): event.defense}{" "} */}
                              <h5>Defense</h5>
                              {!props.Demo &&
                                event.defense &&
                                event.defense.filter((e) => e).join(", ")}{" "}
                            </Col>
                            <Col
                              span="24"
                              style={{ opacity: props.Demo && 0.2 }}
                            >
                              {/* <h5>Body Part</h5> {event.body.length > 1 ? event.body.join(", ") : event.body} */}
                              {!props.Demo &&
                                event.body &&
                                event.body.length > 0 && (
                                  <h5 style={{ textAlign: "center" }}>
                                    Body Part
                                  </h5>
                                )}
                              <p style={{ textAlign: "center" }}>
                                {/* {event.body &&
                              event.body.filter((e) => e).join(", ")} */}
                                {!props.Demo &&
                                  event.body &&
                                  event.body
                                    .filter((e) => e)
                                    .map((e, i) => (
                                      <span
                                        onClick={() => {
                                          if (currentBodyChoice === e) {
                                            setCurrentBodyChoice("");
                                            setShowImage(!showImage);
                                          } else {
                                            setCurrentBodyChoice(e);
                                            setShowImage(true);
                                          }
                                        }}
                                        onMouseEnter={() => {
                                          setHoverBodyLink(i);
                                        }}
                                        onMouseLeave={() => {
                                          setHoverBodyLink("");
                                        }}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                          marginRight: "2px",
                                          // color: hoverBodyLink === i && "#ad8b00",
                                          color:
                                            currentBodyChoice === e ||
                                            hoverBodyLink === i
                                              ? "#ad8b00"
                                              : null,
                                        }}
                                      >
                                        {e}{" "}
                                      </span>
                                    ))}
                              </p>
                              {/* <p
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            
                          >
                            {showImage ? `Hide` : `Show`} Body Image
                          </p> */}
                              {!props.Demo && showImage && (
                                <div
                                  style={{
                                    maxWidth: "300px",
                                    maxHeight: "300px ",
                                    margin: "0 auto",
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    class="blob red"
                                    style={{
                                      top: bodyLocationCSS[currentBodyChoice]
                                        ?.top,
                                      left: bodyLocationCSS[currentBodyChoice]
                                        ?.left,
                                    }}
                                  ></div>
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "right" && (
                                    <img
                                      src={BodySideRight}
                                      alt=""
                                      style={{
                                        maxWidth: "44.85px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}{" "}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "front" && (
                                    <img
                                      src={BodyFront}
                                      alt=""
                                      style={{
                                        maxWidth: "129.67px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "back" && (
                                    <img
                                      src={BodyBack}
                                      alt=""
                                      style={{
                                        maxWidth: "129.67px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "left" && (
                                    <img
                                      src={BodySideLeft}
                                      alt=""
                                      style={{
                                        maxWidth: "44.85px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "left-angle" && (
                                    <img
                                      src={BodyAngleLeft}
                                      alt=""
                                      style={{
                                        maxWidth: "111.02px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "right-angle" && (
                                    <img
                                      src={BodyAngleRight}
                                      alt=""
                                      style={{
                                        maxWidth: "111.02px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col
                              span="24"
                              style={{ opacity: props.Demo && 0.2 }}
                            >
                              {event.ring?.length > 0 && (
                                <h5 style={{ textAlign: "center" }}>
                                  Ring Location
                                </h5>
                              )}
                              <p
                                style={{
                                  textAlign: "center",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color:
                                    showRing || hoverRingLink
                                      ? "#ad8b00"
                                      : null,
                                }}
                                onMouseEnter={() => {
                                  setHoverRingLink(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverRingLink(false);
                                }}
                                onClick={() => {
                                  setShowRing(!showRing);
                                }}
                              >
                                {!props.Demo &&
                                  middleRing.includes(event.ring) &&
                                  `Middle of Ring : `}{" "}
                                {!props.Demo &&
                                  Ropes.includes(event.ring) &&
                                  `Ropes : `}{" "}
                                {!props.Demo && event.ring}{" "}
                              </p>
                              {/* <p
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              setShowRing(!showRing);
                            }}
                          >
                            {showRing ? `Hide` : `Show`} Ring Image
                          </p> */}
                              {!props.Demo && showRing && (
                                <div
                                  style={{
                                    maxWidth: "300px",
                                    margin: "-70px auto 0",
                                  }}
                                >
                                  <div className="blue--corner"></div>
                                  <div className="red--corner"></div>
                                  <img
                                    src={CenterRingLogo}
                                    alt=""
                                    className="centerRingLogo"
                                  />

                                  <div className="content--container">
                                    <div class="innerRing"></div>
                                    <div
                                      class="blob green"
                                      style={{
                                        top: ringLocationCSS[event.ring]?.top,
                                        left: ringLocationCSS[event.ring]?.left,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))
          : allFightEvents
              .filter((item, index) => index < currentEventLimit)
              .map((event, itemIndex) => (
                <Col span="24" key={itemIndex}>
                  {" "}
                  <Row justify="center">
                    <Col
                      span="24"
                      style={{
                        backgroundColor: "#262626",
                        margin: "0 auto 10px",
                        padding: "10px 0px 10px",
                      }}
                    >
                      {" "}
                      <Row
                        justify="space-between"
                        align="center"
                        style={{ padding: "10px" }}
                      >
                        <Col
                          onClick={() => {
                            if (currentEventID === itemIndex) {
                              setCurrentEventID("");
                            } else {
                              setCurrentEventID(itemIndex);
                              setComboEventStartIndex(0);
                              setComboEventIndex(0);
                            }
                          }}
                          span="18"
                        >
                          {event[0].fighter ===
                          props.currentFilmStudy?.fightNameAUID
                            ? props.currentFilmStudy?.fighterA
                            : props.currentFilmStudy?.fighterB}
                          , Rd. {event[0].round} , {event.length} punch combo
                        </Col>
                        <Col span="4">
                          {" "}
                          {event[comboEventIndex]?.videoTime - 0.25 <=
                            props.currentTime &&
                            event[comboEventIndex]?.videoTime + 0.3 >=
                              props.currentTime && (
                              <span
                                style={{
                                  textAlign: "center",
                                  color: "#ad8b00",
                                }}
                              >
                                NOW
                              </span>
                            )}
                        </Col>
                        <Col span="2">
                          <PlayCircleTwoTone
                            twoToneColor={
                              currentComboHover === itemIndex &&
                              event.key === buttonHover
                                ? "#ad8b00"
                                : "#1d39c4"
                            }
                            style={{ fontSize: "24px" }}
                            onMouseEnter={() => {
                              setButtonHover(event.key);
                              setCurrentComboHover(itemIndex);
                            }}
                            onMouseLeave={() => {
                              setButtonHover("");
                              setCurrentComboHover("");
                            }}
                            onClick={() => {
                              setCurrentEventID(itemIndex);
                              props.onSetComboStudyMoment([
                                event[0]?.videoTime,
                                event[event.length - 1]?.videoTime,
                              ]);
                              props.onSetComboEventCount(
                                props.comboEventCount + 1
                              );
                            }}
                          />{" "}
                        </Col>
                      </Row>
                      <Row
                        justify={event.length > 4 ? "space-between" : "center"}
                        align="center"
                        style={{ padding: "10px" }}
                      >
                        {event.length > 4 && (
                          <Col span="4">
                            <Row justify="center" align="middle">
                              <Col>
                                <Button
                                  onClick={() => {
                                    if (currentEventID !== itemIndex) {
                                      setComboEventIndex(0);
                                      setCurrentEventID(itemIndex);
                                    } else {
                                      setComboEventIndex(comboEventIndex - 1);
                                    }
                                    if (
                                      comboEventIndex % 3 === 0 &&
                                      comboEventIndex !== 0
                                    ) {
                                      setComboEventStartIndex(
                                        comboEventIndex - 3
                                      );
                                    }
                                  }}
                                  disabled={
                                    comboEventIndex === 0 &&
                                    currentEventID === itemIndex
                                  }
                                >
                                  <CaretLeftFilled />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col span="16">
                          <Row justify="start" align="middle">
                            {currentEventID === itemIndex
                              ? event
                                  .slice(
                                    comboEventStartIndex,
                                    comboEventStartIndex + 4
                                  )
                                  .map((singleEvent, singleEventIndex) => (
                                    <Col
                                      span="4"
                                      style={{
                                        padding: "2px 5px",
                                        pointer: "cursor",
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <Row justify="center" align="middle">
                                        <Col>
                                          <span
                                            onClick={() => {
                                              setComboEventIndex(
                                                event.indexOf(singleEvent)
                                              );
                                              props.onSetFilmStudyMoment(
                                                event[comboEventIndex]
                                                  ?.videoTime
                                              );
                                              props.onSetFilmEventCount(
                                                props.filmEventCount + 1
                                              );
                                            }}
                                            style={{
                                              textAlign: "left",
                                              color:
                                                event[comboEventIndex].key ===
                                                  singleEvent.key && "#ad8b00",
                                              textDecoration:
                                                event[comboEventIndex].key ===
                                                  singleEvent.key &&
                                                "underline",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {singleEvent.punch}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))
                              : event
                                  .slice(0, 4)
                                  .map((singleEvent, singleEventIndex) => (
                                    <Col
                                      span="4"
                                      style={{
                                        padding: "2px 5px",
                                        pointer: "cursor",
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <Row justify="center" align="middle">
                                        <Col>
                                          <span
                                            onClick={() => {
                                              if (
                                                currentEventID !== itemIndex
                                              ) {
                                                setCurrentEventID(itemIndex);
                                              }
                                              setComboEventIndex(
                                                event.indexOf(singleEvent)
                                              );
                                              props.onSetFilmStudyMoment(
                                                event[comboEventIndex]
                                                  ?.videoTime
                                              );
                                              props.onSetFilmEventCount(
                                                props.filmEventCount + 1
                                              );
                                            }}
                                            style={{
                                              textAlign: "left",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {singleEvent.punch}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                          </Row>
                        </Col>
                        {event.length > 4 && (
                          <Col span="4">
                            <Row justify="center" align="middle">
                              <Col>
                                <Button
                                  disabled={
                                    comboEventIndex === event.length - 1 &&
                                    currentEventID === itemIndex
                                  }
                                  onClick={() => {
                                    if (currentEventID !== itemIndex) {
                                      setComboEventIndex(0);
                                      setCurrentEventID(itemIndex);
                                    } else {
                                      setComboEventIndex(comboEventIndex + 1);
                                    }
                                    // setComboEventStartIndex(comboEventStartIndex + 1)
                                    if (
                                      comboEventIndex % 3 === 0 &&
                                      comboEventIndex !== 0
                                    ) {
                                      setComboEventStartIndex(comboEventIndex);
                                    }
                                  }}
                                >
                                  <CaretRightFilled />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                      <Row justify="space-between" align="center">
                        <Col
                          style={{
                            border: "1px solid #262626",
                            minHeight: "100%",
                            display:
                              currentEventID === itemIndex ? "block" : "none",

                            backgroundColor: "#141414",
                          }}
                        >
                          <Row
                            justify="space-between"
                            style={{ padding: "10px" }}
                            gutter={[16, 8]}
                          >
                            {/* <Col span="24">
                          {" "}
                          <h3>Information</h3>
                        </Col> */}

                            <Col span="12">
                              <h5>Round</h5>
                              {event[comboEventIndex]?.round}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Time</h5>
                              {event[comboEventIndex]?.roundTime}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Hand</h5> {event[comboEventIndex]?.hand}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Punch</h5> {event[comboEventIndex]?.punch}{" "}
                            </Col>
                            <Col span="12">
                              <h5>Status</h5> {event[comboEventIndex]?.status}{" "}
                            </Col>

                            <Col span="12">
                              {<h5>Stance</h5>} {event[comboEventIndex]?.stance}{" "}
                            </Col>

                            <Col span="12">
                              <h5>Range</h5> {event[comboEventIndex]?.range}{" "}
                            </Col>
                            <Col span="12">
                              {/* <h5>Defense</h5> {event.defense && event.defense.length > 1 ? event.defense.join(", "): event.defense}{" "} */}
                              <h5>Defense</h5>
                              {event[comboEventIndex]?.defense &&
                                event[comboEventIndex]?.defense
                                  .filter((e) => e)
                                  .join(", ")}{" "}
                            </Col>
                            <Col span="24">
                              {/* <h5>Body Part</h5> {event.body.length > 1 ? event.body.join(", ") : event.body} */}
                              {event[comboEventIndex]?.body &&
                                event[comboEventIndex]?.body.length > 0 && (
                                  <h5 style={{ textAlign: "center" }}>
                                    Body Part
                                  </h5>
                                )}
                              <p style={{ textAlign: "center" }}>
                                {/* {event.body &&
                              event.body.filter((e) => e).join(", ")} */}
                                {event[comboEventIndex]?.body &&
                                  event[comboEventIndex]?.body
                                    .filter((e) => e)
                                    .map((e, i) => (
                                      <span
                                        onClick={() => {
                                          if (currentBodyChoice === e) {
                                            setCurrentBodyChoice("");
                                            setShowImage(!showImage);
                                          } else {
                                            setCurrentBodyChoice(e);
                                            setShowImage(true);
                                          }
                                        }}
                                        onMouseEnter={() => {
                                          setHoverBodyLink(i);
                                        }}
                                        onMouseLeave={() => {
                                          setHoverBodyLink("");
                                        }}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                          marginRight: "2px",
                                          // color: hoverBodyLink === i && "#ad8b00",
                                          color:
                                            currentBodyChoice === e ||
                                            hoverBodyLink === i
                                              ? "#ad8b00"
                                              : null,
                                        }}
                                      >
                                        {e}{" "}
                                      </span>
                                    ))}
                              </p>
                              {/* <p
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            
                          >
                            {showImage ? `Hide` : `Show`} Body Image
                          </p> */}
                              {showImage && (
                                <div
                                  style={{
                                    maxWidth: "300px",
                                    maxHeight: "300px ",
                                    margin: "0 auto",
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    class="blob red"
                                    style={{
                                      top: bodyLocationCSS[currentBodyChoice]
                                        ?.top,
                                      left: bodyLocationCSS[currentBodyChoice]
                                        ?.left,
                                    }}
                                  ></div>
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "right" && (
                                    <img
                                      src={BodySideRight}
                                      alt=""
                                      style={{
                                        maxWidth: "44.85px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}{" "}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "front" && (
                                    <img
                                      src={BodyFront}
                                      alt=""
                                      style={{
                                        maxWidth: "129.67px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "left" && (
                                    <img
                                      src={BodySideLeft}
                                      alt=""
                                      style={{
                                        maxWidth: "44.85px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "left-angle" && (
                                    <img
                                      src={BodyAngleLeft}
                                      alt=""
                                      style={{
                                        maxWidth: "111.02px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                  {bodyLocationCSS[currentBodyChoice]?.img ===
                                    "right-angle" && (
                                    <img
                                      src={BodyAngleRight}
                                      alt=""
                                      style={{
                                        maxWidth: "111.02px",
                                        textAlign: "center",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col span="24">
                              {event[comboEventIndex]?.ring?.length > 0 && (
                                <h5 style={{ textAlign: "center" }}>Ring</h5>
                              )}
                              <p
                                style={{
                                  textAlign: "center",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color:
                                    showRing || hoverRingLink
                                      ? "#ad8b00"
                                      : null,
                                }}
                                onMouseEnter={() => {
                                  setHoverRingLink(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverRingLink(false);
                                }}
                                onClick={() => {
                                  setShowRing(!showRing);
                                }}
                              >
                                {middleRing.includes(
                                  event[comboEventIndex]?.ring
                                ) && `Middle of Ring : `}{" "}
                                {Ropes.includes(event[comboEventIndex]?.ring) &&
                                  `Ropes : `}{" "}
                                {event[comboEventIndex]?.ring}{" "}
                              </p>
                              {/* <p
                            style={{
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              setShowRing(!showRing);
                            }}
                          >
                            {showRing ? `Hide` : `Show`} Ring Image
                          </p> */}
                              {showRing && (
                                <div
                                  style={{
                                    maxWidth: "300px",
                                    margin: "-70px auto 0",
                                  }}
                                >
                                  <div className="blue--corner"></div>
                                  <div className="red--corner"></div>
                                  <img
                                    src={CenterRingLogo}
                                    alt=""
                                    className="centerRingLogo"
                                  />

                                  <div className="content--container">
                                    <div class="innerRing"></div>
                                    <div
                                      class="blob green"
                                      style={{
                                        top: ringLocationCSS[
                                          event[comboEventIndex].ring
                                        ]?.top,
                                        left: ringLocationCSS[
                                          event[comboEventIndex].ring
                                        ]?.left,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
      </Row>
      {allFightEvents.length > 0 && (
        <Row justify="space-between" style={{ margin: "0 auto" }}>
          <Col>
            <Button
              onClick={() => {
                setCurrentEventLimit(currentEventLimit + 10, () => {
                  console.log(currentEventLimit);
                });
              }}
            >
              Load More
            </Button>
          </Col>

          <Col>
            <Button
              onClick={() => {
                setCurrentEventLimit(currentEventLimit - 10);
              }}
            >
              Load Less
            </Button>
          </Col>
        </Row>
      )}
    </Col>
  );
}
const mapDispatchToProps = (dispatch) => ({
  onSetFilmStudyMoment: (data) =>
    dispatch({ type: "FILM_STUDY_MOMENT_SET", data }),
  onSetFilmEventCount: (data) =>
    dispatch({ type: "FILM_EVENT_COUNT_SET", data }),
  onSetFilmStudyCurrentView: (data) =>
    dispatch({ type: "FILM_STUDY_CURRENT_VIEW_SET", data }),
  onSetComboStudyMoment: (data) =>
    dispatch({ type: "COMBO_STUDY_MOMENT_SET", data }),
  onSetComboEventCount: (data) =>
    dispatch({ type: "COMBO_EVENT_COUNT_SET", data }),
  onSetCurrentSearchEventID: (data) =>
    dispatch({ type: "CURRENT_SEARCH_EVENT_ID_SET", data }),
});
const mapStateToProps = (state) => ({
  currentFilmStudy: state.currentFilmStudy.currentFilmStudy,
  fighterOneData: state.fighterOneData.fighterOneData,
  fighterTwoData: state.fighterTwoData.fighterTwoData,
  searchQueryResult: state.searchQueryResult.searchQueryResult,
  filmEventCount: state.filmEventCount.filmEventCount,
  currentTime: state.currentTimeState.currentTime,
  comboEventCount: state.comboEventCount.comboEventCount,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FilmSearchResult
);
