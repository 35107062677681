const columnData = {
  Moment: [
    {
      title: "Action",
      dataIndex: "moment",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => a.videoTime - b.videoTime,
    },
  ],
  Misc: [
    {
      title: "Action",
      dataIndex: "misc",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => a.videoTime - b.videoTime,
    },
  ],
  Offense: [
    {
      title: "Action",
      dataIndex: "punch",
    },
    {
      title: "Hand",
      dataIndex: "hand",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Range",
      dataIndex: "range",
    },
    {
      title: "Level",
      dataIndex: "level",
    },
    {
      title: "Stance",
      dataIndex: "stance",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => Math.round(a.videoTime) - Math.round(b.videoTime),
    },
  ],
  Defense: [
    {
      title: "Action",
      dataIndex: "defense",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => a.videoTime - b.videoTime,
    },
  ],
  Body: [
    {
      title: "Body",
      dataIndex: "body",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => a.videoTime - b.videoTime,
    },
  ],
  Ring: [
    {
      title: "Ring",
      dataIndex: "ring",
    },
    {
      title: "RingX",
      dataIndex: "ringX",
    },
    {
      title: "RingY",
      dataIndex: "ringY",
    },
    {
      title: "Round",
      dataIndex: "round",
      sorter: (a, b) => a.round - b.round,
    },
    {
      title: "Round Time",
      dataIndex: "roundTime",
      sorter: (a, b) => a.roundTime - b.roundTime,
    },
    {
      title: "Video Time",
      dataIndex: "videoTime",
      sorter: (a, b) => a.videoTime - b.videoTime,
    },
  ],
};

export default columnData;
