import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Logo from "../../assets/img/ROUNDSCORE@2x.png";
import useViewport from "../../useViewPort";
function Footer() {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);

  const { width } = useViewport();
  const mobileBreakpoint = 769;
  const tabletBreakpoint = 1025;

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [width]);

  return (
    <>
      {mobileView ? (
        <Row
          justify="center"
          style={{
            borderTop: "0.1px solid #fff",
            paddingBottom: "40px",
            paddingTop: "20px",
          }}
          align="top"
        >
          <Col span="20">
            <Row justify="center" style={{ marginBottom: 20 + "px" }}>
              <Col span="12">
                <Link to={ROUTES.LANDING}>
                  <img src={Logo} alt="" style={{ width: "100%" }} />
                </Link>
              </Col>
            </Row>

            <Row justify="center" style={{ marginBottom: 5 + "px" }}>
              <Col span="20" style={{ textAlign: "center" }}>
                <Link to={ROUTES.PRIVACY_POLICY}>
                  <a href="#">Privacy Policy</a>
                </Link>
              </Col>
            </Row>

            <Row justify="center" style={{ marginBottom: 10 + "px" }}>
              <Col span="20" style={{ textAlign: "center" }}>
                <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                  {" "}
                  <a href="#">Terms and conditions</a>
                </Link>
              </Col>
            </Row>
            <Row justify="center">
              <Col span="20">
                <p style={{ textAlign: "center" }}>
                  Copyright © 2021 Roundscore, Inc. All rights reserved.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row
          justify="space-between"
          style={{
            borderTop: "0.1px solid #fff",
            paddingBottom: "40px",
            paddingTop: "20px",
          }}
          align="top"
        >
          <Col span="8">
            <Link to={ROUTES.LANDING}>
              <img
                src={Logo}
                alt=""
                style={{ width: tabletView ? "50%" : "30%" }}
              />
            </Link>
            <p>Copyright © 2021 Roundscore, Inc. All rights reserved.</p>
          </Col>

          <Col span="8">
            <Row justify="end">
              <Col span="8">
                <Link to={ROUTES.PRIVACY_POLICY}>
                  <a href="#">Privacy Policy</a>
                </Link>
              </Col>
              <Col span="8">
                <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                  {" "}
                  <a href="#">Terms and conditions</a>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Footer;
