const INITIAL_STATE = {};

const applySetComboEventCount = (state, action) => ({
  ...state,
  comboEventCount: action.data,
});

function comboEventCountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "COMBO_EVENT_COUNT_SET": {
      return applySetComboEventCount(state, action);
    }

    default:
      return state;
  }
}
export default comboEventCountReducer;
