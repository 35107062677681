import React, { useState, useEffect } from "react";
const useOrientation = () => {
  const [screenOrientation, setScreenOrientation] = useState("portrait");

  useEffect(() => {
    const handleSetScreenOrientation = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setScreenOrientation({
          screenOrientation: "portrait",
        });
      }

      if (window.matchMedia("(orientation: landscape)").matches) {
        setScreenOrientation({
          screenOrientation: "landscape",
        });
      }
    };
    // window.addEventListener("orientationchange", handleSetScreenOrientation);
    window.addEventListener("load", handleSetScreenOrientation);
    window.addEventListener("resize", handleSetScreenOrientation);

    return () =>
      window.removeEventListener("resize", handleSetScreenOrientation);
    //   window.addEventListener("orientationchange", handleSetScreenOrientation);
  }, []);

  return { screenOrientation };
};
export default useOrientation;
