import React, { useState, useEffect } from "react";
import DemoLanding from "./DemoLanding";
import FormLanding from "./FormLanding";

const Landing = (props) => {
  const [landingMode, setLandingMode] = useState("");
  useEffect(() => {
    setLandingMode(process.env.REACT_APP_LANDING);
  }, []);
  useEffect(() => {
    console.log(landingMode);
  }, [landingMode]);

  return <>{landingMode === "Demo" ? <DemoLanding /> : <FormLanding />}</>;
};

export default Landing;
