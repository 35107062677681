const INITIAL_STATE = {};

const applySetFilmStudyCurrentView = (state, action) => ({
  ...state,
  filmStudyCurrentView: action.data,
});

function filmStudyCurrentViewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FILM_STUDY_CURRENT_VIEW_SET": {
      return applySetFilmStudyCurrentView(state, action);
    }

    default:
      return state;
  }
}
export default filmStudyCurrentViewReducer;
