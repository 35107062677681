import { combineReducers } from "redux";
import sessionReducer from "./session";
import userReducer from "./user";
import messageReducer from "./message";
import fightReducer from "./fight";
import currentFightReducer from "./currentFight";
import actionTable from "./actionReducers";
import currentTime from "./currentTme";
import userPagesReducers from "./userPages";
import roundScoreReducers from "./roundScore";
import viewPortReducer from "./viewPort";
import orientationReducer from "./orientation";
import currentFilmStudyReducer from "./currentFilmStudy";
import fighterOneDataReducer from "./fighterOneData";
import fighterTwoDataReducer from "./fighterTwoData";
import filmStudyMomentReducer from "./filmStudyMoment";
import searchQueryResultReducer from "./searchQueryResult";
import filmEventCountReducer from "./filmEventCounter";
import filmStudyCurrentViewReducer from "./filmStudyCurrentView";
import filmSearchQueryHistoryReducer from "./filmSearchQueryHistory";
import comboStudyMomentReducer from "./comboStudyMoment";
import comboEventCountReducer from "./comboEventCounter";
import frameForwardCountReducer from "./frameForwardCount";
import frameBackwardCountReducer from "./frameBackwardCount";

export const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  messageState: messageReducer,
  fightState: fightReducer,
  actionState: actionTable,
  currentFightReducer: currentFightReducer,
  currentTimeState: currentTime,
  currentUserPages: userPagesReducers,
  roundScore: roundScoreReducers,
  viewPortReducer: viewPortReducer,
  orientationReducer: orientationReducer,
  currentFilmStudy: currentFilmStudyReducer,
  fighterOneData: fighterOneDataReducer,
  fighterTwoData: fighterTwoDataReducer,
  filmStudyMoment: filmStudyMomentReducer,
  searchQueryResult: searchQueryResultReducer,
  filmEventCount: filmEventCountReducer,
  filmStudyCurrentView: filmStudyCurrentViewReducer,
  filmSearchQueryHistory: filmSearchQueryHistoryReducer,
  comboStudyMoment: comboStudyMomentReducer,
  comboEventCount: comboEventCountReducer,
  frameForwardCount: frameForwardCountReducer,
  frameBackwardCount: frameBackwardCountReducer,
});
