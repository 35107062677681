import React, { Component } from "react";
import { withoutAuthorization, withEmailVerification } from "../Session";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Input, Button, Form, Row, Col, message } from "antd";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = () => (
  <div>
    <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
      Forgot your Password?
    </h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
  // success: false,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({success:true})
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error,success } = this.state;

    const isInvalid = email === "";

    return (
      <>
      
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button disabled={isInvalid} htmlType="submit">
              Reset My Password
            </Button>
          </Col>
        </Row>

        {error && message.error(error.message)}
        {success && message.success("New password link has been sent to you email, check your spam if it does not appear in your inbox")} 
      </Form></>
    );
  }
}

const PasswordForgetLink = () => (
  <p style={{ textAlign: "center" }}>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);
const condition = (authUser) => !authUser;
export default withoutAuthorization(condition)(PasswordForgetPage);

const mapStateToProps = (state, props) => ({
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});

const PasswordForgetForm = compose(
  withFirebase,
  connect(mapStateToProps, null)
)(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };
