import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Col, Divider, Radio, Checkbox, Row, Button, Alert } from "antd";
import { FilmEventFinder } from "../../functions/filmEventFinder";
import useViewport from "../../useViewPort";
function FilmSearch(props) {
  const initialSearchValues = {
    fighter: props.currentFilmStudy?.fightNameAUID,
    qualifier: "Single Punch",
    round: [],
    hand: [],
    punch: [],
    status: [],
    body: [],
    stance: [],
    ring: [],
    range: [],
    defense: [],
  };

  const [searchValues, setSearchValues] = useState(initialSearchValues);

  const [mainBtnHover, setMainBtnHover] = useState(false);

  const checkboxChange = (checkboxQueryValue, queryName) => {
    console.log("checked = ", checkboxQueryValue, "queryName = ", queryName);
    setSearchValues((pastSearchValues) => ({
      ...pastSearchValues,
      [queryName]: checkboxQueryValue,
    }));
  };
  const clearAll = () => {
    console.log("Here");
    setSearchValues(initialSearchValues);
  };
  const allFilmStudyQuery = () => {
    if (searchValues.fighter === props.currentFilmStudy?.fightNameAUID) {
      props.onSetSearchQueryResult([
        FilmEventFinder(
          props.fighterOneData,
          searchValues,
          props.fighterTwoData,
          props.currentFilmStudy?.fightNameAUID,
          props.currentFilmStudy?.fightNameBUID
        ),
        searchValues.qualifier,
      ]);
    } else {
      props.onSetSearchQueryResult([
        FilmEventFinder(
          props.fighterTwoData,
          searchValues,
          props.fighterOneData,
          props.currentFilmStudy?.fightNameBUID,
          props.currentFilmStudy?.fightNameAUID
        ),
        searchValues.qualifier,
      ]);
    }

    props.onSetFilmStudyCurrentView("video");
    props.onSetFilmSearchQueryHistory(searchValues);
  };

  useEffect(() => {
    if (
      props.filmSearchQueryHistory &&
      Object.keys(props.filmSearchQueryHistory).length > 0
    ) {
      Object.entries(props.filmSearchQueryHistory).forEach((entry) => {
        const [key, value] = entry;

        if (key !== "fighter" && value.length > 0) {
          //   console.log(key, value);
          checkboxChange(value, key);
        }
      });
    }
  }, []);

  return (
    <Col span="24">
      <h2 style={{ textAlign: "center" }}>Search</h2>
      <Divider>Fighter</Divider>
      <Alert
        message={
          searchValues.fighter === props.currentFilmStudy?.fightNameAUID
            ? props.currentFilmStudy?.fighterA
            : props.currentFilmStudy?.fighterB
        }
        type="info"
        style={{ textAlign: "center", marginBottom: 10 + "px" }}
      />
      <Radio.Group
        value={searchValues.fighter}
        onChange={(queryValue) => {
          checkboxChange(queryValue.target.value, "fighter");
        }}
        style={{ width: "100%" }}
      >
        <Row>
          <Col span="12">
            <Radio value={props.currentFilmStudy?.fightNameAUID}>
              {props.currentFilmStudy?.fighterA}
            </Radio>
          </Col>
          <Col span="12">
            <Radio value={props.currentFilmStudy?.fightNameBUID}>
              {props.currentFilmStudy?.fighterB}
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Divider>Qualifier</Divider>
      <Alert
        message={searchValues.qualifier}
        type="info"
        style={{ marginBottom: 10 + "px", textAlign: "center" }}
      />
      <Radio.Group
        style={{ width: "100%" }}
        onChange={(queryValue) => {
          checkboxChange(queryValue.target.value, "qualifier");
        }}
        value={searchValues.qualifier}
      >
        <Row>
          <Col span={12}>
            <Radio value="Single Punch">Single Punch</Radio>
          </Col>
          <Col span={12}>
            <Radio value="Counter">Counter Punch</Radio>
          </Col>
          <Col span={12}>
            {" "}
            <Radio value="2 Punch Combo" disabled={props.Demo && true}>
              2 Punch Combo
            </Radio>
          </Col>
          <Col span={12}>
            <Radio value="3 Punch Combo" disabled={props.Demo && true}>
              3 Punch Combo
            </Radio>
          </Col>

          <Col span={12}>
            {" "}
            <Radio value="4 Punch Combo" disabled={props.Demo && true}>
              4 Punch Combo
            </Radio>
          </Col>
          <Col span={12}>
            <Radio value="5+ Punch Combo" disabled={props.Demo && true}>
              5+ Punch Combo
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Divider>Round</Divider>
      <Alert
        message={searchValues.round.join(", ")}
        type="info"
        style={{ marginBottom: 10 + "px" }}
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={(queryValue) => {
          checkboxChange(queryValue, "round");
        }}
        value={searchValues.round}
      >
        {props.Demo ? (
          <Row>
            <Col span={6}>
              <Checkbox value={1}>1</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={2}>2</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value={3}>3</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={4}>4</Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox value={5}>5</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={6}>6</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={7}>7</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={8}>8</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={9}>9</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={10}>10</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={11}>11</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={12}>12</Checkbox>
            </Col>
            <Col span={6}>
              {" "}
              <Checkbox value={13} disabled={props.Demo && true}>
                13
              </Checkbox>
            </Col>{" "}
            <Col span={6}>
              {" "}
              <Checkbox value={14} disabled={props.Demo && true}>
                14
              </Checkbox>
            </Col>{" "}
            <Col span={6}>
              {" "}
              <Checkbox value={15} disabled={props.Demo && true}>
                15
              </Checkbox>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Checkbox value={1}>1</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={2}>2</Checkbox>
            </Col>
            <Col>
              <Checkbox value={3}>3</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={4}>4</Checkbox>
            </Col>
            <Col>
              <Checkbox value={5}>5</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={6}>6</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={7}>7</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={8}>8</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={9}>9</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={10}>10</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={11}>11</Checkbox>
            </Col>
            <Col>
              {" "}
              <Checkbox value={12}>12</Checkbox>
            </Col>
          </Row>
        )}{" "}
      </Checkbox.Group>{" "}
      <Divider>Hand</Divider>
      <Alert
        message={searchValues.hand.join(", ")}
        type="info"
        style={{ marginBottom: 10 + "px" }}
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={(queryValue) => {
          checkboxChange(queryValue, "hand");
        }}
        value={searchValues.hand}
      >
        <Row>
          <Col span={12}>
            <Checkbox value="Leadhand">Leadhand</Checkbox>
          </Col>
          <Col span={12}>
            {" "}
            <Checkbox value="Rearhand">Rearhand</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Divider>Punches</Divider>
      <Alert
        message={searchValues.punch.join(", ")}
        type="info"
        style={{ marginBottom: 10 + "px" }}
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={(queryValue) => {
          checkboxChange(queryValue, "punch");
        }}
        value={searchValues.punch}
      >
        <Row>
          <Col span={8}>
            <Checkbox value="Jab">Jab</Checkbox>
          </Col>
          <Col span={8}>
            {" "}
            <Checkbox value="Cross">Cross</Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox value="Hook">Hook</Checkbox>
          </Col>
          <Col span={8}>
            {" "}
            <Checkbox value="Uppercut">Uppercut</Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox value="Overhand">Overhand</Checkbox>
          </Col>
          <Col span={8}>
            {" "}
            <Checkbox value="Others">Others</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      {!props.Demo && (
        <>
          <Divider>Status</Divider>
          <Alert
            message={searchValues.status.join(", ")}
            type="info"
            style={{ marginBottom: 10 + "px" }}
          />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(queryValue) => {
              checkboxChange(queryValue, "status");
            }}
            value={searchValues.status}
          >
            <Row>
              <Col span={8}>
                <Checkbox value="Land">Land</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Miss">Miss</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Partial Land">Partial Land</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Gloved Block">Gloved Block</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="PGB">Partial Gloved Block</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
          <Divider>Body Part</Divider>
          <Alert
            message={searchValues.body.join(", ")}
            type="info"
            style={{ marginBottom: 10 + "px" }}
          />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(queryValue) => {
              checkboxChange(queryValue, "body");
            }}
            value={searchValues.body}
          >
            <Row>
              <Col span={8}>
                <Checkbox value="Head">Head</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Body">Body</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>{" "}
          <Divider>Ring</Divider>
          <Alert
            message={searchValues.ring.join(", ")}
            type="info"
            style={{ marginBottom: 10 + "px" }}
          />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(queryValue) => {
              checkboxChange(queryValue, "ring");
            }}
            value={searchValues.ring}
          >
            <Row>
              <Col span={12}>
                <Checkbox value="Middle">Middle</Checkbox>
              </Col>
              <Col span={12}>
                {" "}
                <Checkbox value="Ropes">Ropes</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
          <Divider>Range</Divider>
          <Alert
            message={searchValues.range.join(", ")}
            type="info"
            style={{ marginBottom: 10 + "px" }}
          />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(queryValue) => {
              checkboxChange(queryValue, "range");
            }}
            value={searchValues.range}
          >
            <Row>
              <Col span={8}>
                <Checkbox value="Close">Close</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Mid">Mid</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Long">Long</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
          <Divider>Defense</Divider>
          <Alert
            message={searchValues.defense.join(", ")}
            type="info"
            style={{ marginBottom: 10 + "px" }}
          />
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(queryValue) => {
              checkboxChange(queryValue, "defense");
            }}
            value={searchValues.defense}
          >
            <Row>
              <Col span={8}>
                <Checkbox value="Block">Block</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Parry">Parry</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="Shoulder Roll">Shoulder Roll</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Slip">Slip</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="Clinching">Clinching</Checkbox>
              </Col>
              <Col span={8}>
                {" "}
                <Checkbox value="Others">Others</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </>
      )}
      <Divider>Stance</Divider>
      <Alert
        message={searchValues.stance.join(", ")}
        type="info"
        style={{ marginBottom: 10 + "px" }}
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={(queryValue) => {
          checkboxChange(queryValue, "stance");
        }}
        value={searchValues.stance}
      >
        <Row>
          <Col span={8}>
            <Checkbox value="Orthodox">Orthodox</Checkbox>
          </Col>
          <Col span={8}>
            {" "}
            <Checkbox value="Southpaw">Southpaw</Checkbox>
          </Col>
          <Col span={8}>
            {" "}
            <Checkbox value="Square">Square</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row
        justify="center"
        gutter={[16, 8]}
        style={{
          marginTop: "20px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Col>
          <Button
            onClick={allFilmStudyQuery}
            size="large"
            style={mainBtnHover ? style.mainButtonHover : style.button}
            onMouseEnter={() => {
              setMainBtnHover(true);
            }}
            onMouseLeave={() => {
              setMainBtnHover(false);
            }}
          >
            Search Film
          </Button>
        </Col>
        <Col>
          <a
            onClick={() => {
              clearAll();
            }}
            style={{ textDecoration: "underline" }}
          >
            Clear All
          </a>
        </Col>
      </Row>
    </Col>
  );
}
const style = {
  mainButtonHover: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  button: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
};
const mapDispatchToProps = (dispatch) => ({
  onSetSearchQueryResult: (data) =>
    dispatch({ type: "SEARCH_QUERY_RESULT_SET", data }),
  onSetFilmStudyCurrentView: (data) =>
    dispatch({ type: "FILM_STUDY_CURRENT_VIEW_SET", data }),
  onSetFilmSearchQueryHistory: (data) =>
    dispatch({ type: "FILM_SEARCH_QUERY_HISTORY_SET", data }),
});

const mapStateToProps = (state) => ({
  fighterOneData: state.fighterOneData.fighterOneData,
  fighterTwoData: state.fighterTwoData.fighterTwoData,
  filmSearchQueryHistory: state.filmSearchQueryHistory.filmSearchQueryHistory,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FilmSearch
);
