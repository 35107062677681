const INITIAL_STATE = {};

const applySetFilmSearchQueryHistory = (state, action) => ({
  ...state,
  filmSearchQueryHistory: action.data,
});

function filmSearchQueryHistoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FILM_SEARCH_QUERY_HISTORY_SET": {
      return applySetFilmSearchQueryHistory(state, action);
    }

    default:
      return state;
  }
}
export default filmSearchQueryHistoryReducer;
