const INITIAL_STATE = {};

const applySetViewPort = (state, action) => ({
  ...state,
  viewPort: action.data,
});

function viewPortReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "VIEWPORT_SET": {
      return applySetViewPort(state, action);
    }

    default:
      return state;
  }
}
export default viewPortReducer;
