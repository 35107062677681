import React, { useState, useEffect } from "react";
import * as ROLES from "../../../constants/roles";

import { Col } from "antd";
import ButtonClicked from "../ButtonClicked";

import { connect } from "react-redux";
import { punch } from "../../actions";

const PunchList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    props.punch(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentPunch) {
      setClicked(true);
      setSelectedButton(props.currentPunch);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);
  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.punch,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  return (
    <>
      <Col span={8}>
        <ButtonClicked
          value="Jab"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      {!!props.authUser.roles[ROLES.ADMIN] && (
        <>
          <Col span={8}>
            <ButtonClicked
              value="Up Jab"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Pawing Jab"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Stiff Jab"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Misdirection"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Measuring"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
        </>
      )}
      <Col span={8}>
        <ButtonClicked
          value="Cross"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={8}>
        <ButtonClicked
          value="Hook"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={8}>
        <ButtonClicked
          value="Uppercut"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={8}>
        <ButtonClicked
          value="Overhand"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      {!!props.authUser.roles[ROLES.ADMIN] && (
        <>
          {" "}
          <Col span={8}>
            <ButtonClicked
              value="Feint"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Head Butt"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Under Hook"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={8}>
            <ButtonClicked
              value="Wild Swings"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
        </>
      )}
      <Col span={8}>
        <ButtonClicked
          value="UCP"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,

  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    punch: (data) => dispatch(punch(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PunchList);
