import React, { Component } from "react";
import { connect } from "react-redux";

import { Upload, Button, message, Col, Row } from "antd";
import { compose } from "recompose";
import * as ROLES from "../../constants/roles";
import { withAuthorization, withEmailVerification } from "../Session";
import { withFirebase } from "../Firebase";
import { UploadOutlined } from "@ant-design/icons";

class Uploader extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    fileList: [],
    uploading: false,
  };

  handleUpload = () => {
    const { fileList } = this.state;
    this.setState({
      uploading: true,
    });
    fileList.forEach((file) => {
      this.props.firebase.fightsImagesUpload(file).then(() => {
        this.setState({
          uploading: false,
        });

        this.props.firebase
          .fightsImages(file)
          .getDownloadURL()
          .then((URL) => this.props.onSetURL(URL));
      });
    });

    console.log(fileList);
    // You can use any AJAX library you like

    // reqwest({
    //   url: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    //   method: "post",
    //   processData: false,
    //   data: formData,
    //   success: () => {
    //     this.setState({
    //       fileList: [],
    //       uploading: false,
    //     });
    //     message.success("upload successfully.");
    //   },
    //   error: () => {
    //     this.setState({
    //       uploading: false,
    //     });
    //     message.error("upload failed.");
    //   },
    // });
  };
  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Row style={{ marginTop: 20 }}>
        <Col span={12}>
          <Upload {...props}>
            <Button>
              <UploadOutlined /> Select File
            </Button>
          </Upload>
        </Col>
        <Col span={12}>
          {" "}
          <Button
            type="primary"
            onClick={this.handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
        </Col>
      </Row>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const mapDispatchToProps = (dispatch) => ({
  onSetURL: (data) => dispatch({ type: "CURRENT_FIGHT_URL", data }),
});
export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  connect(null, mapDispatchToProps)
)(Uploader);
