export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_UP_DATA = "/signup-data";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const ADD_FIGHTER = "/add-fighter";
export const ADD_FIGHT = "/add-fight";
export const FIGHT_LIST = "/fight-list";
export const FIGHT_COLLECT = "/collection";
export const EDIT_FIGHT = "/edit-fight";
export const VIDEO_USER_TOOL = `/landing/:id`;
export const MY_FIGHT = "/my-fight";
export const MY_FIGHT_STUDY = "/film-study/:id";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const CONTACT_US = "/contact-us";
export const DEMO = "/demo";
export const PLAYLIST = "/playlist";
export const DATAVIZ = "/data-viz";
