import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withoutAuthorization, withEmailVerification } from "../Session";
import { Input, Button, Form, Row, Col, Alert, message } from "antd";
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {
  GoogleOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const SignInPage = () => (
  <div>
    <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
      Login
    </h1>
    <SignInForm /> <PasswordForgetLink />
    <SignInGoogle />
    {/* <SignInFacebook />
    <SignInTwitter /> */}
    <SignUpLink />
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((outcome) => {
        console.log(outcome.user.uid);
        this.props.firebase.user(outcome.user.uid).onSnapshot((snapshot) => {
          console.log(snapshot.data());
          if (Object.entries(snapshot.data().roles).length > 0) {
            if (
              snapshot.data().roles.DATALEVEL1 ||
              snapshot.data().roles.DATALEVEL2 ||
              snapshot.data().roles.ADMIN
            ) {
              this.props.history.push(ROUTES.FIGHT_LIST);
            } else {
              this.props.history.push(ROUTES.MY_FIGHT);
            }
          }
        });
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState(
      event.target.name === "email"
        ? { [event.target.name]: event.target.value.trim() }
        : { [event.target.name]: event.target.value }
    );
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <>
        <Form onFinish={this.onSubmit} name="basic">
          <Row justify="center" gutter={[16, 16]}>
            <Col span={this.props.width > 768 ? 8 : 18} align="center">
              {error && <Alert message={error.message} type="error" />}
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={this.props.width > 768 ? 8 : 18} align="center">
              <Input
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={this.props.width > 768 ? 8 : 18} align="center">
              <Input
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            {" "}
            <Col span={this.props.width > 768 ? 8 : 18} align="center">
              <Button disabled={isInvalid} htmlType="submit">
                Sign In
              </Button>{" "}
            </Col>
          </Row>{" "}
        </Form>
      </>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: {},
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.MY_FIGHT);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          {" "}
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button htmlType="submit">
              {" "}
              <GoogleOutlined />
              Sign In with Google
            </Button>
          </Col>
        </Row>

        {error && message.error(error.message)}
      </Form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.MY_FIGHT);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          {" "}
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button htmlType="submit">
              <FacebookOutlined />
              Sign In with Facebook
            </Button>
          </Col>
        </Row>
        {error && message.error(error.message)}
      </Form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          },
          { merge: true }
        );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.MY_FIGHT);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          {" "}
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button htmlType="submit">
              <TwitterOutlined />
              Sign In with Twitter
            </Button>
          </Col>
        </Row>
        {error && message.error(error.message)}
      </Form>
    );
  }
}

const condition = (authUser) => !authUser;

const mapStateToProps = (state, props) => ({
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});

const SignInForm = compose(
  withRouter,
  withFirebase,
  withoutAuthorization(condition),
  connect(mapStateToProps, null)
)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase);

const SignInTwitter = compose(withRouter, withFirebase)(SignInTwitterBase);

export default compose(withRouter)(SignInPage);

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
