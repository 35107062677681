import React, { Component } from "react";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Layout,
  Row,
  Col,
  Button,
  message,
  Menu,
  Drawer,
  Dropdown,
  Typography,
} from "antd";
import VideoTool from "./VideoTool";
import { connect } from "react-redux";
import { compose } from "recompose";
import { DownOutlined, MoreOutlined, NumberOutlined } from "@ant-design/icons";
import { withFirebase } from "../Firebase";
import Moments from "./Moments";
import ScoreCard from "./ScoreCards";
import TallyScore from "./TallyScore";
import ProgressBar from "./ProgressBar";
import ScoreCardButtonSave from "./ScoreCardButtonSave";
import MostRecentScore from "./MostRecentScore";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentScreen: "Scorecard",
      loading: false,
      currentFightID: this.props.match.params.id,
      fightInfo: {},
      moments: [],
      currentRound: 0,
      saveRoundScore: false,
      start: [],
      endStatus: false,
      scoredBefore: false,
      scoredBeforeFIREBASE: false,
      tally: 1,
      totalRounds: [],
      F1Scores: [],
      F2Scores: [],
      avgF1: 0,
      avgF2: 0,
      totalScores: 0,
      visible: false,
    };
  }
  componentDidMount() {
    if (!this.props.user) {
      this.setState({ loading: true });
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .fight(this.state.currentFightID)
      .onSnapshot((snapshot) => {
        this.setState({ fightInfo: snapshot.data() });

        this.setState({ loading: false }, () => {
          console.log(this.state.fightInfo.FinalScores);
        });
      });
    this.avgScore = this.props.firebase
      .avgScore(this.state.currentFightID)
      .get()
      .then((snapshot) => {
        let scoreReferences = [];
        if (snapshot) {
          snapshot.forEach((e) => {
            console.log(e.data());
            scoreReferences.push(e.data());
          });
        }
        return scoreReferences;
      })
      .then((scoreReferences) => {
        console.log(scoreReferences);
        var f1Scores = [];
        var f2Scores = [];
        var allScoresTemp = [];
        scoreReferences.forEach((scoreRef) => {
          this.props.firebase
            .refSearch(scoreRef.reference)
            .get()
            .then((snapshot) => {
              let matter = scoreRef.reference.match(/\/[^users\/](\S*?)\//g);
              if (snapshot.data()) {
                for (const [key, value] of Object.entries(snapshot.data())) {
                  f1Scores.push(snapshot.data()[key].currentTally.F1);
                  f2Scores.push(snapshot.data()[key].currentTally.F2);
                  allScoresTemp.push({
                    data: snapshot.data()[key],
                    userID: matter[0].substring(1, matter[0].length - 1),
                  });
                }
              }

              this.setState({ F1Scores: f1Scores, F2Scores: f2Scores }, () => {
                var f1total = 0;
                var f2total = 0;

                this.state.F1Scores.forEach((e, i) => {
                  f1total = f1total + e;
                });
                this.setState({
                  avgF1: (f1total / this.state.F1Scores.length).toFixed(1),
                });

                this.state.F2Scores.forEach((e, i) => {
                  f2total = f2total + e;
                });
                this.setState({
                  avgF2: (f2total / this.state.F2Scores.length).toFixed(1),
                });
                var i = 0;
                this.state.F1Scores.forEach((element) => {
                  i = i + element;
                });

                console.log(
                  i,
                  i / this.state.F1Scores.length,
                  this.state.F1Scores.length
                );
                console.log(
                  f1total,
                  f1total / this.state.F1Scores.length,
                  this.state.F1Scores.length
                );
              });
              console.log(allScoresTemp);
              this.setState({ allScores: allScoresTemp });
            });
        });
      });

    this.momentUnsub = this.props.firebase
      .moments(this.state.currentFightID)
      .orderBy("videoTime", "asc")
      .get()
      .then((snapshot) => {
        let momento = [];
        let start = [];
        snapshot.forEach((e) => {
          momento.push(e.data());
        });
        start.push(momento.filter((moment) => moment.action === "Start"));
        this.setState({ moments: momento, start: start });
      })
      .then(() => {
        var missingArray = [];

        console.log(this.state.start);
        for (let i = 0; i <= this.state.start[0].length; i++) {
          if (!this.props.currentTotal[`Round ${i}`]) {
            if (i > 0) {
              missingArray.push(i);
            }
          }
        }
        this.props.onSetMissingScores(missingArray);
      });
    this.scoredStatus = this.props.firebase
      .userScore(this.props.user, this.state.currentFightID)
      .onSnapshot((snapshot) => {
        // this.props.onSetUser(snapshot.data(), this.props.match.params.id);

        if (snapshot.data()) {
          var recent = 0;
          for (const [key, value] of Object.entries(snapshot.data())) {
            if (recent === 0) {
              recent = value.editedAt
                ? value
                : value.createdAt
                ? value
                : recent;
            } else {
              recent =
                (recent.editedAt
                  ? recent.editedAt.toDate()
                  : recent.createdAt
                  ? recent.createdAt.toDate()
                  : null) >
                (value.editedAt
                  ? value.editedAt.toDate()
                  : value.createdAt
                  ? value.createdAt.toDate()
                  : recent)
                  ? recent
                  : value.editedAt
                  ? value
                  : value.createdAt
                  ? value
                  : recent;
            }
          }
          this.setState({ recent: recent });
          this.setState({ scoredBefore: true, scoredBeforeFIREBASE: true });
        } else {
          this.setState({ scoredBefore: false, scoredBeforeFIREBASE: false });
        }
        this.setState({ loading: false });
      });

    // this.unsubscriber = this.props.firebase
    //   .user(this.props.match.params.id)
    //   .onSnapshot((snapshot) => {
    //     this.props.onSetUser(snapshot.data(), this.props.match.params.id);

    //     this.setState({ loading: false });
    //   });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.scoredStatus && this.scoredStatus();
    // this.momentUnsub && this.momentUnsub();
    // this.unsubscriber && this.unsubscriber();
  }

  handleClick = (e) => {
    console.log(this.props.match.params.id);
    this.setState({
      ...this.state.currentScreen,
      currentScreen: e.domEvent.target.innerHTML,
    });
    console.log(this.state.currentScreen);
  };

  handleSaveRound = (e) => {
    var fightRounds = this.state.moments.filter(
      (moment) => moment.action === "Start"
    );
    var counterMax = fightRounds.length + 1;
    var counter = fightRounds.length;

    if (e.target.innerHTML === "Submit Score") {
      var scoreTally = [];
      var finalTally = [];
      for (const [key, value] of Object.entries(this.props.currentTotal)) {
        let object = this.props.currentTotal[`${key}`];
        console.log(object, key);
        if (key === "Round 999") {
          scoreTally.push({ [`Round 12`]: this.props.currentTotal[`${key}`] });
        } else {
          scoreTally.push({ [`${key}`]: this.props.currentTotal[`${key}`] });
        }
      }
      console.log(scoreTally);
      for (let i = 0; i < counter; i++) {
        finalTally.push(scoreTally[i]);
      }
      console.log(this.props.user);
      const uuid = uuidv4();
      if (this.state.scoredBeforeFIREBASE === true) {
        this.props.firebase
          .userScore(this.props.user, this.state.currentFightID)
          .update({
            [uuid]: {
              finalTally: finalTally,
              currentTally: this.props.currentUserTally,
              editedAt: this.props.firebase.fieldValue.serverTimestamp(),
            },
          })
          .then(() => {
            message.success("New Fight Score was Submitted");
          });
      } else {
        this.props.firebase
          .userScore(this.props.user, this.state.currentFightID)
          .set({
            [uuid]: {
              finalTally: finalTally,
              currentTally: this.props.currentUserTally,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            },
          })
          .then((e) => {
            message.success("Fight Score was Submitted");
            console.log(e);
            this.props.firebase
              .finalScores(this.state.currentFightID, this.props.user)
              .set({
                reference: `/users/${this.props.user}/fightsScored/${this.state.currentFightID}`,
              });
            this.props.onSetScoreRound({
              currentRound: 2000,
            });
          });
      }
      // } else if (e.target.innerHTML === "Complete your Score card") {
      //   console.log(fightRounds.length);
      //   for (let i = 0; i < fightRounds.length; i++) {
      //     if (!this.props.currentTotal[`Round ${i}`]) {
      //       this.props.onSetScoreRound({
      //         currentRound: i,
      //       });
      //     }
      //   }
      // this.props.onSetScoreRound({
      //   currentRound:
      //     this.props.currentRound < fightRounds.length
      //       ? this.props.currentRound + 1
      //       : 1000,
      // });
    } else {
      if (
        this.props.currentScores &&
        this.props.currentScores.F1 > 9 &&
        this.props.currentScores.F2 > 9
      ) {
        message.error("Only One Fighter must be awarded a score of 10");
      } else if (
        this.props.currentScores &&
        this.state.tally <= fightRounds.length &&
        (this.props.currentScores.F1 > 9 || this.props.currentScores.F2 > 9)
      ) {
        var missing = 0;
        var missingArray = [];

        for (let i = 0; i <= this.state.start[0].length; i++) {
          if (!this.props.currentTotal[`Round ${i}`]) {
            if (i > 0) {
              missingArray.push(i);
            }
          }
        }
        this.props.onSetMissingScores(missingArray);

        if (this.props.currentRound === missingArray[0]) {
          this.props.onSetScoreRound({
            currentRound:
              this.props.currentRound < fightRounds.length
                ? missingArray[1]
                : 1000,
          });
        } else if (
          this.state.tally === fightRounds.length &&
          this.props.currentRound !== this.state.tally
        ) {
          this.props.onSetScoreRound({
            currentRound: fightRounds.length,
          });
        } else {
          this.props.onSetScoreRound({
            currentRound: missingArray[0],
          });
        }

        missingArray = [];
        for (let i = 0; i <= this.state.start[0].length; i++) {
          if (!this.props.currentTotal[`Round ${i}`]) {
            if (i > 0) {
              missingArray.push(i);
            }
          }
        }
        this.props.onSetMissingScores(missingArray);

        if (this.props.currentRound < fightRounds.length) {
          this.setState({
            endStatus: this.props.endStatus,
          });
        }
      } else {
        message.error("One Fighter must be awarded a score of 10");
      }
    }
  };

  onChange = (a, b, c) => {
    console.log(a, b, c);
  };

  changeScoredBefore = () => {
    this.setState({ scoredBefore: false }, () => {
      this.props.onSetEndStatus(false);
    });
    this.props.onSetScoreRound({});
  };

  scoreCardPlay = () => {
    var currentScoreCardPlay = this.state.moments.filter(
      (moment) => moment.round === this.props.currentRound
    );
    if (currentScoreCardPlay.length > 0) {
      this.props.onSetMomentPlay(currentScoreCardPlay[0].videoTime);
    }
    console.log(currentScoreCardPlay);
  };
  TallyChange = (tally) => {
    this.setState({ tally: tally });
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  render() {
    const { Content } = Layout;
    const { videoURL } = this.state.fightInfo;
    const { Title } = Typography;

    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#364d79",
    };
    const menu = (
      <Menu onClick={this.handleClick}>
        <Menu.Item>
          <a key="Moments">Moments</a>
        </Menu.Item>
        <Menu.Item>
          <a key="Scorecard">Scorecard</a>
        </Menu.Item>
        <Menu.Item>
          <a key="Scorecard">Most Recent</a>
        </Menu.Item>
      </Menu>
    );

    const settingsMenu = (
      <Menu>
        <Menu.Item>
          <a key="Moments">Save Score</a>
        </Menu.Item>
        <Menu.Item>
          <a key="Scorecard">Share Score</a>
        </Menu.Item>
      </Menu>
    );
    const scoreCardMenu = (
      <Menu onClick={this.scoreCardPlay}>
        {/* <Menu.Item>
          <a href="#" key="Moments">
            Save Score
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#" key="Scorecard">
            Share Score
          </a>
        </Menu.Item> */}
        {this.props.currentRound > 0 &&
        this.props.currentRound !== 1000 &&
        this.props.currentRound !== 2000 ? (
          <Menu.Item>
            <a key="Play">Play Round {this.props.currentRound}</a>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <Content
        span={24}
        style={{ paddingLeft: 5 + `%`, paddingRight: 5 + `%`, marginTop: 25 }}
      >
        <Row
          justify="space-between"
          className={
            this.props.width > 1250
              ? "scoreBoard"
              : this.props.width > 920
              ? "scoreBoardTablet"
              : this.props.width > 767
              ? "scoreBoardMobile"
              : null
          }
        >
          <Col span={20} className="fighterAName">
            <Row align="middle">
              <Col span={24}>
                {" "}
                {this.props.width > 768 ? (
                  <Title level={2} className="fighterANameText">
                    {this.state.fightInfo.fighterA}
                  </Title>
                ) : (
                  <p>{this.state.fightInfo.fighterA} </p>
                )}
              </Col>
            </Row>
          </Col>

          <Col span={4} className="fighterAScore">
            <Row justify="center">
              <Col span={20}>
                {" "}
                {this.props.width > 768 ? (
                  <Title level={2}>{this.state.avgF1}</Title>
                ) : (
                  <p>{this.state.avgF1} </p>
                )}
              </Col>
            </Row>
          </Col>

          <Col span={20} className="fighterBName">
            <Row>
              <Col span={24}>
                {" "}
                {this.props.width > 768 ? (
                  <Title level={2}>{this.state.fightInfo.fighterB}</Title>
                ) : (
                  <p>{this.state.fightInfo.fighterB} </p>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={4} className="fighterBScore">
            <Row justify="center">
              <Col span={20}>
                {" "}
                {this.props.width > 768 ? (
                  <Title level={2}>{this.state.avgF2}</Title>
                ) : (
                  <p>{this.state.avgF2} </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>{" "}
        <p className="votes">{this.state.F1Scores.length} votes</p>
        <Row
          justify="center"
          style={{
            marginBottom: 24 + `px`,
            maxHeight: 100 + `vh`,
          }}
        >
          <Col
            md={24}
            sm={24}
            xs={24}
            lg={16}
            style={{ height: this.props.width > 991 ? null : 300 }}
          >
            <VideoTool
              currentURL={videoURL}
              currentMoment={this.props.currentMoment}
            />
          </Col>
          {this.props.width > 991 ? (
            <Col
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{
                border: 1 + `px ` + `solid ` + `#434343`,
              }}
            >
              <Row
                justify="space-between"
                style={{
                  marginBottom: 2 + `vh`,
                  backgroundColor: "#1f1f1f",
                  width: 100 + `%`,
                  padding: 20,
                }}
                align="middle"
              >
                <Col span={8}>
                  <Dropdown overlay={menu}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {this.state.currentScreen}
                      <DownOutlined style={{ marginLeft: 5 }} />
                    </a>
                  </Dropdown>
                </Col>
                <Col span={1}>
                  <Dropdown
                    overlay={
                      this.state.currentScreen === "Scorecard"
                        ? scoreCardMenu
                        : settingsMenu
                    }
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <MoreOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
              <Row
                justify="center"
                style={{
                  overflowY: "scroll",
                  height: 400,
                  paddingLeft: 5 + `%`,
                  paddingRight: 5 + `%`,
                }}
              >
                {this.state.currentScreen === "Moments" ? (
                  this.state.moments.map((moment, i) => (
                    <Col key={i}>
                      <Moments
                        currentFightID={this.state.currentFightID}
                        imgSRC={this.state.fightInfo.fightImageURL}
                        moment={moment}
                      />
                    </Col>
                  ))
                ) : this.state.currentScreen === "Scorecard" &&
                  this.props.authUser ? (
                  <ScoreCard
                    currentFightID={this.state.currentFightID}
                    moments={this.state.moments}
                    currentFightInfo={this.state.fightInfo}
                    currentRD={this.props.currentRound}
                    start={this.state.start}
                    scoredBefore={this.state.scoredBefore}
                    changeScoredBefore={this.changeScoredBefore}
                    tally={this.state.tally ? this.state.tally : 0}
                    scoreCardPlay={this.scoreCardPlay}
                    recent={this.state.recent}
                  />
                ) : this.state.currentScreen === "Scorecard" &&
                  !this.props.authUser ? (
                  <Row justify="center" align="middle">
                    <Col span={24}>
                      {" "}
                      <h2 style={{ textAlign: "center" }}>
                        You have to log in or sign up to score a fight
                      </h2>
                      <Col span={24} align="middle">
                        <Link to={ROUTES.SIGN_IN}>
                          <Button>Login</Button>{" "}
                        </Link>
                      </Col>
                    </Col>
                  </Row>
                ) : this.state.currentScreen === "Most Recent" ? (
                  <>
                    <h1 className="cAlign pbottom1">Most Recent Scores</h1>
                    <MostRecentScore
                      allScores={this.state.allScores}
                      currentFightInfo={this.state.fightInfo}
                    />
                  </>
                ) : (
                  "null"
                )}
              </Row>

              <Row
                justify="space-between"
                style={{
                  backgroundColor: "#1f1f1f",
                  width: 100 + `%`,
                  padding: 20,
                }}
                align="middle"
              >
                <Row
                  justify="center"
                  style={{
                    width: 100 + `%`,
                  }}
                  gutter={[8, 8]}
                >
                  {this.state.currentScreen === "Scorecard" ? (
                    <Col>
                      {this.state.scoredBefore ||
                      !this.props.startStatus ? null : (
                        <ScoreCardButtonSave
                          handleSaveRound={this.handleSaveRound}
                        />
                      )}
                    </Col>
                  ) : null}
                </Row>{" "}
                {this.state.currentScreen === "Scorecard" ? (
                  <Col span={24} style={{ marginBottom: 20, marginTop: 10 }}>
                    {this.state.scoredBefore ? null : (
                      <ProgressBar
                        start={this.state.start}
                        scoredBefore={this.state.scoredBefore}
                        TallyChange={this.TallyChange}
                      />
                    )}
                  </Col>
                ) : null}
                <Col align="middle">
                  <Col span={24}>
                    {this.state.loading ? null : (
                      <TallyScore start={this.state.start} fighter={"A"} />
                    )}
                  </Col>
                  {this.state.fightInfo.fighterA}
                </Col>
                <Col align="middle">
                  <Col span={24}>
                    <TallyScore start={this.state.start} fighter={"B"} />
                  </Col>
                  {this.state.fightInfo.fighterB}
                </Col>
              </Row>
            </Col>
          ) : (
            <Col style={{ marginTop: 20 }}>
              <Button onClick={this.showDrawer}>Score Fights</Button>
            </Col>
          )}{" "}
        </Row>
        <Drawer
          title=""
          placement={this.props.orientation === "portrait" ? "bottom" : "right"}
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
          height={500}
          width={this.props.orientation === "portrait" ? null : 500}
        >
          <Col
            lg={8}
            md={24}
            sm={24}
            xs={24}
            style={{
              border: 1 + `px ` + `solid ` + `#434343`,
              borderRadius: 4 + `px`,
              background: "#262626",
            }}
          >
            <Row
              justify="start"
              style={{
                // marginBottom: 2 + `vh`,
                backgroundColor: "#1f1f1f",
                width: 100 + `%`,
                padding: 20,
              }}
              align="middle"
            >
              <Col span={16}>
                <Dropdown overlay={menu}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "baseline",
                    }}
                  >
                    <Title
                      level={4}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {this.state.currentScreen}{" "}
                    </Title>
                    <DownOutlined style={{ marginLeft: 5, fontSize: 12 }} />
                  </a>
                </Dropdown>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                overflowY: "scroll",
                height: 400,
                paddingLeft: 5 + `%`,
                paddingRight: 5 + `%`,
              }}
            >
              {this.state.currentScreen === "Moments" ? (
                this.state.moments.map((moment, i) => (
                  <Col key={i}>
                    <Moments
                      currentFightID={this.state.currentFightID}
                      imgSRC={this.state.fightInfo.fightImageURL}
                      moment={moment}
                    />
                  </Col>
                ))
              ) : this.state.currentScreen === "Scorecard" &&
                this.props.authUser ? (
                <ScoreCard
                  currentFightID={this.state.currentFightID}
                  moments={this.state.moments}
                  currentFightInfo={this.state.fightInfo}
                  currentRD={this.props.currentRound}
                  start={this.state.start}
                  scoredBefore={this.state.scoredBefore}
                  changeScoredBefore={this.changeScoredBefore}
                  tally={this.state.tally ? this.state.tally : 0}
                  onClose={this.onClose}
                  scoreCardPlay={this.scoreCardPlay}
                  recent={this.state.recent}
                />
              ) : this.state.currentScreen === "Scorecard" &&
                !this.props.authUser ? (
                <Row justify="center" align="middle">
                  <Col span={24}>
                    {" "}
                    <h2 style={{ textAlign: "center" }}>
                      You have to log in or sign up to score a fight
                    </h2>
                    <Col span={24} align="middle">
                      <Link to={ROUTES.SIGN_IN}>
                        <Button>Login</Button>{" "}
                      </Link>
                    </Col>
                  </Col>
                </Row>
              ) : this.state.currentScreen === "Most Recent" ? (
                <>
                  <h1 className="cAlign pbottom1">Most Recent Scores</h1>
                  <MostRecentScore
                    allScores={this.state.allScores}
                    currentFightInfo={this.state.fightInfo}
                  />
                </>
              ) : null}
            </Row>

            <Row
              justify="space-between"
              style={{
                backgroundColor: "#1f1f1f",
                width: 100 + `%`,
                padding: 20,
              }}
              align="middle"
            >
              <Row
                justify="center"
                style={{
                  width: 100 + `%`,
                }}
                gutter={[8, 8]}
              >
                {this.state.currentScreen === "Scorecard" ? (
                  <Col>
                    {this.state.scoredBefore ||
                    !this.props.startStatus ? null : (
                      <ScoreCardButtonSave
                        handleSaveRound={this.handleSaveRound}
                      />
                    )}
                  </Col>
                ) : null}
              </Row>{" "}
              {this.state.currentScreen === "Scorecard" ? (
                <Col span={24} style={{ marginBottom: 20, marginTop: 10 }}>
                  {this.state.scoredBefore ? null : (
                    <ProgressBar
                      start={this.state.start}
                      scoredBefore={this.state.scoredBefore}
                      TallyChange={this.TallyChange}
                    />
                  )}
                </Col>
              ) : null}
              <Col align="middle">
                <Col span={24}>
                  {this.state.loading ? null : (
                    <TallyScore start={this.state.start} fighter={"A"} />
                  )}
                </Col>
                {this.state.fightInfo.fighterA
                  ? this.state.fightInfo.fighterA.split(" ")[
                      this.state.fightInfo.fighterA.split(" ").length - 1
                    ]
                  : ""}
              </Col>
              <Col align="middle">
                <Col span={24}>
                  <TallyScore start={this.state.start} fighter={"B"} />
                </Col>

                {this.state.fightInfo.fighterB
                  ? this.state.fightInfo.fighterB.split(" ")[
                      this.state.fightInfo.fighterB.split(" ").length - 1
                    ]
                  : ""}
              </Col>
            </Row>
          </Col>
        </Drawer>
      </Content>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.sessionState.authUser ? state.sessionState.authUser.uid : null,
  authUser: state.sessionState.authUser,
  fightID: state.currentUserPages.currentLandingPageFight,
  currentMoment: state.currentUserPages.currentMomentPlay
    ? state.currentUserPages.currentMomentPlay
    : 0,
  currentRound: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.currentRound
    : 0,
  currentTotal: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound
    : 0,
  currentReasons: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.reasons
    : [],
  endStatus: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.endStatus
    : false,
  currentScores: state.roundScore.currentScores
    ? state.roundScore.currentScores
    : null,
  currentUserTally: state.roundScore.currentTally,
  startStatus: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.start
    : false,
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (user, uid) => dispatch({ type: "USER_SET", user, uid }),
  onSetEndStatus: (data) => dispatch({ type: "END_STATUS_SET", data }),
  onSetScoreRound: (data) =>
    dispatch({ type: "CURRENT_SCORE_ROUND_SET", data }),
  onSetMomentPlay: (data) =>
    dispatch({ type: "CURRENT_MOMENT_PLAY_SET", data }),
  onSetStartStatus: (data) => dispatch({ type: "START_STATUS_SET", data }),
  onSetMissingScores: (data) => dispatch({ type: "MISSING_SCORES_SET", data }),
});
export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(index);
