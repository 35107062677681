import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import ButtonClicked from "../ButtonClicked";
import { connect } from "react-redux";
import { moment } from "../../actions";

const MomentList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    props.moment(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentMoment) {
      setClicked(true);
      setSelectedButton(props.currentMoment);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (
      props.currentRowData === undefined ||
      props.currentRowData.length == 0
    ) {
      setSelectedButton("", setClicked(false));
    } else if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.moment,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }

    // if (props.currentRowData.length > 0) {
    //   setSelectedButton(
    //     props.currentRowData[props.currentRowIndex].moment,
    //     setClicked(true)
    //   );
    // } else if (
    //   props.currentRowData === undefined ||
    //   props.currentRowData.length == 0
    // ) {
    // } else {
    //   setSelectedButton("", setClicked(false));
    // }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col span={12}>
        <ButtonClicked
          value="Start"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={12}>
        <ButtonClicked
          value="Major Exchange"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});
const mapDispatchToProps = (dispatch) => {
  return {
    moment: (data) => dispatch(moment(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MomentList);
