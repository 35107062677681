import React, { useEffect } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Row, Col } from "antd";
import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignUpPageData from "../SignUpData";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import FighterAdd from "../AdminCreate/FighterAdd";
import FightAdd from "../AdminCreate/FightAdd";
import FightEdit from "../AdminCreate/FightEdit.js";
import FightList from "../FightList";
import Collection from "../Collection";
import FightTools from "../FightTools";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import useViewport from "../../useViewPort";
import useOrientation from "../../useOrientation";
import MyFights from "../MyFights";
import FilmStudy from "../MyFights/FilmStudy";
import Privacy from "../General/Privacy";
import Terms from "../General/Terms";
import ContactUs from "../General/ContactUs";
import Demo from "../MyFights/Demo";
import Playlist from "../Playlist";
import DataViz from "../DataViz";

const App = (props) => {
  const { width } = useViewport();
  const { screenOrientation } = useOrientation();

  useEffect(() => {
    props.onSetViewPort(width);
  }, [width]);

  useEffect(() => {
    props.onSetOrientation(screenOrientation);
  }, [screenOrientation]);

  return (
    <Router>
      <div>
        <Row>
          <Col span={24}>
            {" "}
            <Navigation />
          </Col>
        </Row>

        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_UP_DATA} component={SignUpPageData} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.ADD_FIGHTER} component={FighterAdd} />
        <Route path={ROUTES.ADD_FIGHT} component={FightAdd} />
        <Route path={ROUTES.FIGHT_LIST} component={FightList} />
        <Route path={ROUTES.FIGHT_COLLECT} component={Collection} />
        <Route path={ROUTES.EDIT_FIGHT} component={FightEdit} />
        <Route path={ROUTES.VIDEO_USER_TOOL} component={FightTools} />
        <Route path={ROUTES.MY_FIGHT} component={MyFights} />
        <Route path={ROUTES.MY_FIGHT_STUDY} component={FilmStudy} />
        <Route path={ROUTES.PRIVACY_POLICY} component={Privacy} />
        <Route path={ROUTES.TERMS_AND_CONDITIONS} component={Terms} />
        <Route path={ROUTES.CONTACT_US} component={ContactUs} />
        <Route path={ROUTES.DEMO} component={Demo} />
        <Route path={ROUTES.PLAYLIST} component={Playlist} />
        <Route path={ROUTES.DATAVIZ} component={DataViz} />
      </div>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSetViewPort: (data) => dispatch({ type: "VIEWPORT_SET", data }),
  onSetOrientation: (data) => dispatch({ type: "ORIENTATION_SET", data }),
});
export default compose(
  withFirebase,
  withAuthentication,
  connect(null, mapDispatchToProps)
)(App);
