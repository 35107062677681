import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import ButtonClicked from "../ButtonClicked";
import { connect } from "react-redux";
import { range } from "../../actions";

const RangeList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    console.log(selectedButton);
    props.range(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentRange) {
      setClicked(true);
      setSelectedButton(props.currentRange);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.range,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col span={8}>
        <ButtonClicked
          value="Long"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={8}>
        <ButtonClicked
          value="Mid"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
      <Col span={8}>
        <ButtonClicked
          value="Close"
          onClicked={onClicked}
          clickedState={clicked}
          selected={selectedButton}
        />
      </Col>{" "}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    range: (data) => dispatch(range(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RangeList);
