import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, Layout, Row, Col, Avatar, Dropdown, Button } from "antd";
import { DownOutlined, UserOutlined, MenuOutlined } from "@ant-design/icons";
import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
// import Logo from "../../assets/img/Logo.svg";
import Logo from "../../assets/img/ROUNDSCORE@2x.png";

import DisplayPic from "../../assets/img/Gold.png";

const Navigation = ({ authUser, width }) =>
  authUser ? (
    <NavigationAuth authUser={authUser} width={width} />
  ) : (
    <NavigationCustomer width={width} />
  );
const { Header } = Layout;
const NavigationAuth = ({ authUser }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={ROUTES.ADD_FIGHTER}>Add a fighter</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ROUTES.ADD_FIGHT}> Add a fight</Link>
      </Menu.Item>
    </Menu>
  );

  const menuTwo = (
    <Menu>
      {" "}
      <Menu.Item>
        <Link to={ROUTES.MY_FIGHT}>My Fights</Link>
      </Menu.Item>{" "}
      <Menu.Item>
        <Link to={ROUTES.PLAYLIST}>Playlists</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ROUTES.ACCOUNT}>Account settings</Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link to={ROUTES.HOME}>My Scorecards</Link>
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item>
        <SignOutButton />
      </Menu.Item>
    </Menu>
  );
  const menuOne = (
    <Menu>
      <Menu.Item>
        <Link to={ROUTES.ACCOUNT}>Account settings</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ROUTES.FIGHT_LIST}>My Fights</Link>{" "}
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item>
        <SignOutButton />
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={style.header}>
      <Row span={24} justify="start" align="middle">
        <Col span={6}>
          <Link to={ROUTES.LANDING}>
            {" "}
            <img style={{ maxWidth: 150 }} src={Logo} alt="Ant Design" />
          </Link>
        </Col>
        <Col span={12}>
          <Row span={24}>
            {!!authUser.roles[ROLES.ADMIN] && (
              <>
                <Col span={6}>
                  <Link to={ROUTES.ADMIN}>Admin</Link>{" "}
                </Col>

                <Col span={6}>
                  <Link to={"/"}>
                    <Dropdown overlay={menu} placement="bottom">
                      <Link to={ROUTES.ADD_FIGHTER}>
                        Add <DownOutlined style={{ fontSize: 8 }} />
                      </Link>
                    </Dropdown>
                  </Link>
                </Col>

                <Col span={6}>
                  <Link to={ROUTES.FIGHT_LIST}>Fights</Link>{" "}
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col span={6}>
          <Row justify="space-between" gutter={[8, 8]}>
            <Col span={12} align="end"></Col>
            <Col span={12} align="end">
              <Dropdown
                overlay={
                  !!authUser.roles[ROLES.DATALEVEL1] ||
                  !!authUser.roles[ROLES.DATALEVEL2]
                    ? menuOne
                    : menuTwo
                }
                placement="bottomRight"
                trigger={["click"]}
              >
                <Avatar
                  src={DisplayPic}
                  style={{ border: 1 + `px` + ` Solid #ACACAC` }}
                />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

const NavigationNonAuth = (props) => {
  const [loginHover, setLoginHover] = useState(false);
  const [signupHover, setSignupHover] = useState(false);
  const menuTwo = (
    <Menu>
      <Menu.Item>
        <Link to={ROUTES.SIGN_IN}>Login</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ROUTES.SIGN_UP}>Sign up</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header style={style.header}>
      <Row span={24} justify="start">
        <Col span={6}>
          <Link to={ROUTES.LANDING}>
            {" "}
            <img style={{ width: 150 }} src={Logo} alt="Ant Design" />
          </Link>
        </Col>
        <Col span={props.width > 1024 ? 10 : props.width > 820 ? 8 : 6}></Col>

        <Col
          span={props.width > 1024 ? 8 : props.width > 820 ? 10 : 12}
          align="end"
        >
          {props.width > 768 ? (
            <Row justify="space-between" gutter={[8, 8]}>
              <Col span={12} align="end"></Col>
              <Col span={12} align="end">
                <Link to={ROUTES.SIGN_IN}>
                  <Button
                    style={loginHover ? style.outline : style.outlineHover}
                    onMouseEnter={() => {
                      setLoginHover(true);
                    }}
                    onMouseLeave={() => {
                      setLoginHover(false);
                    }}
                  >
                    Login
                  </Button>{" "}
                </Link>
                <Link to="/signup">
                  <Button
                    style={signupHover ? style.primaryHover : style.primary}
                    onMouseEnter={() => {
                      setSignupHover(true);
                    }}
                    onMouseLeave={() => {
                      setSignupHover(false);
                    }}
                  >
                    Sign up
                  </Button>{" "}
                </Link>
              </Col>
            </Row>
          ) : (
            <Dropdown
              overlay={menuTwo}
              placement="bottomRight"
              trigger={["click"]}
            >
              <MenuOutlined style={{ fontSize: 24 }} />
            </Dropdown>
          )}
        </Col>
      </Row>
    </Header>
  );
};

const NavigationCustomer = (props) => {
  const [loginHover, setLoginHover] = useState(false);
  const [signupHover, setSignupHover] = useState(false);
  const [topBtnHover, setTopBtnHover] = useState(false);
  const menuTwo = (
    <Menu>
      <Menu.Item>
        <Link to={ROUTES.SIGN_IN}>Login</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={ROUTES.SIGN_UP}>Sign up</Link>
      </Menu.Item>
    </Menu>
  );
  const menuCustomer = (
    <Menu>
      <Menu.Item>
        <Link to={ROUTES.CONTACT_US}>Join us</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header style={style.header}>
      <Row
        span={24}
        justify={props.width > 767 ? "start" : "space-between"}
        align="center"
      >
        <Col span={6}>
          <Link to={ROUTES.LANDING}>
            {" "}
            <img style={{ width: 150 }} src={Logo} alt="Logo" />
          </Link>
        </Col>
        <Col span={props.width > 1024 ? 10 : props.width > 820 ? 8 : 6}></Col>

        <Col span={props.width > 1024 ? 8 : props.width > 820 ? 10 : 12}>
          {props.width > 767 ? (
            <Row justify="end">
              <Col span={12}>
                <Button
                  style={topBtnHover ? style.buttonHover : style.button}
                  onMouseEnter={() => {
                    setTopBtnHover(true);
                  }}
                  onMouseLeave={() => {
                    setTopBtnHover(false);
                  }}
                >
                  <Link to={ROUTES.CONTACT_US}>Join us </Link>
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify="end" align="center">
              <Col span="4">
                <Dropdown overlay={menuCustomer} placement="bottomRight">
                  <MenuOutlined style={{ fontSize: 24 }} />
                </Dropdown>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Header>
  );
};

const style = {
  header: {
    // paddingTop: 10,

    // paddingBottom: 20,
    paddingLeft: 5 + `%`,
    paddingRight: 5 + `%`,
    backgroundColor: "#1f1f1f",
  },
  icon: {
    fontSize: 25,
  },
  outlineHover: {
    borderColor: "#177ddc",
    color: "#177ddc",
  },
  outline: {
    borderColor: "#177ede99",
    color: "#177ede99",
  },
  primary: {
    background: "#e8b339",
    borderStyle: "none",
    color: "#fff",
  },
  primaryHover: {
    background: "#e8b43b99",
    borderStyle: "none",
  },
  button: {
    width: 180 + "px",

    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    fontWeight: "bolder",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
  },
  buttonHover: {
    width: 180 + "px",

    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    fontWeight: "bolder",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
  },
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  width: state.viewPortReducer.viewPort,
});
export default connect(mapStateToProps)(Navigation);
