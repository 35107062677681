import { Ropes, middleRing } from "../constants/lists";
export const FilmEventFinder = (
  fighterData,
  searchData,
  opponentData,
  fighterID,
  opponentID
) => {
  const singlePunchEventFinder = (fighterData, searchData) => {
    const singleSearchQueryResult = [];
    fighterData.forEach((fightDatum) => {
      // change to object.entries to make the code smaller
      var totalCount = 0;
      var eventSearchCount = 0;
      if (searchData.round.length > 0) {
        // const found = fightDatum.round.some(r=> arr2.indexOf(r) >= 0) for array to array comparison
        if (searchData.round.includes(fightDatum.round)) {
          eventSearchCount++;
        }
        totalCount++;
      }

      if (searchData.hand.length > 0) {
        if (searchData.hand.includes(fightDatum.hand)) {
          eventSearchCount++;
        }

        totalCount++;
      }
      if (searchData.punch.length > 0) {
        if (searchData.punch.includes(fightDatum.punch)) {
          eventSearchCount++;
        }

        totalCount++;
      }

      if (searchData.status.length > 0) {
        if (searchData.status.includes(fightDatum.status)) {
          eventSearchCount++;
        }

        totalCount++;
      }

      if (searchData.stance.length > 0) {
        if (searchData.stance.includes(fightDatum.stance)) {
          eventSearchCount++;
        }

        totalCount++;
      }
      if (searchData.range.length > 0) {
        if (searchData.range.includes(fightDatum.range)) {
          eventSearchCount++;
        }

        totalCount++;
      }

      if (searchData.ring.length > 0) {
        if (searchData.ring.includes("Ropes")) {
          if (Ropes.includes(fightDatum.ring)) {
            eventSearchCount++;
          }
        }
        if (searchData.ring.includes("Middle")) {
          if (middleRing.includes(fightDatum.ring)) {
            eventSearchCount++;
          }
        }

        totalCount++;
      }

      if (searchData.body.length > 0) {
        const bodySearch = [];

        if (searchData.body.includes("Head")) {
          bodySearch.push(
            "Forehead",
            "Temple Left",
            "Temple Right",
            "Eye/CheekBone Left",
            "Eye/CheekBone Right",
            "Jaw Left",
            "Jaw Right",
            "Mouth",
            "Neck Left",
            "Neck Right",
            "Neck Front",
            "Back"
          );
        }

        if (searchData.body.includes("Body")) {
          bodySearch.push(
            "Shoulder Left",
            "Shoulder Right",
            "Shoulder Left Side",
            "Shoulder Right Side",
            "Chest",
            "Bicep Left",
            "Bicep Right",
            "Forearm Left",
            "Forearm Right",
            "Stomach Upper",
            "Stomach Lower",
            "Rib Left",
            "Rib Right",
            "Hip Left",
            "Hip Right",
            "BTB"
          );
        }

        if (
          fightDatum.body &&
          fightDatum.body.some((r) => bodySearch.indexOf(r) >= 0)
        ) {
          eventSearchCount++;
        }
        totalCount++;
      }

      if (searchData.defense && searchData.defense.length > 0) {
        const defenseSearch = [];

        if (searchData.defense.includes("Block")) {
          defenseSearch.push(
            "Block One-Forward",
            "Block Both-Forward",
            "Block Left-Side",
            "Block Right-Side"
          );
        }

        if (searchData.body.includes("Parry")) {
          defenseSearch.push("Parry Right", "Parry Left");
        }

        if (searchData.body.includes("Shoulder Roll")) {
          defenseSearch.push("Shoulder Roll");
        }

        if (searchData.defense.includes("Slip")) {
          defenseSearch.push(
            "Bobbing",
            "Slip Left",
            "Slip Right",
            "Slip Lower Left",
            "Slip Lower Right",
            "Slip Back",
            "Slip Forward"
          );
        }

        if (searchData.body.includes("Clinching")) {
          defenseSearch.push("Clinching");
        }

        if (searchData.defense.includes("Others")) {
          defenseSearch.push(
            "No Defense",
            "Counter",
            "Body Move",
            "Push",
            "Step Back",
            "Head Move"
          );
        }
        if (
          fightDatum.defense &&
          fightDatum.defense.some((r) => defenseSearch.indexOf(r) >= 0)
        ) {
          eventSearchCount++;
        }
        totalCount++;
      }

      if (
        totalCount === eventSearchCount &&
        (totalCount === eventSearchCount) !== 0
      ) {
        singleSearchQueryResult.push(fightDatum);
      }
    });
    return singleSearchQueryResult;
  };
  const multiPunchEventFinder = (comboData, searchData) => {
    const comboSearchQueryResult = [];
    comboData.forEach((comboDatum, index) => {
      var passSinglePunchEvent = false;
      for (let i = 0; i < comboDatum.length; i++) {
        if (searchData.round.length > 0) {
          if (searchData.round.includes(comboDatum[i].round)) {
            passSinglePunchEvent =
              singlePunchEventFinder([comboDatum[i]], searchData).length > 0;

            if (passSinglePunchEvent) {
              comboSearchQueryResult.push(comboDatum);
              i = comboDatum.length; // if it singlePunchEventFinder returns an unempty array then no need to run the for loop
            }
          } else {
            i = comboDatum.length; // impossible to have a combo in one set from different rounds
          }
        } else {
          passSinglePunchEvent =
            singlePunchEventFinder([comboDatum[i]], searchData).length > 0;

          if (passSinglePunchEvent) {
            comboSearchQueryResult.push(comboDatum);
            i = comboDatum.length; // if it singlePunchEventFinder returns an unempty array then no need to run the for loop
          }
        }
      }
    });
    console.log("====================================");
    console.log(comboSearchQueryResult);
    console.log("====================================");

    return comboSearchQueryResult;
  };
  const counterPunchEventFinder = (fighterData, searchData, opponentData) => {
    const allFightData = fighterData.concat(opponentData);
    allFightData.sort((a, b) =>
      a.videoTime > b.videoTime ? 1 : b.videoTime > a.videoTime ? -1 : 0
    );
    const counterPunches = [];

    allFightData.forEach((fightDatum, index) => {
      if (opponentID === fightDatum.fighter) {
        if (index < allFightData.length - 1) {
          if (allFightData[index + 1].fighter === fighterID) {
            if (
              fightDatum.videoTime + 1.5 >=
              allFightData[index + 1].videoTime
            ) {
              counterPunches.push(allFightData[index + 1]);
            }
          }
        }
      }
    });
    return singlePunchEventFinder(counterPunches, searchData);
  };
  const multiLimitPunchComboEventFinder = (fighterData, searchData, limit) => {
    const multiComboData = [];
    fighterData.sort((a, b) =>
      a.videoTime > b.videoTime ? 1 : b.videoTime > a.videoTime ? -1 : 0
    );
    var currentArIndex = 0; //count for the position of the combo array in the multiComboData array

    fighterData.forEach((fightDatum, index) => {
      if (index < fighterData.length - 1) {
        if (fightDatum.videoTime + 1.5 >= fighterData[index + 1].videoTime) {
          if (multiComboData[currentArIndex]?.length === limit) {
            multiComboData.pop();
            // if the array as already started and it greater than the limit it pops the array from the multicomboData
          } else if (multiComboData[currentArIndex]?.length >= 2) {
            multiComboData[currentArIndex].push(fighterData[index + 1]);
          } else {
            multiComboData.push([fightDatum, fighterData[index + 1]]); //initialize array
          }
        } else {
          if (multiComboData[currentArIndex]?.length === limit) {
            currentArIndex++;
          } else if (multiComboData[currentArIndex]?.length >= 2) {
            multiComboData.pop();
            // multiComboData.splice(currentArIndex, 1);
            // if the array as already started and it smaller than the limit it pops the array from the multicomboData
            // Splice and pop works here because if array greater than 2 but not the limit it removes it and if array length not 2 or above then no need to worry because you never initialized  multiComboData.push([fightDatum, fighterData[index + 1]]);
          }
        }
      } else {
        if (multiComboData[currentArIndex]?.length < limit) {
          multiComboData.splice(currentArIndex, 1);
          //if the last array doesn't meet the minimum required limit(5) then Array is spliced
        }
      }
    });

    return multiPunchEventFinder(multiComboData, searchData);
  };
  const multiLimitlessPunchComboEventFinder = (
    fighterData,
    searchData,
    minLimit
  ) => {
    const multiComboData = [];
    var currentArIndex = 0;

    fighterData.sort((a, b) =>
      a.videoTime > b.videoTime ? 1 : b.videoTime > a.videoTime ? -1 : 0
    );
    fighterData.forEach((fightDatum, index) => {
      if (index < fighterData.length - 1) {
        if (fightDatum.videoTime + 1.5 >= fighterData[index + 1].videoTime) {
          if (multiComboData[currentArIndex]?.length >= 2) {
            multiComboData[currentArIndex].push(fighterData[index + 1]);
            // If array has initially started and passes then add datum to array
          } else {
            multiComboData.push([fightDatum, fighterData[index + 1]]); // Initial Passing
          }
        } else {
          if (multiComboData[currentArIndex]?.length >= minLimit) {
            currentArIndex++;
            //if the array meets the minimum required limit(5) then current index is increase and array is safe
          } else {
            multiComboData.splice(currentArIndex, 1);
            //if the doesn't array meet the minimum required limit(5) then Array is spliced
            // Splice works here and not pop because splice at an index that doesn't exist does nothing
          }
        }
      } else {
        if (multiComboData[currentArIndex]?.length < minLimit) {
          multiComboData.splice(currentArIndex, 1);
          //if the last array doesn't meet the minimum required limit(5) then Array is spliced
        }
      }
    });

    console.log(multiComboData);
    return multiPunchEventFinder(multiComboData, searchData);
  };
  switch (searchData.qualifier) {
    case "Single Punch": // Single Punch Search
      return singlePunchEventFinder(fighterData, searchData);
    case "Counter": // Counter Punch Search
      return counterPunchEventFinder(fighterData, searchData, opponentData);
    case "2 Punch Combo": // 2 Punch Combo Search
      return multiLimitPunchComboEventFinder(fighterData, searchData, 2);
    case "3 Punch Combo": // 3 Punch Combo Search
      return multiLimitPunchComboEventFinder(fighterData, searchData, 3);
    case "4 Punch Combo": // 4 Punch Combo Search
      return multiLimitPunchComboEventFinder(fighterData, searchData, 4);
    case "5+ Punch Combo": //5 and greater Punch Combo  Search
      return multiLimitlessPunchComboEventFinder(fighterData, searchData, 5);
    default:
      break;
  }
};
