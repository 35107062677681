import React from "react";
import { Row, Col, Divider, Tooltip } from "antd";
import { Progress } from "@ant-design/charts";
import { InfoCircleOutlined } from "@ant-design/icons";
function ProgressCol() {
  var config = {
    height: 30,
    width: 300,
    autoFit: false,
    percent: 0.536,
    barWidthRatio: 0.8,
    color: ["#08979c", "#E8EDF3"],

    annotations: [
      {
        type: "line",
        start: ["80%", "0%"],
        end: ["80%", "100%"],
        style: {
          stroke: "#f00",
          lineWidth: 2,
        },
      },
    ],
  };
  var configData = {
    height: 30,
    width: 300,
    autoFit: false,
    percent: 0.536,
    barWidthRatio: 0.8,
    color: ["#F4664A", "#E8EDF3"],
    annotations: [
      {
        type: "line",
        start: ["40%", "0%"],
        end: ["40%", "100%"],
        style: {
          stroke: "#f00",
          lineWidth: 2,
        },
      },
    ],
  };
  return (
    <>
      {/* <h4>
                  Tiny Progress Chart That shows the round is higher than the
                  avg (Customizable)
                </h4>
                <h5>Offense</h5>
                land percentage Best Offensive Round (Round with the highest
                land Percentage with the highest number of punches Above
                Average) sort by percentage then the first above average (number
                of punches per round) is the Off best round
                <h5>Defense</h5> Best Defensive Round (Round with the lowest
                land number with the highest number of punches above average)
                sort by percentage then the first above average number of
                punches per round) is the Def best round */}
      <Row justify="space-between" align="center">
        <Col span="12">
          <p>Best Offensive Round</p>
        </Col>
        <Col span="12">
          <Row justify="end">
            <Col>
              <Tooltip
                title="The total number of punches Saul Alvarez threw in the fight"
                trigger={["click"]}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        </Col>{" "}
      </Row>
      <Row justify="start">
        <Col span="12">
          <p style={style.headerNumber}>4</p>
        </Col>
        <Col span="12"></Col>
      </Row>{" "}
      <Row justify="center">
        <Col span="24">
          <Row justify="center">
            <Tooltip title="25 of 50">
              <Col span="24">
                <Progress {...config} />
              </Col>
            </Tooltip>
            <Tooltip title="21 of 30">
              <Col span="24">
                <Progress {...configData} />
              </Col>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row justify="start">
        <Col span="12">
          {" "}
          <Row align="middle">
            <Col>
              <p># of Landed Punches </p>{" "}
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: "#F4664A",
                  margin: "0 auto",
                }}
              ></div>
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row align="middle">
            <Col>
              <p>% of Landed Punches</p>{" "}
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: "#08979c",
                  margin: "0 auto",
                }}
              ></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

const style = {
  headerNumber: { color: "#fff", fontSize: "42px" },
};
export default ProgressCol;
