const INITIAL_STATE = {};

const applySetFrameForwardCount = (state, action) => ({
  ...state,
  frameForwardCount: action.data,
});

function frameForwardCountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FRAME_FORWARD_COUNT_SET": {
      return applySetFrameForwardCount(state, action);
    }

    default:
      return state;
  }
}
export default frameForwardCountReducer;
