import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as ROLES from "../../constants/roles";
import { withAuthorization, withEmailVerification } from "../Session";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Layout, Row, Col, Button, Affix, message, Alert, Modal } from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ButtonList from "./ButtonListcopy";
import TableData from "./TableData";
import VideoApp from "./VideoApp";
import TableMenu from "./TableMenu";
import roundEstimator from "../../functions/roundEstimator";
import columnData from "./columnData";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ClearSome from "../Collection/ClearSome";
import Manual from "./Manual";

const Collection = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentFightInfo, setCurrentFightInfo] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);
  const [secStart30, setSecStart30] = useState(0);
  const [alertClosed, setAlertClosed] = useState(false);
  const [infoHover, setInfoHover] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Content } = Layout;
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (props.currentFightUID) {
      const availableFight = props.listOfFights.filter(
        (fight) => fight.uid === props.currentFightUID
      );

      if (availableFight.length > 0) {
        props.firebase
          .moments(props.currentFightUID)
          .where("action", "==", "Start")
          .onSnapshot((snapshot) => {
            if (snapshot.size) {
              let moments = [];
              snapshot.forEach((doc) =>
                moments.push({ ...doc.data(), uid: doc.id })
              );
              props.onSetMomentRoundStart(moments);
            } else {
              props.onSetMomentRoundStart([]);
            }
          });

        props.onSetFight(availableFight[0]);
        setCurrentFightInfo(availableFight[0], setLoading(false));
      } else {
        props.firebase
          .fight(props.currentFightUID)
          .get()
          .then((doc) => {
            props.onSetFight(doc.data());
            setCurrentFightInfo(doc.data());
          })
          .then(() => {
            console.log("yesss");
            props.firebase
              .moments(props.currentFightUID)
              .where("action", "==", "Start")
              .onSnapshot((snapshot) => {
                if (snapshot.size) {
                  let moments = [];
                  snapshot.forEach((doc) =>
                    moments.push({ ...doc.data(), uid: doc.id })
                  );
                  moments.push(props.currentFightUID);
                  props.onSetMomentRoundStart(moments);
                } else {
                  props.onSetMomentRoundStart([]);
                }
              });

            setLoading(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setRowSelected(false);
    } else {
      setRowSelected(true);
    }
    // console.log(props.currentRowData, props.currentRowIndex);
  }, [props.currentRowData]);
  const doSomething = () => {
    if (props.currentRowData.length > 0) {
      editRow(props.currentRowData[props.currentRowIndex]?.key);
    } else {
      const actionInfo = {
        round: props.currentRound,
        videoTime: props.currentVideoTime,
        desc: props.currentMoment,
        style: props.currentStyleOfFight,
      };
      if (actionInfo.desc === "Start") {
        // props.onSetRoundStart(actionInfo.videoTime);
        let fullRoundTime =
          actionInfo.style === "Boxing"
            ? "3:00:00"
            : actionInfo.style === "MMA"
            ? "5:00:00"
            : actionInfo.style === "WomenBoxing"
            ? "2:00:00"
            : "";
        console.log(fullRoundTime);
        props.firebase
          .moments(props.currentFightUID)
          .add({
            round: actionInfo.round,
            roundTime: fullRoundTime,
            videoTime: actionInfo.videoTime,
            action: actionInfo.desc,
            createdAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Moment was Added");
          });
      } else if (actionInfo.desc === "Major Exchange") {
        const currentRoundStart = props.currentRoundMomentStart.filter(
          (moment) => moment.round === props.currentRound
        );
        if (currentRoundStart.length > 0) {
          let roundTime = roundEstimator(
            actionInfo.videoTime,
            currentRoundStart[0].videoTime,
            actionInfo.style
          );

          props.firebase
            .moments(props.currentFightUID)
            .add({
              round: actionInfo.round,
              roundTime: roundTime,
              videoTime: actionInfo.videoTime,
              action: actionInfo.desc,
              createdAt: props.firebase.fieldValue.serverTimestamp(),
            })
            .then(() => {
              message.success("Moment was Added");
            });
          console.log(currentRoundStart[0].videoTime);
        } else {
          message.warning("There is no round start available");
        }
      } else {
        const currentRoundStart = props.currentRoundMomentStart.filter(
          (moment) => moment.round === props.currentRound
        );
        if (
          currentRoundStart.length > 0 &&
          props.currentAction.fighter !== ""
        ) {
          let roundTime = roundEstimator(
            actionInfo.videoTime,
            currentRoundStart[0].videoTime,
            actionInfo.style
          );

          props.firebase
            .fightData(props.currentFightUID, props.currentAction.fighter)
            .add({
              ...props.currentAction, // Create the key value pair for each of the allowable fields
              roundTime: roundTime,
              videoTime: actionInfo.videoTime,
              createdAt: props.firebase.fieldValue.serverTimestamp(),
            })
            .then(() => {
              message.success("Fight Datum was Added");
            });
          console.log(currentRoundStart[0].videoTime);
        } else {
          message.warning(
            "There is no round or round start available or fighter name isn't selected"
          );
        }
      }
    }
  };
  const editRow = (rowKey) => {
    const actionEditInfo = {
      round: props.currentRound,
      videoTime: props.currentVideoTime,
      desc: props.currentMoment,
      style: props.currentStyleOfFight,
    };
    if (props.currentTableMenu == "Moment") {
      if (actionEditInfo.desc === "Start") {
        let fullRoundTime =
          actionEditInfo.style === "Boxing"
            ? "3:00:00"
            : actionEditInfo.style === "MMA"
            ? "5:00:00"
            : actionEditInfo.style === "WomenBoxing"
            ? "2:00:00"
            : "";
        props.firebase
          .moment(props.currentFightUID, rowKey)
          .update({
            round: actionEditInfo.round,
            roundTime: fullRoundTime,
            videoTime: actionEditInfo.videoTime,
            action: actionEditInfo.desc,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Moment was Editted");
          });
      } else {
        console.log(rowKey);

        const currentRoundStart = props.currentRoundMomentStart.filter(
          (moment) => moment.round === props.currentRound
        );
        console.log(props.currentRoundMomentStart, currentRoundStart);
        if (currentRoundStart.length > 0) {
          let roundTime = roundEstimator(
            actionEditInfo.videoTime,
            currentRoundStart[0].videoTime,
            actionEditInfo.style
          );
          props.firebase
            .moment(props.currentFightUID, rowKey)
            .update({
              round: actionEditInfo.round,
              roundTime: roundTime,
              videoTime: actionEditInfo.videoTime,
              action: actionEditInfo.desc,
              editedAt: props.firebase.fieldValue.serverTimestamp(),
            })
            .then(() => {
              message.success("Moment was Editted");
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } else if (props.currentTableMenu == "Offense") {
      const currentRoundStart = props.currentRoundMomentStart.filter(
        (moment) => moment.round === props.currentRound
      );
      if (currentRoundStart.length > 0 && props.currentAction.fighter !== "") {
        let roundTime = roundEstimator(
          actionEditInfo.videoTime,
          currentRoundStart[0].videoTime,
          actionEditInfo.style
        );

        props.firebase
          .fightDatum(
            props.currentFightUID,
            props.currentAction.fighter,
            rowKey
          )
          .update({
            round: props.currentAction.round,
            hand: props.currentAction.hand,
            punch: props.currentAction.punch,
            status: props.currentAction.status,
            range: props.currentAction.range,
            stance: props.currentAction.stance,
            level: props.currentAction.level,
            roundTime: roundTime,
            videoTime: actionEditInfo.videoTime,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Fight Datum was Editted");
          });
        console.log(currentRoundStart[0].videoTime);
      } else {
        message.warning(
          "There is no round or round start available or fighter name isn't selected"
        );
      }
    } else if (props.currentTableMenu == "Defense") {
      const currentRoundStart = props.currentRoundMomentStart.filter(
        (moment) => moment.round === props.currentRound
      );
      if (currentRoundStart.length > 0 && props.currentAction.fighter !== "") {
        let roundTime = roundEstimator(
          actionEditInfo.videoTime,
          currentRoundStart[0].videoTime,
          actionEditInfo.style
        );

        props.firebase
          .fightDatum(
            props.currentFightUID,
            props.currentAction.fighter,
            rowKey
          )
          .update({
            defense: props.currentAction.defense,
            roundTime: roundTime,
            videoTime: actionEditInfo.videoTime,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Fight Datum was Editted");
          });
        console.log(currentRoundStart[0].videoTime);
      } else {
        message.warning(
          "There is no round or round start available or fighter name isn't selected"
        );
      }
    } else if (props.currentTableMenu == "Body") {
      const currentRoundStart = props.currentRoundMomentStart.filter(
        (moment) => moment.round === props.currentRound
      );
      if (currentRoundStart.length > 0 && props.currentAction.fighter !== "") {
        let roundTime = roundEstimator(
          actionEditInfo.videoTime,
          currentRoundStart[0].videoTime,
          actionEditInfo.style
        );

        props.firebase
          .fightDatum(
            props.currentFightUID,
            props.currentAction.fighter,
            rowKey
          )
          .update({
            body: props.currentAction.body,
            roundTime: roundTime,
            videoTime: actionEditInfo.videoTime,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Fight Datum was Editted");
          });
        console.log(currentRoundStart[0].videoTime);
      } else {
        message.warning(
          "There is no round or round start available or fighter name isn't selected"
        );
      }
    } else if (props.currentTableMenu == "Misc") {
      const currentRoundStart = props.currentRoundMomentStart.filter(
        (moment) => moment.round === props.currentRound
      );
      if (currentRoundStart.length > 0 && props.currentAction.fighter !== "") {
        let roundTime = roundEstimator(
          actionEditInfo.videoTime,
          currentRoundStart[0].videoTime,
          actionEditInfo.style
        );

        props.firebase
          .fightDatum(
            props.currentFightUID,
            props.currentAction.fighter,
            rowKey
          )
          .update({
            misc: props.currentAction.misc,
            roundTime: roundTime,
            videoTime: actionEditInfo.videoTime,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Fight Datum was Editted");
          });
        console.log(currentRoundStart[0].videoTime);
      } else {
        message.warning(
          "There is no round or round start available or fighter name isn't selected"
        );
      }
    } else if (props.currentTableMenu == "Ring") {
      const currentRoundStart = props.currentRoundMomentStart.filter(
        (moment) => moment.round === props.currentRound
      );
      if (currentRoundStart.length > 0 && props.currentAction.fighter !== "") {
        let roundTime = roundEstimator(
          actionEditInfo.videoTime,
          currentRoundStart[0].videoTime,
          actionEditInfo.style
        );

        props.firebase
          .fightDatum(
            props.currentFightUID,
            props.currentAction.fighter,
            rowKey
          )
          .update({
            ring: props.currentAction.ring,
            roundTime: roundTime,
            videoTime: actionEditInfo.videoTime,
            editedAt: props.firebase.fieldValue.serverTimestamp(),
          })
          .then(() => {
            message.success("Fight Datum was Editted");
          });
        console.log(currentRoundStart[0].videoTime);
      } else {
        message.warning(
          "There is no round or round start available or fighter name isn't selected"
        );
      }
    }
  };
  const SecStart = () => {
    setSecStart30(props.currentVideoTime - 150);
  };
  const OneMinSecStart = () => {
    setSecStart30(props.currentVideoTime - 90);
  };
  const deleteSomething = () => {
    const key = props.currentRowData[props.currentRowIndex]?.key;
    const fightID = props.currentFightUID;
    const fighterID = props.currentFighter;
    if (
      props.currentMoment === "Start" ||
      props.currentMoment === "Major Exchange"
    ) {
      props.firebase
        .moment(fightID, key)
        .delete()
        .then(function () {
          message.success("Document successfully deleted!");
        })
        .catch(function (error) {
          message
            .error("Error removing document: ", error)
            .then(function () {
              message.success("Document successfully deleted!");
            })
            .catch(function (error) {
              message.error("Error removing document: ", error);
            });
        });
      console.log("here we are");
    } else {
      props.firebase.fightDatum(fightID, fighterID, key).delete();
      console.log("here we are not, jk");
    }
  };
  const indexChanger = () => {
    if (props.currentRowData.length - 1 > props.currentRowIndex) {
      console.log(props.currentRowIndex);
      props.onSetCurrentRowIndex(props.currentRowIndex + 1);
    } else {
      message.warning("You have reached the end of the list");
    }
  };

  return (
    <Content
      span={24}
      style={{ paddingLeft: 5 + `%`, paddingRight: 5 + `%`, marginTop: 25 }}
    >
      {loading ? (
        <p>Loading</p>
      ) : (
        <Row justify="center" style={{ marginBottom: 20 }}>
          <Modal
            title="References"
            visible={isModalVisible}
            closable={false}
            onOk={handleOk}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ type: "default" }}
            onCancel={handleCancel}
          >
            <Manual currentTable={props.currentTableMenu} />
          </Modal>
          <Col span={16}>
            <VideoApp currentMoment={secStart30} />
          </Col>
          <Col span={8} style={{ border: 1 + `px ` + `solid ` + `#434343` }}>
            <Row
              justify="space-between"
              style={{
                marginBottom: 2 + `vh`,
                backgroundColor: "#303030",
                width: 100 + `%`,
                padding: 20,
              }}
            >
              {" "}
              <Col span={16}>
                {" "}
                <p>Current Time : {props.currentVideoTime}</p>
              </Col>
              {!!props.authUser.roles[ROLES.ADMIN] && (
                <>
                  <Col span={8}>
                    <Button onClick={SecStart}>30SecStart</Button>
                    <Button onClick={OneMinSecStart}>1min30SecStart</Button>
                  </Col>
                  <Col span="4">
                    <InfoCircleOutlined
                      onMouseEnter={() => {
                        setInfoHover(true);
                      }}
                      onMouseLeave={() => {
                        setInfoHover(false);
                      }}
                      onClick={showModal}
                      style={{
                        cursor: "pointer",
                        color: infoHover ? "#ad8b00" : null,
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <ClearSome />
                  </Col>
                  <Col span={6}>
                    <Button
                      danger
                      disabled={rowSelected}
                      onClick={deleteSomething}
                    >
                      Delete
                    </Button>
                  </Col>
                </>
              )}
              {(props.authUser.roles[ROLES.DATALEVEL1] ||
                props.authUser.roles[ROLES.DATALEVEL2]) && (
                <>
                  <Col span={8}>
                    <Row
                      justify="space-between"
                      gutter={[16, 8]}
                      align="middle"
                    >
                      <Col span="4">
                        <InfoCircleOutlined
                          onMouseEnter={() => {
                            setInfoHover(true);
                          }}
                          onMouseLeave={() => {
                            setInfoHover(false);
                          }}
                          onClick={showModal}
                          style={{
                            cursor: "pointer",
                            color: infoHover ? "#ad8b00" : null,
                          }}
                        />
                      </Col>
                      <Col span="20">
                        {" "}
                        <ClearSome />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Row justify="space-between" gutter={[16, 8]}>
                  <Col span={16}>
                    {alertClosed ? (
                      <Alert
                        message="Current Row Info"
                        description={` Round:  
                            ${
                              props.currentRowData[props.currentRowIndex]
                                ? props.currentRowData[props.currentRowIndex]
                                    .round
                                : ""
                            }, ${"  "}
                             
                          Time of Round: 
                            ${
                              props.currentRowData[props.currentRowIndex]
                                ? props.currentRowData[props.currentRowIndex]
                                    .roundTime
                                : " "
                            }`}
                        type="info"
                        closable
                        onClose={() => {
                          setAlertClosed(false);
                        }}
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          setAlertClosed(true);
                        }}
                      >
                        Show Current Row
                      </Button>
                    )}
                  </Col>
                  <Col span={8}>
                    <Row justify="end">
                      <Col>
                        <Affix offsetTop={10}>
                          <div>
                            <Button
                              disabled={rowSelected}
                              onClick={indexChanger}
                            >
                              <ArrowLeftOutlined />
                            </Button>
                            <Button onClick={doSomething}>
                              <ArrowRightOutlined />
                            </Button>
                          </div>
                        </Affix>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ButtonList
              fighterInfo={{
                fighterAUID: currentFightInfo.fightNameAUID,
                fighterBUID: currentFightInfo.fightNameBUID,
                fighterAName: currentFightInfo.fighterA,
                fighterBName: currentFightInfo.fighterB,
              }}
              authUser={props.authUser}
            />
          </Col>

          <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
            <TableMenu />
          </Col>
          {props.currentTableMenu === "Moment" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Moment}
              />
            </Col>
          ) : props.currentTableMenu === "Offense" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Offense}
              />
            </Col>
          ) : props.currentTableMenu === "Defense" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Defense}
              />
            </Col>
          ) : props.currentTableMenu === "Body" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Body}
              />
            </Col>
          ) : props.currentTableMenu === "Ring" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Ring}
              />
            </Col>
          ) : props.currentTableMenu === "Misc" ? (
            <Col span={24}>
              <TableData
                currentColumn={props.currentTableMenu}
                columnData={columnData.Misc}
              />
            </Col>
          ) : null}
        </Row>
      )}
      <KeyboardEventHandler
        handleKeys={["s", "f", "g"]}
        onKeyEvent={(key, e) => {
          e.preventDefault();
          if (key === "s") {
            if (props.currentRowData.length - 1 > props.currentRowIndex) {
              console.log(props.currentRowIndex);
              props.onSetCurrentRowIndex(props.currentRowIndex + 1);
            } else {
              console.log(props.CurrentRowIndex);
              message.warning("You have reached the end of the list");
            }
          } else if (key === "f") {
            if (props.currentRowIndex >= 1) {
              console.log(props.currentRowIndex);
              props.onSetCurrentRowIndex(props.currentRowIndex - 1);
            } else {
              message.warning("You have reached the beginning of the list");
            }
          } else if (key === "g") {
            if (props.currentRowData.length > 0) {
              editRow(props.currentRowData[props.currentRowIndex].key);
            } else {
              doSomething();
            }
          }
        }}
      />
    </Content>
  );
};

const condition = (authUser) =>
  authUser &&
  (!!authUser.roles[ROLES.ADMIN] ||
    !!authUser.roles[ROLES.DATALEVEL1] ||
    authUser.roles[ROLES.DATALEVEL2]);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  currentFightUID: state.currentFightReducer.currentFightID,
  currentFighter: state.actionState.fighter,
  listOfFights: state.fightState.fights,
  currentRound: state.actionState.round ? state.actionState.round : "",
  currentVideoTime: state.currentTimeState.currentTime
    ? state.currentTimeState.currentTime
    : 0,
  currentMoment: state.actionState.moment ? state.actionState.moment : "",
  currentRoundStart: state.currentFightReducer.currentRoundStart
    ? state.currentFightReducer.currentRoundStart
    : "",
  currentStyleOfFight: state.currentFightReducer.currentFullFight
    ? state.currentFightReducer.currentFullFight.styleOfFight
    : "",
  currentRoundMomentStart: state.currentFightReducer.currentRoundMomentStart
    ? state.currentFightReducer.currentRoundMomentStart
    : [],
  currentAction: state.actionState,
  currentTableMenu: state.currentFightReducer.currentTableMenu
    ? state.currentFightReducer.currentTableMenu
    : [],
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
  // currentAction: state.actionState,
  // list,
});

const mapDispatchToProps = (dispatch) => ({
  onSetFight: (data) => dispatch({ type: "FULL_FIGHT_SET", data }),
  onSetRoundStart: (data) => dispatch({ type: "ROUND_START_SET", data }),
  onSetMomentRoundStart: (data) =>
    dispatch({ type: "MOMENTS_ ROUND_START_SET", data }),

  onSetCurrentRowIndex: (data) =>
    dispatch({ type: "CURRENT_ROW_INDEX_SET", data }),
});

export default compose(
  withFirebase,
  // withEmailVerification,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(Collection);
