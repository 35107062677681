import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { defense } from "../../actions";
import * as ROLES from "../../../constants/roles";

const DefenseList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState([]);

  useEffect(() => {
    props.defense(selectedButton);
    console.log(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentDefense) {
      setClicked(true);
      setSelectedButton(props.currentDefense);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton([], setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      if (props.currentRowData[props.currentRowIndex]?.defense) {
        console.log(
          props.currentRowData[props.currentRowIndex]?.defense.split(","),
          "here"
        );
        setSelectedButton(
          props.currentRowData[props.currentRowIndex]?.defense.split(", "),
          setClicked(true)
        );
      } else {
        setSelectedButton("", setClicked(false));
      }
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    const currentButton = event.target.innerText;
    if (!clicked) {
      setClicked(true);
      setSelectedButton([...selectedButton, currentButton]);
    } else {
      //   setClicked(false);
      if (selectedButton.includes(currentButton)) {
        setSelectedButton(
          selectedButton.filter((item) => item !== currentButton)
        );
      } else {
        setSelectedButton([...selectedButton, currentButton]);
      }
    }
  };

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      {!!props.authUser.roles[ROLES.ADMIN] ? (
        <>
          {" "}
          <Col span={12}>
            <ButtonClicked
              value="Block One-Forward"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Block Both-Forward"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Block Left-Side"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Block Right-Side"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Parry Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Parry Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Roll"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Step Back"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Push"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Bobbing"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Slip Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Slip Back"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Slip Forward"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Slip Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Slip Lower Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Slip Lower Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Body Move"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Counter"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Clinching"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="No Defense"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
        </>
      ) : (
        <>
          <Col span={12}>
            <ButtonClicked
              value="Body Move"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Slip"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Push"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Roll"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Parry"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Block"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Step Back"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Clinching"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="No Defense"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
          <Col span={12}>
            <ButtonClicked
              value="Unsure"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>
        </>
      )}{" "}
    </Row>
  );
};

const ButtonClicked = (props) => {
  return (
    <Button
      style={
        props.clickedState && props.selected.includes(props.value)
          ? Style.Clicked
          : Style.Reg
      }
      onClick={props.onClicked}
    >
      {props.value}
    </Button>
  );
};

const Style = {
  Clicked: {
    width: 100 + `%`,
    borderColor: "#a8071a",
    color: "#a8071a",
  },
  Reg: {
    width: 90 + `%`,
  },
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});
const mapDispatchToProps = (dispatch) => {
  return {
    defense: (data) => dispatch(defense(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefenseList);
