import React, { useState, useEffect } from "react";
import * as ROLES from "../../../constants/roles";

import { Button } from "antd";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { body } from "../../actions";

const BodyList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState([]);

  useEffect(() => {
    props.body(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentBody) {
      setClicked(true);
      setSelectedButton(props.currentBody);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton([], setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      if (props.currentRowData[props.currentRowIndex]?.body) {
        setSelectedButton(
          props.currentRowData[props.currentRowIndex]?.body,
          setClicked(true)
        );
      } else {
        setSelectedButton("", setClicked(false));
      }
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    const currentButton = event.target.innerText;
    if (!clicked) {
      setClicked(true);
      setSelectedButton([...selectedButton, currentButton]);
    } else {
      //   setClicked(false);
      if (selectedButton.includes(currentButton)) {
        setSelectedButton(
          selectedButton.filter((item) => item !== currentButton)
        );
      } else {
        setSelectedButton([...selectedButton, currentButton]);
      }

      //   if (selectedButton === event.target.innerText) {
      //     // setSelectedButton("");
      //   } else {
      //     setSelectedButton([...selectedButton, event.target.innerText]);
      //   }
    }
  };

  return (
    <>
      {props.authUser.roles[ROLES.ADMIN] ||
      props.authUser.roles[ROLES.DATALEVEL2] ? (
        <Row justify="space-between" gutter={[8, 8]}>
          <Col span={12}>
            <ButtonClicked
              value="Forehead"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Temple Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Temple Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Eye/CheekBone Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Eye/CheekBone Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Jaw Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Jaw Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Mouth"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Neck Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Neck Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Neck Front"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Back"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Left Side"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Shoulder Right Side"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Chest Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Chest Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Bicep Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Bicep Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Forearm Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Forearm Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Stomach Upper"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Stomach Lower"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Rib Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Rib Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Hip Left"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Hip Right"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="BTB"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
        </Row>
      ) : (
        <Row justify="space-between" gutter={[8, 8]}>
          <Col span={12}>
            <ButtonClicked
              value="Head"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              value="Body"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
        </Row>
      )}
    </>
  );
};

const ButtonClicked = (props) => {
  return (
    <Button
      style={
        props.clickedState && props.selected.includes(props.value)
          ? Style.Clicked
          : Style.Reg
      }
      onClick={props.onClicked}
    >
      {props.value}
    </Button>
  );
};

const Style = {
  Clicked: {
    width: 100 + `%`,
    borderColor: "#a8071a",
    color: "#a8071a",
  },
  Reg: {
    width: 90 + `%`,
  },
};

const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    body: (data) => dispatch(body(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BodyList);
