const INITIAL_STATE = {};

const applySetFrameBackwardCount = (state, action) => ({
  ...state,
  frameBackwardCount: action.data,
});

function frameBackwardCountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FRAME_BACKWARD_COUNT_SET": {
      return applySetFrameBackwardCount(state, action);
    }

    default:
      return state;
  }
}
export default frameBackwardCountReducer;
