import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";

function ScoreCardButtonSave(props) {
  const [endStatusLS, setEndStatusLS] = useState(false);
  const [scoredBeforeLS, setScoredBeforeLS] = useState(false);

  useEffect(() => {
    setEndStatusLS(props.endStatus);
  }, [props.currentRound, props.endStatus]);

  return (
    <Button onClick={props.handleSaveRound}>
      {props.currentRound === 1000 && !endStatusLS
        ? "Complete your Score card"
        : props.currentRound > 0 && !endStatusLS
        ? ` Score Round ${props.currentRound}`
        : endStatusLS
        ? "Submit Score"
        : ""}
    </Button>
  );
}

const mapStateToProps = (state, props) => ({
  currentRound: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.currentRound
    : 0,
  endStatus: state.roundScore ? state.roundScore.endStatus : false,
});

export default connect(mapStateToProps, null)(ScoreCardButtonSave);
