import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Input, Button, Form, Row, Col, message } from "antd";
import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        message.success("Password Changed");
      })
      .catch((error) => {
        this.setState({ error });
        message.success("Password Change Unsuccessful");
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <Form onFinish={this.onSubmit}>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={8}>
            <p style={{ textAlign: "center" }}>Change Password</p>{" "}
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18}>
            <Input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password"
            />
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col span={this.props.width > 768 ? 8 : 18} align="center">
            <Button disabled={isInvalid} htmlType="submit">
              Reset My Password
            </Button>
          </Col>
        </Row>

        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});
export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(PasswordChangeForm);
