import React from "react";

function OffenseManual() {
  return (
    <>
      <h4>General Data</h4>
      <p>
        <span style={Style.span}> A Punch</span> - A punch is only considered a
        punch if the logical aim of it was to hit the fighter and or if the
        Punch has a logical chance of hitting the other boxer
      </p>
      <p>
        <span style={Style.span}>Not a Punch</span> - a fight throwing a punch
        towards the other fighter but the other fighter is to far away to be
        conceivable reached by said punch
      </p>
      <h4>Hand Data</h4>
      <p>
        {" "}
        <span style={Style.span}>Leadhand</span> - Is the hand of the fighter
        that is in front. If the fighter is in an Orthodox stance then the left
        Hand would be the Leadhand, conversely if the fighter was in a Southpaw
        stance then the right hand is the Leadhand
      </p>
      <p>
        {" "}
        <span style={Style.span}>Rearhand</span> - Is the hand of the fighter
        that is in the back. If the fighter is in an Orthodox stance then the
        right Hand would be the Rearhand, conversely if the fighter was in a
        Southpaw stance then the left hand is the Rearhand
      </p>
      <h4>Punch Data</h4>
      <div>
        <p>
          <span style={Style.span}>Jab</span> - Is a straight punch that happens
          with the Leadhand
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=YVcEkTX-ISU&t=3s"
            target="_blank"
          >
            Jab Example 1
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/shorts/EOVFCtb0aMg"
            target="_blank"
          >
            {" "}
            Jab Example 2
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=3A6JSISuhe8"
            target="_blank"
          >
            {" "}
            Jab Example 3(Starting from 0:00)
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Cross</span> - Is a straight punch that
          happens with the Rearhand
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=RfsuIEJzRac"
            target="_blank"
          >
            Cross(Orthodox) Example 1
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=OuB_TzVz3EU"
            target="_blank"
          >
            {" "}
            Cross(Southpaw) Example 2(at 0:03, 0:17 and 0:24 )
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=7IvHE4-BS5E"
            target="_blank"
          >
            {" "}
            Cross Example 3
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Hook</span> - Is a punch that follows a
          hooking motion with either hand
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/shorts/X-SKEUU7SJ0"
            target="_blank"
          >
            Hook(Leadhand) Example 1
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/shorts/MtFU6coECE0"
            target="_blank"
          >
            {" "}
            Hook(Rearhand) Example 2(Last Punch thrown)
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=3owZ8LD7H7A"
            target="_blank"
          >
            {" "}
            Hook Examples 3
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Uppercut</span> - is a punch thrown by either
          Hand that travels along a vertical line at the opponent's chin or
          solar plexus usually
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/shorts/FtdNEOy3vcU"
            target="_blank"
          >
            Uppercut(Leadhand) Example 1
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/shorts/jAulsBwapDw"
            target="_blank"
          >
            {" "}
            Uppercut(Rearhand) Example 2(Last Punch thrown)
          </a>
          ,{" "}
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=M49Ho4AJfmc"
            target="_blank"
          >
            {" "}
            Uppercut Examples 3
          </a>
        </p>
      </div>
      <div>
        <p>
          <span style={Style.span}>Overhand</span> - is a punch thrown by either
          Hand in a semi circular motion above the boxers hand or gloves
          typically.
        </p>
        <p>
          <a
            style={Style.videoLink}
            href="https://www.youtube.com/watch?v=rpur8kV2Dn8"
            target="_blank"
          >
            Overhand Examples
          </a>
        </p>
      </div>
      <p>
        <span style={Style.span}>UCP (Unidentified Connected Punch)</span> - is
        a punch that connects but you are unable to identify which punch is
        falls under
      </p>
      <h4>Qualifier Data</h4>
      <p>
        <span style={Style.span}>Land</span> - a punch that lands squarely on
        the opponents body without making contact with the opponent's gloves
      </p>
      <p>
        <span style={Style.span}>Partial Land</span> - a punch that lands on the
        opponents body without making contact with the opponent's gloves but
        doesn't make a strong impact. I.e. grazing the opponent
      </p>
      <p>
        <span style={Style.span}>Miss</span> - a punch that makes no contact
        with the opponents body or gloves
      </p>
      <p>
        Gloved Block - a punch that only makes contact with the opponents gloves
      </p>
      <p>
        <span style={Style.span}>PGB (Partial Gloved Block)</span> - a punch
        that makes contact with the opponents gloves and also other parts of the
        opponent
      </p>
      <p>
        <span style={Style.span}>Unsure</span> - a punch that does not fit in
        any of the categories described above
      </p>
      <p>
        <span style={Style.span}>Orthodox</span> - is a stance whereby the
        fighters left Leg are forward
      </p>
      <p>
        <span style={Style.span}>Southpaw</span> - is a stance whereby the
        fighters right Leg are forward
      </p>
      <p>
        <span style={Style.span}>Square</span> - is a stance whereby both
        fighter's left and right leg are at the same level or distance from the
        opponent. That is to say neither fighter's leg is clearly closest to
        their opponent. Usually Only hooks, uppercuts and overhand punches can
        be thrown from this stance
      </p>
    </>
  );
}
const Style = {
  span: {
    fontWeight: "bold",
    color: "#E8B339",
  },
  videoLink: {
    fontStyle: "italic",
  },
};

export default OffenseManual;
