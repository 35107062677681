import React, { useState, useEffect } from "react";
import { Layout, Row, Col } from "antd";

import TotalPunchChart from "../DataViz/TotalPunchChart";
import TinyColChat from "../DataViz/TinyColChat";
import ProgressCol from "../DataViz/ProgressCol";
import ColumnViz from "../DataViz/ColumnViz";
import BodyViz from "../DataViz/BodyViz";
import RingViz from "../DataViz/RingViz";
import useViewport from "../../useViewPort";
function DataViz() {
  const { Content } = Layout;
  const { width } = useViewport();
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [laptopView, setLaptopView] = useState(false);
  const mobileBreakpoint = 769;
  const tabletBreakpoint = 1025;
  const laptopBreakpoint = 1705;
  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [width]);
  return (
    <Content style={style.content}>
      <Row justify="center">
        <Col span="24">
          <Row justify="space-between" gutter={[32, 32]}>
            <Col span={mobileView || tabletView ? "24" : "8"}>
              <div style={style.chartArea}>
                <TinyColChat />
              </div>
            </Col>
            <Col span={mobileView || tabletView ? "24" : "8"}>
              <div style={style.chartArea}>
                <TotalPunchChart />{" "}
              </div>
            </Col>
            <Col span={mobileView || tabletView ? "24" : "8"}>
              <div style={style.chartArea}>
                <ProgressCol />
              </div>
            </Col>

            <Col span="24">
              <div style={style.chartArea}>
                <ColumnViz />
              </div>
            </Col>
            <Col span={mobileView || tabletView ? "24" : "12"}>
              <div style={style.chartArea}>
                <BodyViz />
              </div>
            </Col>
            <Col span={mobileView || tabletView ? "24" : "12"}>
              <div style={style.chartArea}>
                <RingViz />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
}

const style = {
  content: {
    padding: "5%",
  },
  chartArea: {
    backgroundColor: "#1f1f1f",
    borderRadius: "8px",
    height: "100%",
    padding: "4%",
  },
};
export default DataViz;
