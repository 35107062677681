import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_WEB_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.analytics();

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty roles

            if (!dbUser?.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** Storage API ***
  fightsImagesUpload = (file) =>
    this.storage.ref(`/fights/${file.name}`).put(file);

  fightsImages = (file) => this.storage.ref(`/fights/${file.name}`);

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  userScore = (uid, fightID) =>
    this.db.doc(`users/${uid}/fightsScored/${fightID}`);

  userSavedScore = (uid, fightID) =>
    this.db.doc(`users/${uid}/fightsSavedScored/${fightID}`);

  finalScores = (fightID, userId) =>
    this.db.doc(`fights/${fightID}/FinalScores/${userId}`);

  avgScore = (fightID) => this.db.collection(`fights/${fightID}/FinalScores`);

  // *** Message API ***

  message = (uid) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection("messages");

  // *** Fighter API ***

  fighter = (uid) => this.db.doc(`fighters/${uid}`);

  fighters = () => this.db.collection("fighters");

  // *** Fight API ***
  fight = (uid) => this.db.doc(`fights/${uid}`);

  fights = () => this.db.collection("fights");

  // *** Fight Data API ***
  fightData = (fightID, fighterID) =>
    this.db.doc(`fights/${fightID}`).collection(`${fighterID}`);

  fightDatum = (fightID, fighterID, datumID) =>
    this.db.doc(`fights/${fightID}/${fighterID}/${datumID}`);

  // *** Moments Data API ***

  moment = (fightID, uid) => this.db.doc(`fights/${fightID}/moments/${uid}`);

  moments = (fightID) => this.db.doc(`fights/${fightID}`).collection("moments");

  refSearch = (reference) => this.db.doc(reference);
  // *** WeightClass API *** (Coming soon)

  // *** Playlist API ***
  playlist = (uid) => this.db.doc(`playlists/${uid}`);

  playlists = () => this.db.collection("playlists");
}

export default Firebase;
