import React from "react";

import FightItem from "./FightItem";

const FightList = ({ fights, onCollectFightData, key, authUser }) => (
  <ul>
    {fights.map((fight) => (
      <FightItem
        key={key}
        fight={fight}
        onCollectFightData={onCollectFightData}
        authUser={authUser}
      />
    ))}
  </ul>
);

export default FightList;
