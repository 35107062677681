import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as ROLES from "../../constants/roles";
import moment from "moment";
import { withAuthorization, withEmailVerification } from "../Session";
import {
  Input,
  Button,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  message,
} from "antd";
import VideoApp from "../Collection/VideoApp";

import { withFirebase } from "../Firebase";
import Uploader from "./Uploader";
class FightEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fighter: [],
      fight: {
        fighterA: "",
        fighterB: "",
        styleOfFight: "",
        date: "",
        weightClass: "",
        videoURL: "",
        id: "",
      },
      uid: "",
      listofFights: [],
    };
  }

  componentDidMount() {
    this.onListenForMessages();
    const currentEdit = this.props.listOfFights.filter(
      (fight) => fight.uid === this.props.currentFightUID
    );

    this.setState(
      (prevState) => ({
        fight: {
          ...this.state.fight,
          fighterA: currentEdit[0].fighterA,
          fighterB: currentEdit[0].fighterB,
          fightNameAUID: currentEdit[0].fightNameAUID,
          fightNameBUID: currentEdit[0].fightNameBUID,
          styleOfFight: currentEdit[0].styleOfFight,
          date: currentEdit[0].date,

          videoURL: currentEdit[0].videoURL,
          weightClass: currentEdit[0].weightClass,
        },
        uid: currentEdit[0].uid,
      }),
      () => {
     
      }
    );
  }
  onListenForMessages = () => {
    this.unsubscribe = this.props.firebase.fighters().onSnapshot((snapshot) => {
      let fighters = [];
      snapshot.forEach((doc) => fighters.push({ ...doc.data(), uid: doc.id }));

      this.setState({ fighter: fighters });
    });
  };
  formSubmit = () => {
    const url = this.props.imageURL;
    const INITIAL_VALUES = {
      fighter: [],
      fight: {
        fighterA: "",
        fighterB: "",
        styleOfFight: "",
        date: "",
        weightClass: "",
        videoURL: "",
        imageURL: "",
      },
    };

    this.props.firebase
      .fight(this.props.currentFightUID)
      .update({
        fightImageURL: url,
        editedAt: this.props.firebase.fieldValue.serverTimestamp(),
      })
      .then(() => {
        message.success("Fight was Added", 1);
        this.props.onSetFightURL("");
        this.setState({
          fight: {
            ...this.state.fight,
            fighterA: "",
            fighterB: "",
            styleOfFight: "",
            date: "",
            weightClass: "",
            videoURL: "",
          },
        });
      })
      .catch((e) => {
 
      });
  };

  clearState = (init) => {
    this.setState({ init });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }
  componentDidUpdate() {
  
  }

  render() {
    const { Option } = Select;
    const { fighter, fight, listofFights } = this.state;
    const dateFormat = "YYYY-MM-DD";
    return (
      <>
        <h1 style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
          Add a Fight
        </h1>
        <Form onFinish={this.formSubmit} name="fightAdd">
          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Select
                showSearch
                showArrow={false}
                value={fight.fighterA}
                style={{ width: 100 + `%` }}
                onChange={(e, i) => {
                  this.setState({
                    fight: {
                      ...fight,
                      fighterA: e,
                      fightNameAUID: i.key,
                    },
                  });
            
                }}
              >
                <Option key={fight.fightNameAUID} value={fight.fighterA}>
                  {fight.fighterA}
                </Option>

                {fighter.size !== 0
                  ? fighter.map((fg) => (
                      <Option key={fg.uid} value={fg.fighterName}>
                        {fg.fighterName}
                      </Option>
                    ))
                  : null}
              </Select>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Select
                showSearch
                showArrow={false}
                defaultValue={this.state.fight.fighterB}
                value={fight.fighterB}
                style={{ width: 100 + `%` }}
                onChange={(e, i) =>
                  this.setState({
                    fight: {
                      ...fight,
                      fighterB: e,
                      fightNameBUID: i.key,
                    },
                  })
                }
              >
                <Option key={fight.fightNameAUID} value={fight.fighterB}>
                  {fight.fighterB}
                </Option>
                {fighter.size !== 0
                  ? fighter.map((fg) => (
                      <Option key={fg.uid} value={fg.fighterName}>
                        {fg.fighterName}
                      </Option>
                    ))
                  : null}
              </Select>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Select
                defaultValue={this.state.fight.styleOfFight}
                value={this.state.fight.styleOfFight}
                style={{ width: 100 + `%` }}
                onChange={(e) =>
                  this.setState({ fight: { ...fight, styleOfFight: e } })
                }
              >
                <Option value="">Style of Fight</Option>
                <Option value="Boxing">Boxing</Option>
                <Option value="MMA">MMA</Option>
                <Option value="WomenBoxing">Women Boxing</Option>
              </Select>
            </Col>
          </Row>

          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={fight.date}
                placeholder={fight.date}
                onChange={(date, dateString) =>
                  this.setState({ fight: { ...fight, date: dateString } })
                }
                style={{ width: 100 + `%` }}
              />
            </Col>
          </Row>

          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Select
                defaultValue={this.state.fight.weightClass}
                value={this.state.fight.weightClass}
                style={{ width: 100 + `%` }}
                onChange={(e) =>
                  this.setState({ fight: { ...fight, weightClass: e } })
                }
              >
                <Option value="">Style of Weight Class</Option>
                <Option value="HW">Heavyweight</Option>
                <Option value="CW">Cruiserweight</Option>
                <Option value="LHW"> Light Heavyweight</Option>
                <Option value="SMW">Super Middleweight</Option>
                <Option value="MW"> Middleweight</Option>
                <Option value="WW">Welterweight</Option>
                <Option value="SLW">Super Lightweight</Option>
                <Option value="LW">LightWeight</Option>
                <Option value="SFW">Super Featherweight</Option>
                <Option value="FW">Featherweight</Option>
                <Option value="SBW">Super Bantamweight</Option>
                <Option value="BW">Bantamweight</Option>
                <Option value="SFLW">Super flyweight</Option>
                <Option value="FLW">Flyweight</Option>
                <Option value="LFLW">Light Flyweight</Option>
                <Option value="MNW">Minimumweight</Option>
                <Option value="AW">Atomweight</Option>
              </Select>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Input
                name="videoURL"
                type="text"
                value={this.state.fight.videoURL}
                placeholder="Video URL"
                onChange={(e) =>
                  this.setState({
                    fight: { ...fight, videoURL: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={8} align="center">
              <Input
                name="views"
                type="text"
                value={this.state.fight.views}
                placeholder="Views"
                onChange={(e) =>
                  this.setState({
                    fight: { ...fight, views: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 35 }}>
            <Col span={8} align="center">
              <h4>Upload Image</h4>
              <Uploader />
            </Col>
          </Row>

          <Row justify="center" gutter={[16, 16]}>
            {" "}
            <Col span={8} align="center">
              <Button htmlType="submit">Add Fighter</Button>{" "}
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const mapDispatchToProps = (dispatch) => ({
  onSetFightURL: (data) => dispatch({ type: "CURRENT_FIGHT_URL", data }),
});

const mapStateToProps = (state) => ({
  currentFightUID: state.currentFightReducer.currentFightID,
  listOfFights: state.fightState.fights,
  imageURL: state.currentFightReducer.currentFightURL,
  // list,
});
export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(FightEdit);
