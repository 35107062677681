import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Input, Button, Form } from "antd";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import MessageList from "./MessageList";

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.messages.length) {
      this.setState({ loading: true });
    }
    this.onListenForMessages();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy("createdAt", "desc")
      .limit(this.props.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach((doc) =>
            messages.push({ ...doc.data(), uid: doc.id })
          );

          this.props.onSetMessages(messages);
          this.setState({ loading: false });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().add({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ text: "" });

    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).update({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.message(uid).delete();
  };

  onNextPage = () => {
    this.props.onSetMessagesLimit(this.props.limit + 5);
  };

  componentDidUpdate(props) {
    if (props.limit !== this.props.limit) {
      this.onListenForMessages();
    }
  }

  render() {
    const { messages, authUser } = this.props;
    const { text, loading } = this.state;

    return (
      <div>
        {!loading && messages && (
          <Button type="Button" onClick={this.onNextPage}>
            More
          </Button>
        )}

        {loading && <div>Loading ...</div>}

        {messages && (
          <MessageList
            authUser={authUser}
            messages={messages}
            onEditMessage={this.onEditMessage}
            onRemoveMessage={this.onRemoveMessage}
          />
        )}

        {!messages && <div>There are no messages ...</div>}

        <Form
          onFinish={(event) => this.onCreateMessage(event, this.props.authUser)}
        >
          <Input type="text" value={text} onChange={this.onChangeText} />
          <Button htmlType="submit">Send</Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  messages: Object.keys(state.messageState.messages || {}).map((key) => ({
    ...state.messageState.messages[key],
    uid: state.messageState.messages[key].uid,
  })),
  limit: state.messageState.limit,
});

const mapDispatchToProps = (dispatch) => ({
  onSetMessages: (messages) => dispatch({ type: "MESSAGES_SET", messages }),
  onSetMessagesLimit: (limit) =>
    dispatch({ type: "MESSAGES_LIMIT_SET", limit }),
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Messages);
