import React, { useEffect, useState, useLayoutEffect } from "react";
import emailjs from "emailjs-com";
import { Row, Col, Button, Spin, Result, message } from "antd";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import featureCap from "../../assets/img/featurecap.png";
import { LoadingOutlined } from "@ant-design/icons";
import useViewport from "../../useViewPort";
function ContactUsForm() {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [formBtnHover, setFormBtnHover] = useState(false);
  const [messageCloseStatus, setMessageCloseStatus] = useState(true);
  const [formValues, setFormValues] = useState({
    from_name: "",
    user_email: "",
  });
  const [formSent, setFormSent] = useState(false);
  const [formResult, setFormResult] = useState("");
  const { width } = useViewport();
  const mobileBreakpoint = 769;
  const tabletBreakpoint = 1025;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [width]);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 36, color: "#ad8b00" }} spin />
  );

  const onChange = (name, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const messageClose = () => {
    setMessageCloseStatus(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formValues.from_name === "" || formValues.user_email === "") {
      if (messageCloseStatus) {
        setMessageCloseStatus(false);
        message.error("Enter your name and email", 2, messageClose);
      }
    } else {
      setFormSent(true);
      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          formValues,
          process.env.REACT_APP_EMAIL_USER_ID
        )
        .then(
          (result) => {
            setFormSent(false);

            console.log(result.text);
            setFormResult("success");
          },
          (error) => {
            setFormSent(false);
            setFormResult("error");

            console.log(error.text);
          }
        );
    }
  };
  return (
    <>
      {mobileView || tabletView ? (
        <Row justify="center" align="middle" style={style.formRowMobile}>
          <Col span="24">
            <img src={featureCap} style={style.featureImg} alt="" />
          </Col>
          {formSent ? (
            <Col span="8" style={style.resultCol}>
              {" "}
              <Spin indicator={antIcon} />
            </Col>
          ) : formResult === "success" ? (
            <Col span="24" style={{ marginBottom: 100 + "px" }}>
              {" "}
              <Result
                status="success"
                title="Successfully Submitted"
                subTitle={`Thank you${
                  formValues.from_name !== ""
                    ? " " + formValues.from_name + ","
                    : ","
                } a confirmation message has been sent to your email.`}
              />
            </Col>
          ) : formResult === "error" ? (
            <Col span="24" style={{ marginBottom: 100 + "px" }}>
              {" "}
              <Result
                status={formResult}
                title="Something went wrong"
                subTitle="Please try again or send us an Email at hello@Roundscore.tv"
                extra={
                  width < 320
                    ? [
                        <Row justify="center" gutter={[8, 8]}>
                          <Col span="12">
                            <Button
                              key="try"
                              onClick={() => {
                                setFormResult("");
                              }}
                            >
                              Try again
                            </Button>
                          </Col>
                          <Col span="12">
                            <Button
                              key="email"
                              href="mailto:hello@Roundscore.tv"
                            >
                              Send Email
                            </Button>
                          </Col>
                        </Row>,
                      ]
                    : [
                        <Button
                          key="try"
                          onClick={() => {
                            setFormResult("");
                          }}
                        >
                          Try again
                        </Button>,
                        <Button key="email" href="mailto:hello@Roundscore.tv">
                          Send Email
                        </Button>,
                      ]
                }
              />
            </Col>
          ) : (
            <Col span="24" style={{ marginBottom: 100 + "px" }}>
              <h2
                className="h1Gradient"
                style={
                  mobileView
                    ? style.h2FormMobile
                    : tabletView
                    ? style.h2FormTablet
                    : style.h2Form
                }
              >
                Join us
              </h2>{" "}
              <p
                style={{
                  fontSize: 18 + "px",
                  color: "#fff",
                  marginTop: "-10px",
                  marginBottom: 80 + "px",
                  textAlign: "center",
                }}
              >
                {/* A seamless way to watch fight film */}
                Use Roundscore, win your next fight.
              </p>
              <Row justify="center">
                <Col
                  span={mobileView ? "20" : "18"}
                  style={{ marginBottom: 20 + "px" }}
                >
                  <p style={{ color: "#595959", fontSize: "16px" }}>Name</p>
                  <input
                    onChange={(e) => {
                      onChange(e.target.name, e.target.value);
                    }}
                    type="text"
                    style={style.inputContactMobile}
                    placeholder="Your first name"
                    name="from_name"
                  />
                </Col>

                <Col
                  span={mobileView ? "20" : "18"}
                  style={{ marginBottom: 40 + "px" }}
                >
                  <p style={{ color: "#595959", fontSize: "16px" }}>Email</p>
                  <input
                    onChange={(e) => {
                      onChange(e.target.name, e.target.value);
                    }}
                    type="email"
                    style={style.inputContactMobile}
                    placeholder="Your email"
                    name="user_email"
                  />
                </Col>
                <Col span={width < 320 ? 20 : 12}>
                  <Button
                    style={
                      formBtnHover
                        ? style.buttonAccessFormHoverMobile
                        : style.buttonAccessFormMobile
                    }
                    onMouseEnter={() => {
                      setFormBtnHover(true);
                    }}
                    onMouseLeave={() => {
                      setFormBtnHover(false);
                    }}
                    onClick={onSubmit}
                  >
                    Join us
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "50px" }} justify="center">
                <Col span="16" style={{ textAlign: "center" }}>
                  <p>
                    By clicking{" "}
                    <span style={{ fontStyle: "italic" }}>"Join us"</span> you
                    are agree to the{" "}
                    <span
                      style={{
                        color: "#fff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                        {" "}
                        <a href="#">Terms of Service</a>
                      </Link>
                    </span>{" "}
                    and{" "}
                    <span
                      style={{
                        color: "#fff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={ROUTES.PRIVACY_POLICY}>
                        <a href="#">Privacy Policy</a>{" "}
                      </Link>
                    </span>
                    . We ocassionally send account related emails
                  </p>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      ) : (
        <Row
          justify="center"
          align="middle"
          style={style.formRow}
          gutter={[8, 8]}
        >
          {formSent ? (
            <Col span="8" style={style.resultCol}>
              {" "}
              <Spin indicator={antIcon} />
            </Col>
          ) : formResult === "success" ? (
            <Col span="8" style={{ marginBottom: 100 + "px" }}>
              {" "}
              <Result
                status="success"
                title="Successfully Submitted"
                subTitle={`Thank you${
                  formValues.from_name !== ""
                    ? " " + formValues.from_name + ","
                    : ","
                } a confirmation message has been sent to your email.`}
              />
            </Col>
          ) : formResult === "error" ? (
            <Col span="8" style={{ marginBottom: 100 + "px" }}>
              {" "}
              <Result
                status={formResult}
                title="Something went wrong"
                subTitle="Please try again or send us an Email at hello@Roundscore.tv"
                extra={[
                  <Button
                    key="try"
                    onClick={() => {
                      setFormResult("");
                    }}
                  >
                    Try again
                  </Button>,
                  <Button key="email" href="mailto:hello@Roundscore.tv">
                    Send Email
                  </Button>,
                ]}
              />
            </Col>
          ) : (
            <Col span="8" style={{ marginBottom: 100 + "px" }}>
              <h2 className="h1Gradient" style={style.h2Form}>
                Join us
              </h2>{" "}
              <p
                style={{
                  fontSize: 20 + "px",
                  color: "#fff",
                  marginTop: "-10px",
                  marginBottom: 80 + "px",
                }}
              >
                The best way to watch fight film
                {/* Use Roundscore, win your next fight. */}
                {/* Win before you enter the ring! */}
              </p>
              <Row justify="center">
                <Col span="24" style={{ marginBottom: 20 + "px" }}>
                  <p style={{ color: "#595959", fontSize: "16px" }}>Name</p>
                  <input
                    onChange={(e) => {
                      onChange(e.target.name, e.target.value);
                    }}
                    type="text"
                    style={style.inputContact}
                    placeholder="Your first name"
                    name="from_name"
                  />
                </Col>

                <Col span="24" style={{ marginBottom: 40 + "px" }}>
                  <p style={{ color: "#595959", fontSize: "16px" }}>Email</p>
                  <input
                    onChange={(e) => {
                      onChange(e.target.name, e.target.value);
                    }}
                    type="email"
                    style={style.inputContact}
                    placeholder="Your email"
                    name="user_email"
                  />
                </Col>
                <Col span="24">
                  <Button
                    style={
                      formBtnHover
                        ? style.buttonAccessFormHover
                        : style.buttonAccessForm
                    }
                    onMouseEnter={() => {
                      setFormBtnHover(true);
                    }}
                    onMouseLeave={() => {
                      setFormBtnHover(false);
                    }}
                    onClick={onSubmit}
                  >
                    Join us
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "100px" }}>
                <Col span="16">
                  <p>
                    By clicking{" "}
                    <span style={{ fontStyle: "italic" }}>"Join us"</span> you
                    are agree to the{" "}
                    <span
                      style={{
                        color: "#fff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                        {" "}
                        <a href="#">Terms of Service</a>
                      </Link>
                    </span>{" "}
                    and{" "}
                    <span
                      style={{
                        color: "#fff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={ROUTES.PRIVACY_POLICY}>
                        <a href="#">Privacy Policy</a>{" "}
                      </Link>
                    </span>
                    . We ocassionally send account related emails
                  </p>
                </Col>
              </Row>
            </Col>
          )}
          <Col span="16">
            <img src={featureCap} style={style.featureImg} alt="" />
          </Col>
        </Row>
      )}
    </>
  );
}
const style = {
  resultCol: { textAlign: "center" },
  formRow: {
    width: "100%",
    marginTop: 50 + "px",
    paddingBottom: 100 + "px",
  },
  formRowMobile: {
    width: "100%",
    // marginTop: 50 + "px",
    paddingBottom: 100 + "px",
  },
  content: {
    paddingLeft: 10 + `%`,
    paddingRight: 10 + `%`,
    marginTop: 70 + `px`,
    width: 100 + "%",
  },

  h2Form: {
    fontSize: 60 + "px",
    textAlign: "left",
    // lineHeight: 1,
    fontWeight: "bolder",
  },
  h2FormMobile: {
    fontSize: 42 + "px",
    textAlign: "center",
    fontWeight: "bolder",
  },
  h2FormTablet: {
    fontSize: 60 + "px",
    textAlign: "center",
    // lineHeight: 1,
    fontWeight: "bolder",
  },
  inputContact: {
    width: 65 + "%",
    height: 40 + "px",
    color: "#fff",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 20 + "px",
    padding: 10 + "px",
  },
  inputContactMobile: {
    width: 100 + "%",
    height: 40 + "px",
    color: "#fff",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 20 + "px",
    padding: 10 + "px",
  },

  buttonAccessForm: {
    width: 65 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonAccessFormMobile: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonAccessFormHover: {
    width: 65 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonAccessFormHoverMobile: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },

  featureImg: {
    width: 100 + "%",
  },
};
export default ContactUsForm;
