import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Layout, Row, Col, Button, Spin } from "antd";
import {
  LoadingOutlined,
  WarningOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import emailjs from "emailjs-com";
import landingScreenCap from "../../assets/img/landingScreenCap.png";
import fast from "../../assets/img/fast.svg";
import tools from "../../assets/img/tools.svg";
import strategy from "../../assets/img/strategy.svg";
import roundscoreGif from "../../assets/video/Roundscore.gif";
import useViewport from "../../useViewPort";
import Footer from "../General/Footer";
import ContactUsForm from "../General/ContactUsForm";

const DemoLanding = (props) => {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  // const [hover, setTabletView] = useState(false);

  const [mainBtnHover, setMainBtnHover] = useState(false);
  const [mainBtnHoverDEMO, setMainBtnHoverDEMO] = useState(false);

  const [formValues, setFormValues] = useState({
    user_email: "",
  });
  const [formSent, setFormSent] = useState(false);
  const [formResult, setFormResult] = useState("");
  const { Content } = Layout;

  const { width } = useViewport();
  const mobileBreakpoint = 769;
  const tabletBreakpoint = 1025;
  const laptopBreakpoint = 1705;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [width]);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 36, color: "#ad8b00" }} spin />
  );
  const onChange = (name, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const onSubmit = (e) => {
  //   setFormSent(true);
  //   e.preventDefault();
  //   emailjs
  //     .send(
  //       process.env.REACT_APP_EMAIL_SERVICE_ID,
  //       process.env.REACT_APP_EMAIL_TEMPLATE_ID,
  //       formValues,
  //       process.env.REACT_APP_EMAIL_USER_ID
  //     )
  //     .then(
  //       (result) => {
  //         setFormSent(false);

  //         console.log(result.text);
  //         setFormResult("success");
  //       },
  //       (error) => {
  //         setFormSent(false);
  //         setFormResult("error");

  //         console.log(error.text);
  //       }
  //     );
  // };

  return (
    <Content style={mobileView ? style.contentMobile : style.content}>
      <Row
        justify="center"
        style={mobileView ? style.mainRowMobile : style.mainRow}
      >
        <Col span={mobileView || tabletView ? "24" : "10"}>
          <h1
            style={
              mobileView
                ? style.h1Mobile
                : tabletView
                ? style.h1Tablet
                : style.h1
            }
            className="h1Gradient"
          >
            The video study tool for combat sports.
          </h1>
          <p
            style={
              mobileView ? style.pMobile : tabletView ? style.pTablet : style.p
            }
          >
            {/* Fight films are difficult to study. Roundscore is the fastest way to
            study and learn from fight films. Discard the unhelpful parts and
            focus on the moments you need to see. 

            Roundscore is the fastest way to study and learn from fight films.
            Get the unhelpful parts of the fight videos out of the way and focus
            on the most crucial moments and tendencies. */}
            Roundscore is the fastest way to study and learn from fight films.
            Discover the crucial moments, techniques and tendencies that matter
            most and filter out the rest.
          </p>
          {formSent ? (
            <Row style={style.formRow} justify="center">
              <Col span="12" style={{ textAlign: "center" }}>
                {" "}
                <Spin indicator={antIcon} />
              </Col>
            </Row>
          ) : formResult === "success" ? (
            <Row style={style.formRow}>
              <Col span={mobileView || tabletView ? 24 : 1}>
                <CheckOutlined
                  style={{
                    color: "#237804",
                    display: (mobileView || tabletView) && "block",
                    fontSize: mobileView
                      ? "24px"
                      : tabletView
                      ? "32px"
                      : "16px",
                  }}
                />
              </Col>
              <Col
                span={mobileView || tabletView ? 24 : 23}
                style={{
                  marginBottom: 100 + "px",
                  textAlign: (mobileView || tabletView) && "center",
                }}
              >
                <p>
                  {" "}
                  Successfully Submitted. Thank you, a confirmation message has
                  been sent to your email.
                </p>
              </Col>
            </Row>
          ) : formResult === "error" ? (
            <Row style={style.formRow}>
              <Col span={mobileView || tabletView ? 24 : 1}>
                <WarningOutlined
                  style={{
                    color: "#cf1322",
                    display: (mobileView || tabletView) && "block",
                    fontSize: mobileView
                      ? "24px"
                      : tabletView
                      ? "32px"
                      : "16px",
                  }}
                />{" "}
              </Col>
              <Col
                span={mobileView || tabletView ? 24 : 23}
                style={{
                  marginBottom: 100 + "px",
                  textAlign: (mobileView || tabletView) && "center",
                }}
              >
                {" "}
                <p>
                  Something went wrong, Please{" "}
                  <a
                    href="#d"
                    onClick={() => {
                      setFormResult("");
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    try again
                  </a>{" "}
                  or send us an Email at{" "}
                  <a
                    href="mailto:hello@Roundscore.tv"
                    style={{ textDecoration: "underline" }}
                  >
                    hello@Roundscore.tv
                  </a>
                </p>
              </Col>
            </Row>
          ) : (
            <Row
              style={
                mobileView || tabletView ? style.formRowMobile : style.formRow
              }
              justify={(mobileView || tabletView) && "center"}
              gutter={
                mobileView
                  ? [0, 16]
                  : tabletView
                  ? [0, 16]
                  : width < 1441
                  ? [0, 16]
                  : [0, 16]
              }
            >
              <Col
                span={
                  mobileView
                    ? "20"
                    : tabletView
                    ? "20"
                    : width < 1441
                    ? "24"
                    : "24"
                }
              >
                {mobileView || tabletView ? (
                  <Button
                    style={
                      mainBtnHoverDEMO
                        ? style.mainButtonHoverMobileDEMO
                        : style.buttonMobileDEMO
                    }
                    onMouseEnter={() => {
                      setMainBtnHoverDEMO(true);
                    }}
                    onMouseLeave={() => {
                      setMainBtnHoverDEMO(false);
                    }}
                  >
                    <Link to={ROUTES.DEMO}>Try our Demo</Link>
                  </Button>
                ) : (
                  <Button
                    style={
                      mainBtnHoverDEMO
                        ? style.mainButtonHoverDEMO
                        : style.buttonDEMO
                    }
                    onMouseEnter={() => {
                      setMainBtnHoverDEMO(true);
                    }}
                    onMouseLeave={() => {
                      setMainBtnHoverDEMO(false);
                    }}
                  >
                    <Link to={ROUTES.DEMO}>Try our Demo</Link>
                  </Button>
                )}
              </Col>
              <Col
                span={
                  mobileView
                    ? "20"
                    : tabletView
                    ? "20"
                    : width < 1441
                    ? "24"
                    : "24"
                }
              >
                {mobileView || tabletView ? (
                  <Button
                    style={
                      mainBtnHover
                        ? style.mainButtonHoverMobile
                        : style.buttonMobile
                    }
                    onMouseEnter={() => {
                      setMainBtnHover(true);
                    }}
                    onMouseLeave={() => {
                      setMainBtnHover(false);
                    }}
                    // onClick={onSubmit}
                  >
                    <Link to={ROUTES.CONTACT_US}> Join us</Link>
                  </Button>
                ) : (
                  <Button
                    style={mainBtnHover ? style.mainButtonHover : style.button}
                    onMouseEnter={() => {
                      setMainBtnHover(true);
                    }}
                    onMouseLeave={() => {
                      setMainBtnHover(false);
                    }}
                    // onClick={onSubmit}
                  >
                    <Link to={ROUTES.CONTACT_US}> Join us</Link>
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Col>
        <Col pan={mobileView || tabletView ? "24" : "10"} style={style.imgCol}>
          <img
            src={roundscoreGif}
            alt=""
            style={
              mobileView || tabletView
                ? style.landingImgMobile
                : style.landingImg
            }
          />
        </Col>
      </Row>
      <Row
        justify="center"
        style={mobileView ? style.featureRowMobile : style.featureRow}
      >
        <Col span="24">
          <h2 style={mobileView ? style.h2Mobile : style.h2}>
            Built for fights
          </h2>
        </Col>

        <Col span="24" style={style.featureBodyCol}>
          <p
            style={{
              fontSize: mobileView ? 18 + "px" : 20 + "px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {/* Clear, easy to use tools make watching fight clips and full fights
            quicker than ever, so you can develop your game. */}
            See body shots, headshots, punching combinations, clinch throws,
            submissions, and many more.{" "}
            {!(mobileView || tabletView) &&
              "Simplify the work of fight analysis while improving your understanding from fight film study."}
          </p>

          {mobileView ? (
            <Row justify="center">
              {" "}
              <Col
                span="24"
                style={{
                  marginTop: 5 + "%",
                }}
              >
                {" "}
                <img
                  src={landingScreenCap}
                  style={style.featureImgMobile}
                  alt=""
                />{" "}
              </Col>
              {/* <Col span="24">
                <Row justify="center">
                  <Col span="12">
                    {" "}
                    <Button
                      style={
                        mainBtnHoverDEMO
                          ? style.mainButtonHoverDEMO
                          : style.buttonDEMO
                      }
                      onMouseEnter={() => {
                        setMainBtnHoverDEMO(true);
                      }}
                      onMouseLeave={() => {
                        setMainBtnHoverDEMO(false);
                      }}
                    >
                      <Link to={ROUTES.DEMO}>Try our Demo</Link>
                    </Button>
                  </Col>
                </Row>
              </Col> */}
              <Col span="20" style={style.featureMobile}>
                <img src={fast} alt="" style={style.featureBodyImg} />
                <h3 style={style.h3}>Faster film study</h3>
                <p style={style.featureP}>
                  You don't have to watch the whole fight to get insights. Skip
                  to the key frames that matter to your fight strategy.
                </p>
              </Col>
              <Col span="20" style={style.featureMobile}>
                <img src={strategy} alt="" style={style.featureBodyImg} />
                <h3 style={style.h3}>Better fight strategies</h3>
                <p style={style.featureP}>
                  A powerful and easy-to-use search tool to find the moments
                  that matter to you i.e. How does your opponent defend against
                  the jab?
                </p>
              </Col>
              <Col span="20" style={style.featureMobile}>
                <img src={tools} alt="" style={style.featureBodyImg} />
                <h3 style={style.h3}>More efficient tools</h3>
                <p style={style.featureP}>
                  See the moments in a fight that truly matter, and find new
                  ways to beat them. Replay those fights in Slow Motion or frame
                  by frame.
                </p>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              {" "}
              <Col
                span="24"
                style={{
                  marginTop: tabletView && 5 + "%",
                }}
              >
                {" "}
                <img
                  src={landingScreenCap}
                  style={style.featureImg}
                  alt=""
                />{" "}
              </Col>
              {/* <Col span="24">
                <Row justify="center">
                  <Col span="12">
                    {" "}
                    <Button
                      style={
                        mainBtnHoverDEMO
                          ? style.mainButtonHoverDEMO
                          : style.buttonDEMO
                      }
                      onMouseEnter={() => {
                        setMainBtnHoverDEMO(true);
                      }}
                      onMouseLeave={() => {
                        setMainBtnHoverDEMO(false);
                      }}
                    >
                      <Link to={ROUTES.DEMO}>Try our Demo</Link>
                    </Button>
                  </Col>
                </Row>
              </Col> */}
              <Col span="24" style={style.featureIconCol}>
                <Row justify="space-between" align="middle">
                  <Col span="8">
                    <img src={fast} alt="" style={style.featureBodyImg} />
                  </Col>
                  <Col span="8">
                    <img src={strategy} alt="" style={style.featureBodyImg} />
                  </Col>
                  <Col span="8">
                    <img src={tools} alt="" style={style.featureBodyImg} />
                  </Col>
                </Row>
              </Col>
              <Col span="24">
                <Row justify="space-between" align="middle">
                  <Col span="8" style={style.featureListRow}>
                    <h3 style={tabletView ? style.h3Tablet : style.h3}>
                      Faster film study
                    </h3>
                    <p
                      style={tabletView ? style.featurePTablet : style.featureP}
                    >
                      You don't have to watch the whole fight to get insights.
                      Skip to the key frames that matter to your fight strategy.
                    </p>
                  </Col>
                  <Col span="8" style={style.featureListRow}>
                    <h3 style={tabletView ? style.h3Tablet : style.h3}>
                      Better fight strategies
                    </h3>
                    <p
                      style={tabletView ? style.featurePTablet : style.featureP}
                    >
                      How does your opponent defend against the jab? Your
                      opponents' habits, tendencies and missteps are held firmly
                      to the light.
                    </p>{" "}
                  </Col>
                  <Col span="8" style={style.featureListRow}>
                    <h3 style={tabletView ? style.h3Tablet : style.h3}>
                      More efficient tools
                    </h3>
                    <p
                      style={tabletView ? style.featurePTablet : style.featureP}
                    >
                      See the moments in a fight that truly matter, and find new
                      ways to beat them. Replay those fights in Slow Motion or
                      frame by frame.
                    </p>
                  </Col>
                </Row>
              </Col>{" "}
            </Row>
          )}
        </Col>
      </Row>

      <ContactUsForm />
      <Footer />
    </Content>
  );
};

const style = {
  content: {
    paddingLeft: 5 + `%`,
    paddingRight: 5 + `%`,
    marginTop: 70 + `px`,
    width: 100 + "%",
  },
  contentMobile: {
    paddingLeft: 5 + `%`,
    paddingRight: 5 + `%`,
    marginTop: 70 + `px`,
    width: 100 + "%",
  },
  formRow: {
    width: "100%",
    marginTop: 50 + "px",
    paddingBottom: 100 + "px",
  },
  formRowMobile: {
    width: "100%",
    marginTop: 50 + "px",
    paddingBottom: 50 + "px",
  },

  mainRow: { width: 100 + "%", marginBottom: 10 + "%" },
  mainRowMobile: { width: 100 + "%" },
  h1: {
    fontSize: 90 + "px",
    lineHeight: 1.2,
  },
  h1Mobile: {
    fontSize: 42 + "px",
    textAlign: "center",
  },
  h1Tablet: {
    fontSize: 75 + "px",
    textAlign: "center",
  },
  h2: { fontSize: 75 + "px", textAlign: "center", color: "#fff" },
  h2Mobile: { fontSize: 35 + "px", textAlign: "center", color: "#fff" },
  h2Form: {
    fontSize: 60 + "px",
    textAlign: "left",
    // lineHeight: 1,
    fontWeight: "bolder",
  },

  h3: { fontSize: 28 + "px", textAlign: "left", color: "#fff" },
  h3Tablet: { fontSize: 24 + "px", textAlign: "left", color: "#fff" },
  p: {
    color: "#d9d9d9",
    fontSize: 24 + "px",
  },
  pMobile: { color: "#d9d9d9", fontSize: 18 + "px", textAlign: "center" },
  pTablet: { color: "#d9d9d9", fontSize: 20 + "px", textAlign: "center" },
  input: {
    width: 95 + "%",
    height: 50 + "px",
    color: "#d9d9d9",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 28 + "px",
    padding: 10 + "px",
  },
  inputMobile: {
    width: 100 + "%",
    height: 50 + "px",
    color: "#d9d9d9",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 20 + "px",
    padding: 8 + "px",
  },
  inputTablet: {
    width: 95 + "%",
    height: 50 + "px",
    color: "#d9d9d9",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 24 + "px",
    padding: 8 + "px",
  },
  inputContact: {
    width: 65 + "%",
    height: 40 + "px",
    color: "#fff",
    backgroundColor: "#262626",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 20 + "px",
    padding: 10 + "px",
  },
  button: {
    width: 50 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonDEMO: {
    width: 50 + "%",
    height: 50 + "px",
    backgroundColor: "#fadb14",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonMobile: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonMobileDEMO: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#fadb14",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },

  mainButtonHover: {
    width: 50 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  mainButtonHoverDEMO: {
    width: 50 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  mainButtonHoverMobile: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  mainButtonHoverMobileDEMO: {
    width: 100 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonAccessForm: {
    width: 65 + "%",
    height: 50 + "px",
    backgroundColor: "#1d39c4",
    color: "#fff",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  buttonAccessFormHover: {
    width: 65 + "%",
    height: 50 + "px",
    backgroundColor: "#fff",
    color: "#141414",
    borderColor: "transparent",
    borderRadius: "8px",
    fontSize: 16 + "px",
    cursor: "pointer",
    fontWeight: "bolder",
  },
  landingImg: {
    width: 100 + "%",
    marginTop: "20%",
  },
  landingImgMobile: {
    width: 100 + "%",
    // marginTop: "10%",
  },
  featureImg: {
    width: 100 + "%",
  },
  featureImgMobile: {
    width: 100 + "%",
    marginBottom: 10 + "%",
  },
  imgCol: {
    paddingLeft: 3 + `%`,
    paddingRight: 3 + `%`,
  },
  imgColMobile: {
    paddingLeft: 3 + `%`,
    paddingRight: 3 + `%`,
    paddingBottom: 3 + `%`,
  },
  featureRow: { width: 100 + "%" },
  featureRowMobile: { width: 100 + "%", marginTop: 20 + "%" },
  featureListRow: { paddingRight: 20 + `px` },

  featureBodyCol: {
    marginBottom: 50 + "px",
  },
  featureBodyImg: { width: 50 + "px" },
  featureIconCol: {
    marginBottom: 30 + "px",
  },
  featureMobile: {
    marginBottom: 10 + "%",
  },
  featureP: {
    fontSize: 20 + "px",
  },
  featurePTablet: {
    fontSize: 18 + "px",
  },
};

export default DemoLanding;
