import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { Row, Col, Button, Typography, message } from "antd";
import { PlayCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import ScoreResult from "./ScoreResult";
import TallyScore from "./TallyScore";

import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

const { TabPane } = Tabs;

function ScoreCards(props) {
  const [fightRounds, setFightRounds] = useState([]);
  const [hashtags, setHashtags] = useState([]);

  const [scoreRound, setScoreRound] = useState({});
  const [saveRoundScore, setSaveRoundScore] = useState(true);

  const [currentRound, setCurrentRound] = useState(0);

  const [scoreF1, setScoreF1] = useState(9);

  const [scoreF2, setScoreF2] = useState(9);

  const [activeKey, setActiveKey] = useState(1);

  const [activeAreaStatusF1Min, setActiveAreaStatusF1Min] = useState(false);
  const [activeAreaStatusF1Plus, setActiveAreaStatusF1Plus] = useState(false);
  const [activeAreaStatusF2Min, setActiveAreaStatusF2Min] = useState(false);
  const [activeAreaStatusF2Plus, setActiveAreaStatusF2Plus] = useState(false);

  const { Title } = Typography;

  const callback = (key) => {
    console.log("hfhfhf");
    setSaveRoundScore(false);
    var numberKey = parseInt(key);
    setCurrentRound(numberKey);
  };
  useEffect(() => {
    if (props.currentFightInfo.fighterA && props.currentFightInfo.fighterB) {
      const fighterA = props.currentFightInfo.fighterA.replace(/\s/g, "");

      const fighterB = props.currentFightInfo.fighterB.replace(/\s/g, "");

      setHashtags([
        "boxing",
        "robberies",
        "boxingrobberies",
        fighterA,
        fighterB,
      ]);
    }
  }, [props.currentFightInfo.fighterA, props.currentFightInfo.fighterB]);

  useEffect(() => {
    setFightRounds(props.start[0]);
  }, [props.start[0]]);

  useEffect(() => {
    if (props.userScores[`Round ${currentRound}`]) {
      if (saveRoundScore) {
        console.log("here 1");

        props.onSetScoreRound({
          ...scoreRound,
          [`Round ${currentRound - 1}`]: {
            F1EA: scoreF1,
            F2EA: scoreF2,
          },
          currentRound: currentRound,
          start: true,
        });
        props.onSetStartStatus(true);
        props.onSetCurrentRound(currentRound);
        setScoreRound({
          ...scoreRound,
          [`Round ${currentRound - 1}`]: {
            F1EA: scoreF1,
            F2EA: scoreF2,
          },
        });
      } else {
        console.log("heree22");
        setScoreF1(props.userScores[`Round ${currentRound}`].F1EA);
        setScoreF2(props.userScores[`Round ${currentRound}`].F2EA);

        props.onSetScoreRound({
          ...scoreRound,
          currentRound: currentRound,
          start: true,
        });
        props.onSetStartStatus(true);
        props.onSetCurrentRound(currentRound);
      }
    } else {
      if (saveRoundScore) {
        if (props.currentRound > 0) {
          console.log("here 1222");
          props.onSetScoreRound({
            ...scoreRound,
            [`Round ${currentRound - 1}`]: {
              F1EA: scoreF1,
              F2EA: scoreF2,
            },
            currentRound: currentRound,
            start: true,
          });
          props.onSetStartStatus(true);
          props.onSetCurrentRound(currentRound);
          setScoreRound({
            ...scoreRound,
            [`Round ${currentRound - 1}`]: {
              F1EA: scoreF1,
              F2EA: scoreF2,
            },
          });
        }
      } else {
        console.log("here 14t5sry5eyr");

        if (currentRound > 0) {
          props.onSetScoreRound({
            ...scoreRound,
            currentRound: currentRound,
            start: true,
          });
          props.onSetStartStatus(true);
          props.onSetCurrentRound(currentRound);
        }

        reset();
      }
    }
  }, [currentRound]);

  useEffect(() => {
    if (currentRound > 0) {
      console.log("Stat 1");
      if (props.startStatus) {
        setSaveRoundScore(false);
        console.log("Stat 2");
        setCurrentRound(props.currentRound);
      } else {
        setSaveRoundScore(true);
        setCurrentRound(props.currentRound);
        console.log("Stat 3");
      }
    } else {
      setSaveRoundScore(false);
      console.log("Stat 4");
      setCurrentRound(props.currentRound);
    }
  }, [props.currentRound]);

  useEffect(() => {
    console.log(scoreRound);
    reset();
  }, [scoreRound]);

  useEffect(() => {
    props.onSetCurrentScores({ F1: scoreF1, F2: scoreF2 });
  }, [scoreF1, scoreF2]);

  const reset = () => {
    setScoreF1(9);
    setScoreF2(9);
  };
  useEffect(() => {
    if (props.scoredBefore === true) {
      setCurrentRound(2000);
    } else {
      setFightRounds(
        props.moments.filter((moment) => moment.action === "Start")
      );
      setCurrentRound(props.currentRound);
      setScoreRound(props.userScores);
    }
  }, [props.scoredBefore]);

  const scoreCardPlay = () => {
    if (props.width <= 991) {
      props.onClose();
    }
    var currentScoreCardPlay = props.moments.filter(
      (moment) => moment.round === props.currentRound
    );
    if (currentScoreCardPlay.length > 0) {
      props.onSetMomentPlay(currentScoreCardPlay[0].videoTime);
    }
    console.log(currentScoreCardPlay);
  };
  return (
    <Tabs
      activeKey={props.currentRD ? props.currentRD.toString() : "0"}
      onChange={callback}
      style={{
        paddingLeft: 5 + `%`,
        paddingRight: 5 + `%`,
        color: "#f0f0f0",
      }}
    >
      <TabPane tab={`Start`} key={0} disabled={props.currentRound > 0}>
        <Row justify="center" className="cAlign">
          <Col className="mbottom10">
            <Button
              onClick={() => {
                // setCurrentRound(1);

                console.log(currentRound);

                props.onSetScoreRound({
                  ...scoreRound,
                  currentRound: 1,
                  start: true,
                });
                props.onSetStartStatus(true);
                props.onSetCurrentRound(1);
              }}
              style={{ color: "#fff", borderColor: "#fff", marginBottom: 20 }}
            >
              Start Scoring
            </Button>
            <Title level={2}>Rules to Scoring</Title>
            <p>You cannot score a round a tie</p>
            <p>
              You must award one fighter a point of 10 to indicate the winner of
              the round
            </p>

            <p>Every Round must be scored.</p>
            <p>
              To get the most accurate scores, Try your best not to go back to
              change your scores.
            </p>
            <p>
              Use the "Play Round" button to play from the start of the current
              round
            </p>
            <p>Have Fun and Enjoy the fight!</p>
          </Col>
        </Row>
      </TabPane>
      {fightRounds.length > 0
        ? fightRounds.map((rounds, i) => (
            <TabPane
              tab={`Round ` + rounds.round}
              key={rounds.round}
              disabled={
                (props.startStatus && !props.endStatus) ||
                !props.startStatus ||
                props.endStatus
              }
            >
              <Row justify="center" style={{ marginBottom: 20 }}>
                {" "}
                {props.currentRound > 0 && props.currentRound !== 1000 ? (
                  <Button onClick={scoreCardPlay}>
                    Play Round <PlayCircleOutlined />
                  </Button>
                ) : null}
              </Row>

              <Row
                className="tabPanes"
                gutter={[32, 0]}
                justify="space-between"
              >
                <Col span={24}>
                  <Row justify="start">
                    {scoreF1 > 9 || scoreF2 > 9 ? (
                      <CheckCircleOutlined
                        style={{
                          color: scoreF2 > 9 ? "#177ddc" : "#e8b339",
                        }}
                      />
                    ) : (
                      <CheckCircleOutlined
                        style={{
                          color: "#595959",
                        }}
                      />
                    )}
                  </Row>

                  <Row justify="center">
                    <p className="roundCardHeader">
                      {" "}
                      <span>
                        {scoreF1 > 9 || scoreF2 > 9
                          ? scoreF2 > 9
                            ? props.currentFightInfo.fighterB.split(" ")[
                                props.currentFightInfo.fighterB.split(" ")
                                  .length - 1
                              ] +
                              ` - Round ` +
                              props.currentRound
                            : props.currentFightInfo.fighterA.split(" ")[
                                props.currentFightInfo.fighterA.split(" ")
                                  .length - 1
                              ] +
                              ` - Round ` +
                              props.currentRound
                          : "Tie"}
                      </span>
                    </p>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center">
                    <Col
                      style={{
                        // backgroundColor: "#d32029",
                        backgroundColor: "#E8B339",
                        maxWidth: 100 + `%`,
                        marginBottom: 10,
                        borderRadius: 4 + `px`,
                      }}
                      span={24}
                    >
                      {" "}
                      <Row justify="center">
                        <Col>
                          <Title
                            style={{
                              fontSize: 56,
                            }}
                            level={4}
                          >
                            {scoreF1}
                          </Title>{" "}
                        </Col>
                        <Col span={24}>
                          <Title
                            level={4}
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {
                              props.currentFightInfo.fighterA.split(" ")[
                                props.currentFightInfo.fighterA.split(" ")
                                  .length - 1
                              ]
                            }
                          </Title>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row
                    justify="center"
                    style={{ width: 100 + `%` }}
                    gutter={[8, 8]}
                  >
                    <Col span={10} align="center">
                      <Button
                        style={{
                          borderColor: activeAreaStatusF1Min ? "#e8b339" : null,
                          color: activeAreaStatusF1Min ? "#e8b339" : null,
                        }}
                        onMouseEnter={() => {
                          setActiveAreaStatusF1Min(true);
                        }}
                        onMouseLeave={() => {
                          setActiveAreaStatusF1Min(false);
                        }}
                        onClick={() => {
                          setActiveKey(() => {
                            return activeKey > fightRounds.length
                              ? activeKey + 1
                              : 1;
                          });
                          setScoreF1(scoreF1 > 7 ? scoreF1 - 1 : scoreF1);
                        }}
                      >
                        -
                      </Button>
                    </Col>
                    <Col span={10} align="center">
                      <Button
                        style={{
                          borderColor: activeAreaStatusF1Plus
                            ? "#e8b339"
                            : null,
                          color: activeAreaStatusF1Plus ? "#e8b339" : null,
                        }}
                        onMouseEnter={() => {
                          setActiveAreaStatusF1Plus(true);
                        }}
                        onMouseLeave={() => {
                          setActiveAreaStatusF1Plus(false);
                        }}
                        onClick={() => {
                          setScoreF1(scoreF1 < 10 ? scoreF1 + 1 : scoreF1);
                        }}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row justify="center">
                    <Col
                      span={24}
                      style={{
                        backgroundColor: "#177ddc",

                        marginBottom: 10,
                        borderRadius: 4 + `px`,
                      }}
                    >
                      <Row justify="center">
                        <Col>
                          <Title
                            style={{
                              fontSize: 56,
                            }}
                            level={4}
                          >
                            {scoreF2}
                          </Title>{" "}
                        </Col>
                        <Col span={24}>
                          <Title
                            level={4}
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {
                              props.currentFightInfo.fighterB.split(" ")[
                                props.currentFightInfo.fighterB.split(" ")
                                  .length - 1
                              ]
                            }
                          </Title>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row
                    justify="center"
                    style={{ width: 100 + `%` }}
                    gutter={[8, 8]}
                  >
                    <Col span={10} align="center">
                      <Button
                        style={{
                          borderColor: activeAreaStatusF2Min ? "#1890ff" : null,
                          color: activeAreaStatusF2Min ? "#1890ff" : null,
                        }}
                        onMouseEnter={() => {
                          setActiveAreaStatusF2Min(true);
                        }}
                        onMouseLeave={() => {
                          setActiveAreaStatusF2Min(false);
                        }}
                        onClick={() => {
                          setActiveKey(() => {
                            return activeKey > fightRounds.length
                              ? activeKey + 1
                              : 1;
                          });
                          setScoreF2(scoreF2 > 7 ? scoreF2 - 1 : scoreF2);
                        }}
                      >
                        -
                      </Button>
                    </Col>

                    <Col span={10} align="center">
                      <Button
                        style={{
                          borderColor: activeAreaStatusF2Plus
                            ? "#1890ff"
                            : null,
                          color: activeAreaStatusF2Plus ? "#1890ff" : null,
                        }}
                        onMouseEnter={() => {
                          setActiveAreaStatusF2Plus(true);
                        }}
                        onMouseLeave={() => {
                          setActiveAreaStatusF2Plus(false);
                        }}
                        onFocus={() => {
                          setActiveAreaStatusF2Plus(true);
                        }}
                        onBlur={() => {
                          setActiveAreaStatusF2Plus(false);
                        }}
                        onClick={() => {
                          setScoreF2(scoreF2 < 10 ? scoreF2 + 1 : scoreF2);
                        }}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          ))
        : null}
      <TabPane tab={`Score Summary`} key={1000} disabled={!props.endStatus}>
        <Row justify="center">
          <ScoreResult
            fighterA={props.currentFightInfo.fighterA}
            fighterB={props.currentFightInfo.fighterB}
            scoreRound={scoreRound}
            start={props.start}
          />
        </Row>
      </TabPane>
      <TabPane tab={`Your Score`} key={2000} disabled={!props.endStatus}>
        <div className="mbottom2">
          <Title level={3} className="cAlign ">
            Your Last Score
          </Title>
        </div>
        <Row justify="space-between">
          <Col>
            <p className="cAlign">
              {props.currentFightInfo.fighterA
                ? props.currentFightInfo.fighterA.split(" ")[
                    props.currentFightInfo.fighterA.split(" ").length - 1
                  ]
                : ""}
            </p>
            {props.recent ? (
              <TallyScore
                start={props.start}
                recentTally={props.recent.currentTally.F1}
                mostRecentScore={true}
                fighter={"A"}
              />
            ) : (
              "nothing"
            )}{" "}
          </Col>

          <div className="">
            <p className="cAlign">
              {props.currentFightInfo.fighterB
                ? props.currentFightInfo.fighterB.split(" ")[
                    props.currentFightInfo.fighterB.split(" ").length - 1
                  ]
                : ""}
            </p>
            {props.recent ? (
              <TallyScore
                start={props.start}
                recentTally={props.recent.currentTally.F2}
                mostRecentScore={true}
                fighter={"B"}
              />
            ) : null}
          </div>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ flexDirection: "column" }}
        >
          {/* <Button>Share Score</Button> */}
          <Col style={{ marginBottom: 15 }}>
            {props.currentFightInfo.fighterA &&
            props.currentFightInfo.fighterB ? (
              <TwitterShareButton
                title={`I Score the fight the ${
                  props.currentFightInfo.fighterA
                } - ${props.recent ? props.recent.currentTally.F1 : null} ${
                  props.currentFightInfo.fighterB
                } - ${props.recent ? props.recent.currentTally.F2 : null}`}
                url={`https://roundscore.netlify.app/#/landing/${props.currentFightID}`}
                hashtags={hashtags}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            ) : null}

            <FacebookShareButton
              url={`https://roundscore.netlify.app/#/landing/${props.currentFightID}`}
              quote={`I Score the fight the ${
                props.currentFightInfo.fighterA
              } - ${props.recent ? props.recent.currentTally.F1 : null} ${
                props.currentFightInfo.fighterB
              } - ${props.recent ? props.recent.currentTally.F2 : null}`}
              hashtag={"#boxing"}
              style={{ marginLeft: 15, marginRight: 15 }}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <RedditShareButton
              title={`I Score the fight the ${
                props.currentFightInfo.fighterA
              } - ${props.recent ? props.recent.currentTally.F1 : null} ${
                props.currentFightInfo.fighterB
              } - ${props.recent ? props.recent.currentTally.F2 : null}`}
              url={`https://roundscore.netlify.app/#/landing/${props.currentFightID}`}
              windowWidth={660}
              windowHeight={460}
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </Col>

          <Col>
            <Button onClick={props.changeScoredBefore}>Score Again</Button>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
}

const mapStateToProps = (state, props) => ({
  currentMoment: state.currentUserPages.currentMomentPlay
    ? state.currentUserPages.currentMomentPlay
    : 0,
  userScores: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound
    : {},
  currentRound: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.currentRound
    : 0,

  startStatus: state.roundScore.currentScoreRound
    ? state.roundScore.currentScoreRound.start
    : false,
  endStatus: state.roundScore ? state.roundScore.endStatus : false,
  orientation: state.orientationReducer.orientation.screenOrientation,
  width: state.viewPortReducer.viewPort,
});

const mapDispatchToProps = (dispatch) => ({
  onSetScoreRound: (data) =>
    dispatch({ type: "CURRENT_SCORE_ROUND_SET", data }),
  onSetCurrentScores: (data) => dispatch({ type: "CURRENT_SCORE_SET", data }),
  onSetCurrentRound: (data) =>
    dispatch({ type: "CURRENT_SCORE_CURROUND_SET", data }),
  onSetStartStatus: (data) => dispatch({ type: "START_STATUS_SET", data }),
  onSetMomentPlay: (data) =>
    dispatch({ type: "CURRENT_MOMENT_PLAY_SET", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCards);
