import React from "react";
import { Row, Col, Divider, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { TinyColumn } from "@ant-design/charts";
function TinyColChat() {
  var data = [274, 337, 81, 497, 666, 219, 269];
  var config = {
    height: 60,
    autoFit: false,
    data: data,
    // tooltip: false,
    annotations: [
      {
        type: "line",
        start: ["min", "mean"],
        end: ["max", "mean"],
        text: {
          content: "平均值",
          offsetY: -2,
          style: {
            textAlign: "left",
            fontSize: 10,
            fill: "rgba(44, 53, 66, 0.45)",
            textBaseline: "bottom",
          },
        },
        style: { stroke: "rgba(0, 0, 0, 0.25)" },
      },
    ],
  };
  return (
    <>
      {" "}
      <Row justify="space-between" align="center">
        <Col span="12">
          <p>Total Hooks</p>
        </Col>
        <Col span="12">
          <Row justify="end">
            <Col>
              <Tooltip
                title="The total number of punches Saul Alvarez threw in the fight"
                trigger={["click"]}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        </Col>{" "}
      </Row>
      <Row justify="start" align="middle">
        <Col span="12">
          <p style={style.headerNumber}>430</p>
        </Col>
        <Col span="12">
          <Row justify="space-between">
            <Col span="12">
              {" "}
              <p style={{ fontSize: "16px" }}>
                Land{" "}
                <span style={{ fontSize: "14px", color: "white" }}>
                  45% <CaretDownOutlined style={{ color: "#cf1322" }} />
                </span>
              </p>
            </Col>
            <Col span="12">
              <p style={{ fontSize: "16px" }}>
                Miss{" "}
                <span style={{ fontSize: "14px", color: "white" }}>
                  {" "}
                  55% <CaretUpOutlined style={{ color: "#389e0d" }} />
                </span>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <TinyColumn {...config} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <p style={{ fontSize: "16px" }}>
            Avg. Jab per round{" "}
            <span style={{ fontSize: "14px", color: "white" }}>35.7</span>
          </p>
        </Col>
      </Row>
    </>
  );
}
const style = {
  headerNumber: { color: "#fff", fontSize: "42px" },
};

export default TinyColChat;
