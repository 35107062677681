import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Input, Button, Form, Row, Col, message, Avatar } from "antd";
import { withFirebase } from "../Firebase";
import DisplayPic from "../../assets/img/Gold.png";
import { CameraOutlined } from "@ant-design/icons";
function AccountSetting(props) {
  const [imgUploadHover, setImgUploadHover] = useState(false);
  const onSubmit = () => {};
  return (
    <Form onFinish={onSubmit}>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={props.width > 768 ? 8 : 18}>
          <Avatar
            src={imgUploadHover ? null : DisplayPic}
            icon={imgUploadHover ? <CameraOutlined /> : null}
            size={64}
            style={{
              border: 1 + `px` + ` Solid #ACACAC`,
              cursor: "pointer",
            }}
            onMouseEnter={() => {
              setImgUploadHover(true);
            }}
            onMouseLeave={() => {
              setImgUploadHover(false);
            }}
          />
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={props.width > 768 ? 8 : 18}>
          <Input
            name="firstName"
            // value={firstName}
            // onChange={onChange}
            type="text"
            placeholder="First Name"
          />
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col span={props.width > 768 ? 8 : 18}>
          <Input
            name="lasttName"
            // value={firstName}
            // onChange={onChange}
            type="text"
            placeholder="Last Name"
          />
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]}>
        <Col span={props.width > 768 ? 8 : 18} align="center">
          <Button htmlType="submit">Save Profile</Button>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state, props) => ({
  width: state.viewPortReducer.viewPort,
  orientation: state.orientationReducer.orientation.screenOrientation,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, null)
)(AccountSetting);
