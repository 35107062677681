import React, { useState, useEffect } from "react";
import * as ROLES from "../../constants/roles";

import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Layout, Row, Col, Divider, Select } from "antd";
import HandList from "./ButtonGroups/HandList";
import PunchList from "./ButtonGroups/PunchList";
import StatusList from "./ButtonGroups/StatusList";
import RangeList from "./ButtonGroups/RangeList";
import StanceList from "./ButtonGroups/StanceList";
import LevelList from "./ButtonGroups/LevelList";
import RingList from "./ButtonGroups/RingList";
import DefenseList from "./ButtonGroups/DefenseList";
import BodyList from "./ButtonGroups/BodyList";
import MiscList from "./ButtonGroups/MiscList";
import FighterList from "./ButtonGroups/FighterList";
import RoundList from "./ButtonGroups/RoundList";
import MomentList from "./ButtonGroups/MomentList";
import Ring from "./ButtonGroups/Ring";
import Body from "./ButtonGroups/Body";

const { Content } = Layout;
const ButtonList = (props) => {
  const [LoadRing, setLoadRing] = useState(false);
  const [currentView, setCurrentView] = useState(props.currentTableMenu);
  const { Option } = Select;

  function handleChange(value) {
    // setCurrentView(value);
    props.onSetTableMenu(value);
  }
  useEffect(() => {
    setCurrentView(props.currentTableMenu);
  }, [props.currentTableMenu]);
  return (
    <Content
      style={{
        overflowY: "scroll",
        height: 68 + `vh`,
        paddingLeft: 5 + `%`,
        paddingRight: 5 + `%`,
      }}
      onScroll={(e) =>
        console.log(
          e.target.offsetTop - e.target.scrollTop + e.target.clientTop
        )
      }
      id="ringScrollPos"
    >
      <>
        <Row justify="center">
          <Select
            value={currentView}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            {!!props.authUser.roles[ROLES.ADMIN] && (
              <Option value="Moment">Moment</Option>
            )}
            <Option value="Offense">Offense</Option>{" "}
            <Option value="Defense">Defense</Option>
            <Option value="Ring">Ring</Option>
            <Option value="Body">Body</Option>
            <Option value="Misc">Misc</Option>
          </Select>
        </Row>
        {currentView === "Offense" || currentView === "Moment" ? (
          <Row
            gutter={[16, 16]}
            justify="center"
            style={{ marginBottom: "30px" }}
          >
            {/* <Divider>Ring</Divider>
          <a onClick={() => setLoadRing(!LoadRing)}>Here</a>
          {LoadRing ? (
            <Col style={{ margin: "0 auto" }}>
              <Ring />
            </Col>
          ) : null} */}
            <Divider>General Information</Divider>
            <Col span={12}>
              <FighterList
                fighterInfo={{
                  fighterAUID: props.fighterInfo.fighterAUID,
                  fighterBUID: props.fighterInfo.fighterBUID,
                  fighterAName: props.fighterInfo.fighterAName,
                  fighterBName: props.fighterInfo.fighterBName,
                }}
                defValue={
                  props.currentFighter === props.fighterInfo.fighterAUID ||
                  props.currentFighter === props.fighterInfo.fighterBUID
                    ? props.currentFighter
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <RoundList />
            </Col>
            {!!props.authUser.roles[ROLES.ADMIN] && (
              <>
                <Divider>Moments Data</Divider>
                <Col span={24}>
                  <MomentList currentMoment={props.moment} />
                </Col>
              </>
            )}
            <Divider>Hand Data</Divider>
            <Col span={24}>
              <HandList currentHand={props.hand} />
            </Col>
            <Divider>Punch Data</Divider>
            <Col span={24}>
              <Row justify="space-between" gutter={[8, 8]}>
                <PunchList currentPunch={props.punch} />
              </Row>
            </Col>
            <Divider>Qualifier Data</Divider>
            <Col span={24}>
              <StatusList currentStatus={props.status} />
            </Col>
            {!!props.authUser.roles[ROLES.ADMIN] && (
              <>
                <Col span={24}>
                  <RangeList currentRange={props.range} />
                </Col>
                <Col span={24}>
                  <LevelList currentLevel={props.level} />
                </Col>
              </>
            )}

            <Col span={24}>
              <StanceList currentStance={props.stance} />
            </Col>
            {/* <Divider>Body Data</Divider>
            <Col span={24}>
              <BodyList currentBody={props.body} authUser={props.authUser} />
            </Col>{" "} */}
            {/* <Divider>Defensive Data</Divider>
            <Col span={24}>
              <DefenseList currentDefense={props.defense} />
            </Col> */}
            {/* <Divider>Misc Data (Coming soon)</Divider> */}
            {/* <Col span={24}><MiscList /></Col> */}
          </Row>
        ) : currentView === "Ring" ? (
          <Row justify="center">
            <Divider>Ring Data</Divider>
            <Col span={24}>
              {/* <RingList currentLevel={props.ring} authUser={props.authUser} /> */}
              <Ring currentRing={props.ring} />
            </Col>{" "}
          </Row>
        ) : currentView === "Body" ? (
          <Row justify="center" style={{ marginBottom: "30px" }}>
            <Divider>Body Data</Divider>
            <Col span={24}>
              {" "}
              <BodyList currentBody={props.body} authUser={props.authUser} />
            </Col>
          </Row>
        ) : currentView === "Defense" ? (
          <Row justify="center" style={{ marginBottom: "30px" }}>
            <Divider>Defensive Data</Divider>
            <Col span={24}>
              <DefenseList currentDefense={props.defense} />
            </Col>
          </Row>
        ) : (
          <Row justify="center" style={{ marginBottom: "30px" }}>
            <Divider>Misc Data</Divider>
            <Col span={24}>
              <MiscList />
            </Col>
          </Row>
        )}
      </>
    </Content>
  );
};
const mapStateToProps = (state) => ({
  currentFightUID: state.currentFightReducer.currentFightID,
  currentFighter: state.actionState.fighter,
  hand: state.actionState.hand,
  punch: state.actionState.punch,
  status: state.actionState.status,
  range: state.actionState.range,
  stance: state.actionState.stance,
  level: state.actionState.level,
  ring: state.actionState.ring,
  defense: state.actionState.defense,
  body: state.actionState.body,
  moment: state.actionState.moment,
  currentTableMenu: state.currentFightReducer.currentTableMenu
    ? state.currentFightReducer.currentTableMenu
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  onSetFight: (data) => dispatch({ type: "FULL_FIGHT_SET", data }),
  onSetTableMenu: (data) => dispatch({ type: "TABLE_MENU_SET", data }),
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ButtonList);
