import React, { useState, useEffect } from "react";
import * as ROLES from "../../../constants/roles";

import { Row, Col, Button } from "antd";
import { connect } from "react-redux";
import { ring } from "../../actions";

const RingList = (props) => {
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    console.log(selectedButton);
    props.ring(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    if (props.currentLevel) {
      setClicked(true);
      setSelectedButton(props.currentLevel);
    }
  }, []);

  useEffect(() => {
    if (props.currentClear === true) {
      setSelectedButton("", setClicked(false));
    } else {
    }
  }, [props.currentClear]);

  useEffect(() => {
    if (props.currentRowData.length > 0) {
      setSelectedButton(
        props.currentRowData[props.currentRowIndex]?.ring,
        setClicked(true)
      );
    } else {
      setSelectedButton("", setClicked(false));
    }
  }, [props.currentRowData, props.currentRowIndex]);

  const onClicked = (event) => {
    if (!clicked) {
      setClicked(true);
      setSelectedButton(event.target.innerText);
    } else {
      if (selectedButton === event.target.innerText) {
        setSelectedButton("");
      } else {
        setSelectedButton(event.target.innerText);
      }
    }
  };

  return (
    <>
      {props.authUser.roles[ROLES.ADMIN] ||
      props.authUser.roles[ROLES.DATALEVEL2] ? (
        <Row justify="space-between" gutter={[8, 8]}>
          <Col span={6}>
            <ButtonClicked
              valueX="0"
              valueY="4"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="1"
              valueY="4"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="2"
              valueY="4"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="3"
              valueY="4"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={6}>
            <ButtonClicked
              valueX="4"
              valueY="4"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="0"
              valueY="3"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="1"
              valueY="3"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="2"
              valueY="3"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="3"
              valueY="3"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="4"
              valueY="3"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="0"
              valueY="2"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="1"
              valueY="2"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="2"
              valueY="2"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="3"
              valueY="2"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="4"
              valueY="2"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="0"
              valueY="1"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="1"
              valueY="1"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="2"
              valueY="1"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="3"
              valueY="1"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={5}>
            <ButtonClicked
              valueX="4"
              valueY="1"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={6}>
            <ButtonClicked
              valueX="0"
              valueY="0"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="1"
              valueY="0"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="2"
              valueY="0"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={4}>
            <ButtonClicked
              valueX="3"
              valueY="0"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={6}>
            <ButtonClicked
              valueX="4"
              valueY="0"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          {/* <Col span={12}>
      <ButtonClicked
        value="Inner Square"
        onClicked={onClicked}
        clickedState={clicked}
        selected={selectedButton}
      />
    </Col>{" "}
    <Col span={12}>
      <ButtonClicked
        value="Outer Square"
        onClicked={onClicked}
        clickedState={clicked}
        selected={selectedButton}
      />
    </Col>{" "}
    <Col span={12}>
      <ButtonClicked
        value="Ropes"
        onClicked={onClicked}
        clickedState={clicked}
        selected={selectedButton}
      />
    </Col>{" "}
    <Col span={12}>
      <ButtonClicked
        value="Corner"
        onClicked={onClicked}
        clickedState={clicked}
        selected={selectedButton}
      />
    </Col>{" "} */}
        </Row>
      ) : (
        <Row justify="space-between" gutter={[8, 8]}>
          <Col span={12}>
            <ButtonClicked
              valueX="Ring"
              valueY="Middle"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              valueX="Ring"
              valueY="Outer"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              valueX="Ring"
              valueY="Ropes"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
          <Col span={12}>
            <ButtonClicked
              valueX="Ring"
              valueY="Corner"
              onClicked={onClicked}
              clickedState={clicked}
              selected={selectedButton}
            />
          </Col>{" "}
        </Row>
      )}
    </>
  );
};

const ButtonClicked = (props) => {
  const [clicked, setClicked] = useState(false);
  return (
    <Button
      style={
        props.clickedState &&
        `${props.valueX} - ${props.valueY}` === props.selected
          ? Style.Clicked
          : Style.Reg
      }
      onClick={props.onClicked}
    >
      {props.valueX} - {props.valueY}
    </Button>
  );
};

const Style = {
  Clicked: {
    width: 100 + `%`,
    borderColor: "#a8071a",
    color: "#a8071a",
  },
  Reg: {
    width: 90 + `%`,
  },
};
const mapStateToProps = (state) => ({
  currentClear: state.currentFightReducer.currentClearSome
    ? state.currentFightReducer.currentClearSome
    : false,
  currentRowData: state.currentFightReducer.currentRowData
    ? state.currentFightReducer.currentRowData
    : [],
  currentRowIndex: state.currentFightReducer.currentRowIndex
    ? state.currentFightReducer.currentRowIndex
    : 0,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ring: (data) => dispatch(ring(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RingList);
