import React from "react";
import { Row, Col, Input, Checkbox, Menu, Dropdown } from "antd";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import CenterRingLogo from "../../assets/img/ROUNDSCORE.png";
function RingViz() {
  const optionsWithDisabled = [
    { label: "Total", value: "total" },
    { label: "Land", value: "land" },
    { label: "Miss", value: "miss" },
  ];
  const menu = (
    <Menu>
      <Menu.Item>1st menu item</Menu.Item>
      <Menu.Item>2nd menu item</Menu.Item>
      <Menu.Item>3rd menu item </Menu.Item>
    </Menu>
  );
  return (
    <>
      {/* <h4>Custom Ring Chart</h4>
                Shows how many times single punch types landed or was aimed at a
                specific part of the ring- (Customizable single punch types
                including counters, Status Checkbox [land, miss, all]) */}
      <Row justify="space-between" gutter={[8, 32]}>
        <Col span="20">
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Col span="12">
                {" "}
                <Input
                  size="large"
                  placeholder="Jabs"
                  suffix={<DownOutlined />}
                />
              </Col>
            </Dropdown>
            <Col span="12">
              <Checkbox.Group
                options={optionsWithDisabled}
                defaultValue={["Apple"]}
                // onChange={onChange}
              />
            </Col>
          </Row>
        </Col>
        <Col span="4">
          <Row justify="end">
            <Col>
              <InfoCircleOutlined />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        {/* <div class="octagonWrap">
          <div class="octagon">
            <div class="octagon-inner">
              <img src={CenterRingLogo} alt="" className="centerRingLogoData" />
            </div>
          </div>
        </div> */}
        <div className="content--container__Data">
          <div className="blue--corner__Data"></div>
          <div className="red--corner__Data"></div>
          <img src={CenterRingLogo} alt="" className="centerRingLogoData" />
          <div class="innerRingData"></div>
          <div class="green_data"></div>
          <div class="red_data"></div>
          <div class="blue_data"></div>
          <div class="yellow_data"></div>
          <div class="pink_data"></div>
        </div>
      </Row>
    </>
  );
}

export default RingViz;
