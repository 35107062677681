export const FIGHTER = "FIGHTER";
export const ROUND = "ROUND";
export const HAND = "HAND";
export const PUNCH = "PUNCH";
export const STATUS = "STATUS";
export const RANGE = "RANGE";
export const STANCE = "STANCE";
export const LEVEL = "LEVEL";
export const RING = "RING";
export const DEFENSE = "DEFENSE";
export const BODY = "BODY";
export const MOMENT = "MOMENT";
export const MISC = "MISC";
