import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Col, Row, Button, Tooltip } from "antd";
import VideoTool from "../FightTools/VideoTool";
import FilmSearch from "./FilmSearch";
import FilmSearchResult from "./FilmSearchResult";
import {
  CloseOutlined,
  SearchOutlined,
  VideoCameraAddOutlined,
  RollbackOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import useViewport from "../../useViewPort";
import landscapeIcon from "../../assets/img/toLandscape.svg";
function Demo(props) {
  const [fighterOneData, setFighterOneData] = useState([]);
  const [fighterTwoData, setFighterTwoData] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [currentView, setCurrentView] = useState("search");
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [laptopView, setLaptopView] = useState(false);

  const { width } = useViewport();

  const mobileBreakpoint = 768;
  const smallMobileLandscapeBreakpoint = 639;

  const tabletBreakpoint = 1024;
  const laptopBreakpoint = 1440;

  const frameBack = () => {
    props.onSetFrameBackwardCount(props.frameBackwardCount + 1);
  };
  const frameForward = () => {
    props.onSetFrameForwardCount(props.frameForwardCount + 1);
  };

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setMobileView(true);
      setTabletView(false);
      setLaptopView(false);
    } else if (width < tabletBreakpoint) {
      setMobileView(false);
      setTabletView(true);
      setLaptopView(false);
    } else if (width < laptopBreakpoint) {
      setMobileView(false);
      setTabletView(false);
      setLaptopView(true);
    } else {
      setMobileView(false);
      setTabletView(false);
      setLaptopView(false);
    }
  }, [width]);

  useEffect(() => {
    window.scrollTo(0, 150);
  }, [props.filmEventCount, props.comboEventCount]);

  useEffect(() => {
    props.firebase
      .fight(process.env.REACT_APP_DEMO_ID)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.data());
        props.onSetCurrentFilmStudy(querySnapshot.data());
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    props.onSetFilmSearchQueryHistory();
    props.onSetFrameForwardCount(0);
    props.onSetFrameBackwardCount(0);
  }, []);

  useEffect(() => {
    props.firebase
      .fightData(
        process.env.REACT_APP_DEMO_ID,
        props.currentFilmStudy?.fightNameAUID
      )
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let fighterOneDatum = snapshot.docs.map((doc) => ({
            key: doc.id,
            fighter: doc.data().fighter,
            punch: doc.data().punch,
            hand: doc.data().hand,
            status: doc.data().status,
            range: doc.data().range,
            level: doc.data().level,
            stance: doc.data().stance,
            round: doc.data().round,
            roundTime: doc.data().roundTime,
            videoTime: doc.data().videoTime,
            defense: doc.data().defense,
            body: doc.data().body,
            ring: doc.data().ring,
            misc: doc.data().misc,
          }));
          setFighterOneData(fighterOneDatum);
        }
      });

    props.firebase
      .fightData(
        process.env.REACT_APP_DEMO_ID,
        props.currentFilmStudy?.fightNameBUID
      )
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let fighterTwoDatum = snapshot.docs.map((doc) => ({
            key: doc.id,
            fighter: doc.data().fighter,
            punch: doc.data().punch,
            hand: doc.data().hand,
            status: doc.data().status,
            range: doc.data().range,
            level: doc.data().level,
            stance: doc.data().stance,
            round: doc.data().round,
            roundTime: doc.data().roundTime,
            videoTime: doc.data().videoTime,
            defense: doc.data().defense,
            body: doc.data().body,
            ring: doc.data().ring,
            misc: doc.data().misc,
          }));
          setFighterTwoData(fighterTwoDatum);
        }
      });
  }, [props.currentFilmStudy]);

  useEffect(() => {
    if (props.filmStudyCurrentView && props.filmStudyCurrentView !== "") {
      setCurrentView(props.filmStudyCurrentView);
    }
  }, [props.filmStudyCurrentView]);

  useEffect(() => {
    props.onSetFighterOneData(fighterOneData);
    console.log(fighterOneData);
  }, [fighterOneData]);

  useEffect(() => {
    props.onSetFighterTwoData(fighterTwoData);
    console.log(fighterTwoData);
  }, [fighterTwoData]);

  const replay = () => {
    if (
      props.searchQueryResult[1] === "Single Punch" ||
      props.searchQueryResult[1] === "Counter"
    ) {
      props.onSetFilmEventCount(props.filmEventCount + 1);
    } else {
      props.onSetComboEventCount(props.comboEventCount + 1);
    }
  };
  return (
    <>
      {mobileView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ marginTop: "30px", textAlign: "center" }}>
            {props.currentFilmStudy?.fighterA} vs{" "}
            {props.currentFilmStudy?.fighterB} - [DEMO]
          </h1>

          <div
            style={
              props.screenOrientation == "portrait"
                ? style.overlayPotrait
                : style.overlayLandscape
            }
          >
            <div className="overlayText">
              <p> Please Turn Your Device To Landscape Mode </p>
              <img src={landscapeIcon} alt="" />
            </div>
          </div>
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ width: 90 + `%`, margin: "0 auto" }}
          >
            <Col span={12}>
              <Row justify="center">
                {" "}
                <Button
                  target="_blank"
                  href="https://roundscoredata.typeform.com/to/tu2qxzk3"
                >
                  Give us your feedback
                </Button>
              </Row>
            </Col>
            <Col span="24" style={{ height: "80vh" }}>
              <VideoTool
                currentURL={props.currentFilmStudy?.videoURL}
                currentMoment={props.filmStudyMoment}
                currentComboMoment={props.comboStudyMoment}
              />
            </Col>
            <Col
              span="24"
              style={{
                height: "130vh",
                backgroundColor: "#262626",
                display: minimize ? "none" : "block",
                marginBottom: "50px",
              }}
            >
              {" "}
              <Row gutter={[0, 16]} justify="space-between">
                <Col span={width > smallMobileLandscapeBreakpoint ? "4" : "6"}>
                  <Row gutter={[8, 0]}>
                    <Col span="12">
                      <Button
                        onClick={() => {
                          setMinimize(!minimize);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                    </Col>
                    <Col span="12">
                      <Button
                        onClick={() => {
                          if (currentView === "search") {
                            setCurrentView("video");
                          } else {
                            setCurrentView("search");
                          }
                        }}
                      >
                        {currentView === "search" ? (
                          <span>
                            Result <VideoCameraOutlined />
                          </span>
                        ) : (
                          <span>
                            {" "}
                            Search <VideoCameraAddOutlined />
                          </span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={width > smallMobileLandscapeBreakpoint ? "20" : "18"}
                >
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameBack}>
                          {width > smallMobileLandscapeBreakpoint &&
                            "Step Back"}{" "}
                          <DoubleLeftOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={replay}>
                          {width > smallMobileLandscapeBreakpoint && "Replay"}{" "}
                          <RollbackOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameForward}>
                          {width > smallMobileLandscapeBreakpoint &&
                            "Step Forward"}{" "}
                          <DoubleRightOutlined />
                        </Button>{" "}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  height: "80%",
                  backgroundColor: "#141414",
                  padding: "10px",
                  overflowY: "scroll",
                }}
              >
                {currentView === "video" && <FilmSearchResult Demo={true} />}
                {currentView === "search" && (
                  <FilmSearch
                    currentFilmStudy={props.currentFilmStudy}
                    Demo={true}
                  />
                )}
              </Row>
            </Col>
            <Col
              span="24"
              style={{
                height: "55px",
                marginBottom: "50px",

                backgroundColor: "#262626",
                display: minimize ? "block" : "none",
              }}
            >
              <Row>
                <Col span="4">
                  <Button
                    onClick={() => {
                      setMinimize(!minimize);
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Col>
                <Col span="20">
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameBack}>
                          {width > smallMobileLandscapeBreakpoint &&
                            "Step Back"}{" "}
                          <DoubleLeftOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={replay}>
                          {width > smallMobileLandscapeBreakpoint && "Replay"}{" "}
                          <RollbackOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameForward}>
                          {width > smallMobileLandscapeBreakpoint &&
                            "Step Forward"}{" "}
                          <DoubleRightOutlined />
                        </Button>{" "}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : tabletView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ marginTop: "30px", textAlign: "center" }}>
            {props.currentFilmStudy?.fighterA} vs{" "}
            {props.currentFilmStudy?.fighterB} - [DEMO]
          </h1>

          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ width: 90 + `%`, margin: "0 auto" }}
          >
            <Col span={12}>
              <Row justify="center">
                {" "}
                <Button
                  target="_blank"
                  href="https://roundscoredata.typeform.com/to/tu2qxzk3"
                >
                  Give us your feedback
                </Button>
              </Row>
            </Col>
            <Col span="24" style={{ height: "80vh" }}>
              <VideoTool
                currentURL={props.currentFilmStudy?.videoURL}
                currentMoment={props.filmStudyMoment}
                currentComboMoment={props.comboStudyMoment}
              />
            </Col>
            <Col
              span="24"
              style={{
                height: "600px",
                backgroundColor: "#262626",
                display: minimize ? "none" : "block",
                marginBottom: "40vh",
              }}
            >
              <Row gutter={[0, 16]} justify="space-between">
                <Col span="4">
                  <Row gutter={[8, 0]}>
                    <Col span="12">
                      <Button
                        onClick={() => {
                          setMinimize(!minimize);
                        }}
                      >
                        <CloseOutlined />
                      </Button>{" "}
                    </Col>
                    <Col span="12">
                      <Button
                        onClick={() => {
                          if (currentView === "search") {
                            setCurrentView("video");
                          } else {
                            setCurrentView("search");
                          }
                        }}
                      >
                        {currentView === "search" ? (
                          <span>
                            Result <VideoCameraOutlined />
                          </span>
                        ) : (
                          <span>
                            {" "}
                            Search <VideoCameraAddOutlined />
                          </span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span="20">
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameBack}>
                          Step Back
                          <DoubleLeftOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={replay}>
                          Replay
                          <RollbackOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameForward}>
                          Step Forward
                          <DoubleRightOutlined />
                        </Button>{" "}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  height: "80%",
                  backgroundColor: "#141414",
                  padding: "10px",
                  overflowY: "scroll",
                }}
              >
                {currentView === "video" && <FilmSearchResult Demo={true} />}
                {currentView === "search" && (
                  <FilmSearch
                    currentFilmStudy={props.currentFilmStudy}
                    Demo={true}
                  />
                )}
              </Row>
            </Col>
            <Col
              span="24"
              style={{
                height: "55px",
                marginBottom: "40vh",

                backgroundColor: "#262626",
                display: minimize ? "block" : "none",
              }}
            >
              <Row>
                <Col span="4">
                  <Tooltip title="Search/Result">
                    <Button
                      onClick={() => {
                        setMinimize(!minimize);
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  </Tooltip>
                </Col>
                <Col span="20">
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameBack}>
                          Step Back
                          <DoubleLeftOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={replay}>
                          Replay
                          <RollbackOutlined />
                        </Button>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Button onClick={frameForward}>
                          Step Forward
                          <DoubleRightOutlined />
                        </Button>{" "}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : laptopView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ marginTop: "30px", textAlign: "center" }}>
            {props.currentFilmStudy?.fighterA} vs{" "}
            {props.currentFilmStudy?.fighterB} - [DEMO]
          </h1>

          <Row justify="center" style={{ marginBottom: "20px" }}>
            <Col span={12}>
              <Row justify="center">
                {" "}
                <Button
                  target="_blank"
                  href="https://roundscoredata.typeform.com/to/tu2qxzk3"
                >
                  Give us your feedback
                </Button>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ width: 90 + `%`, margin: "0 auto" }}
          >
            <Col
              span="10"
              style={{
                height: "600px",
                backgroundColor: "#262626",
                display: minimize ? "none" : "block",
              }}
            >
              <Row style={{ padding: "15px 0" }} align="middle">
                <Col span="6">
                  <Row gutter={[8, 0]}>
                    <Col span="12">
                      {" "}
                      <Tooltip title="Search/Result" trigger={["hover"]}>
                        <Button
                          onClick={() => {
                            setMinimize(!minimize);
                          }}
                        >
                          <CloseOutlined />
                        </Button>{" "}
                      </Tooltip>
                    </Col>
                    <Col span="12">
                      <Tooltip
                        title={currentView === "search" ? "Result" : "Search "}
                        trigger={["hover"]}
                      >
                        <Button
                          onClick={() => {
                            if (currentView === "search") {
                              setCurrentView("video");
                            } else {
                              setCurrentView("search");
                            }
                          }}
                        >
                          {currentView === "search" ? (
                            <VideoCameraOutlined />
                          ) : (
                            <VideoCameraAddOutlined />
                          )}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col span="18">
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip title="Step Back 1 Frame " trigger={["hover"]}>
                          <Button onClick={frameBack}>
                            <DoubleLeftOutlined />
                          </Button>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip title="Replay" trigger={["hover"]}>
                          <Button onClick={replay}>
                            <RollbackOutlined />
                          </Button>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip
                          title="Step Forward 1 Frame "
                          trigger={["hover"]}
                        >
                          <Button onClick={frameForward}>
                            <DoubleRightOutlined />
                          </Button>{" "}
                        </Tooltip>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                style={{
                  height: "80%",
                  backgroundColor: "#141414",
                  padding: "10px",
                  overflowY: "scroll",
                }}
              >
                {currentView === "video" && <FilmSearchResult Demo={true} />}
                {currentView === "search" && (
                  <FilmSearch
                    currentFilmStudy={props.currentFilmStudy}
                    Demo={true}
                  />
                )}
              </Row>
            </Col>
            <Col
              span="2"
              style={{
                height: "600px",
                backgroundColor: "#262626",
                display: minimize ? "block" : "none",
              }}
            >
              <Row
                gutter={[0, 32]}
                style={{ padding: "15px 0" }}
                align="middle"
              >
                <Col span="24">
                  <Tooltip title="Search/Result" trigger={["hover"]}>
                    <Button
                      onClick={() => {
                        setMinimize(!minimize);
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  </Tooltip>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip title="Step Back 1 Frame " trigger={["hover"]}>
                      <Button onClick={frameBack}>
                        <DoubleLeftOutlined />
                      </Button>{" "}
                    </Tooltip>
                  </Row>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip title="Replay" trigger={["hover"]}>
                      <Button onClick={replay}>
                        <RollbackOutlined />
                      </Button>
                    </Tooltip>
                  </Row>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip title="Step Forward 1 Frame " trigger={["hover"]}>
                      <Button onClick={frameForward}>
                        <DoubleRightOutlined />
                      </Button>{" "}
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={minimize ? "22" : "14"} style={{ height: "600px" }}>
              <VideoTool
                currentURL={props.currentFilmStudy?.videoURL}
                currentMoment={props.filmStudyMoment}
                currentComboMoment={props.comboStudyMoment}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ marginTop: "30px", textAlign: "center" }}>
            {props.currentFilmStudy?.fighterA} vs{" "}
            {props.currentFilmStudy?.fighterB} - [DEMO]
          </h1>
          <Row justify="center" style={{ marginBottom: "20px" }}>
            <Col span={12}>
              <Row justify="center">
                {" "}
                <Button
                  target="_blank"
                  href="https://roundscoredata.typeform.com/to/tu2qxzk3"
                >
                  Give us your feedback
                </Button>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[16, 16]}
            style={{ width: 80 + `%`, margin: "0 auto" }}
          >
            <Col
              span="8"
              style={{
                height: "600px",
                backgroundColor: "#262626",
                display: minimize ? "none" : "block",
              }}
            >
              <Row style={{ padding: "15px 0" }} align="middle">
                <Col span="6">
                  <Row gutter={[8, 0]}>
                    <Col span="12">
                      {" "}
                      <Tooltip title="Search/Result" trigger={["hover"]}>
                        <Button
                          onClick={() => {
                            setMinimize(!minimize);
                          }}
                        >
                          <CloseOutlined />
                        </Button>{" "}
                      </Tooltip>
                    </Col>
                    <Col span="12">
                      <Tooltip
                        title={currentView === "search" ? "Result" : "Search "}
                        trigger={["hover"]}
                      >
                        <Button
                          onClick={() => {
                            if (currentView === "search") {
                              setCurrentView("video");
                            } else {
                              setCurrentView("search");
                            }
                          }}
                        >
                          {currentView === "search" ? (
                            <VideoCameraOutlined />
                          ) : (
                            <VideoCameraAddOutlined />
                          )}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col span="18">
                  <Row justify="center">
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip title="Step Back 1 Frame " trigger={["hover"]}>
                          <Button onClick={frameBack}>
                            <DoubleLeftOutlined />
                          </Button>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip title="Replay" trigger={["hover"]}>
                          <Button onClick={replay}>
                            <RollbackOutlined />
                          </Button>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col span="6">
                      <Row justify="center">
                        <Tooltip
                          title="Step Forward 1 Frame "
                          trigger={["hover"]}
                        >
                          <Button onClick={frameForward}>
                            <DoubleRightOutlined />
                          </Button>{" "}
                        </Tooltip>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  height: "80%",
                  backgroundColor: "#141414",
                  padding: "10px",
                  overflowY: "scroll",
                }}
              >
                {currentView === "video" && <FilmSearchResult Demo={true} />}
                {currentView === "search" && (
                  <FilmSearch
                    currentFilmStudy={props.currentFilmStudy}
                    Demo={true}
                  />
                )}
              </Row>
            </Col>
            <Col
              span="2"
              style={{
                height: "600px",
                backgroundColor: "#262626",
                display: minimize ? "block" : "none",
              }}
            >
              <Row
                gutter={[0, 32]}
                style={{ padding: "15px 0" }}
                align="middle"
              >
                <Col span="24">
                  <Tooltip
                    title="Search/Result"
                    trigger={["hover"]}
                    placement="left"
                  >
                    <Button
                      onClick={() => {
                        setMinimize(!minimize);
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  </Tooltip>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip
                      title="Step Back 1 Frame "
                      trigger={["hover"]}
                      placement="left"
                    >
                      <Button onClick={frameBack}>
                        <DoubleLeftOutlined />
                      </Button>{" "}
                    </Tooltip>
                  </Row>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip
                      title="Replay"
                      trigger={["hover"]}
                      placement="left"
                    >
                      <Button onClick={replay}>
                        <RollbackOutlined />
                      </Button>
                    </Tooltip>
                  </Row>
                </Col>
                <Col span="24">
                  <Row>
                    <Tooltip
                      title="Step Forward 1 Frame "
                      trigger={["hover"]}
                      placement="left"
                    >
                      <Button onClick={frameForward}>
                        <DoubleRightOutlined />
                      </Button>{" "}
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              span={minimize ? "22" : "16"}
              style={{ height: "600px" }}
              trigger={["hover"]}
            >
              <VideoTool
                currentURL={props.currentFilmStudy?.videoURL}
                currentMoment={props.filmStudyMoment}
                currentComboMoment={props.comboStudyMoment}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

const style = {
  overlayPotrait: {
    position: "fixed" /* Sit on top of the page content */,
    display: "block" /* Hidden by default */,
    width: "100%" /* Full width (cover the whole page) */,
    height: "100%" /* Full height (cover the whole page) */,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.75)" /* Black background with opacity */,
    zIndex: 2 /* Specify a stack order in case you're using a different order for other elements */,
    cursor: "pointer",
  },
  overlayLandscape: {
    position: "fixed" /* Sit on top of the page content */,
    display: "none" /* Hidden by default */,
    width: "100%" /* Full width (cover the whole page) */,
    height: "100%" /* Full height (cover the whole page) */,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.75)" /* Black background with opacity */,
    zIndex: 2 /* Specify a stack order in case you're using a different order for other elements */,
    cursor: "pointer",
  },
};
const mapDispatchToProps = (dispatch) => ({
  onSetCurrentFilmStudy: (data) =>
    dispatch({ type: "CURRENT_FILM_STUDY_SET", data }),
  onSetFighterOneData: (data) =>
    dispatch({ type: "FIGHTER_ONE_DATA_SET", data }),
  onSetFighterTwoData: (data) =>
    dispatch({ type: "FIGHTER_TWO_DATA_SET", data }),
  onSetFilmSearchQueryHistory: (data) =>
    dispatch({ type: "FILM_SEARCH_QUERY_HISTORY_SET", data }),
  onSetFilmStudyMoment: (data) =>
    dispatch({ type: "FILM_STUDY_MOMENT_SET", data }),
  onSetFilmEventCount: (data) =>
    dispatch({ type: "FILM_EVENT_COUNT_SET", data }),
  onSetComboEventCount: (data) =>
    dispatch({ type: "COMBO_EVENT_COUNT_SET", data }),
  onSetFrameForwardCount: (data) =>
    dispatch({ type: "FRAME_FORWARD_COUNT_SET", data }),
  onSetFrameBackwardCount: (data) =>
    dispatch({ type: "FRAME_BACKWARD_COUNT_SET", data }),
});
const mapStateToProps = (state) => ({
  currentFilmStudy: state.currentFilmStudy.currentFilmStudy,
  filmStudyMoment: state.filmStudyMoment.filmStudyMoment,
  filmStudyCurrentView: state.filmStudyCurrentView.filmStudyCurrentView,
  comboStudyMoment: state.comboStudyMoment.comboStudyMoment,
  screenOrientation: state.orientationReducer.orientation.screenOrientation,
  filmEventCount: state.filmEventCount.filmEventCount,
  comboEventCount: state.comboEventCount.comboEventCount,
  searchQueryResult: state.searchQueryResult.searchQueryResult,
  frameForwardCount: state.frameForwardCount.frameForwardCount,
  frameBackwardCount: state.frameBackwardCount.frameBackwardCount,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Demo);
